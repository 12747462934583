import React, { useEffect, useState } from "react";
import Highlight from "react-highlight";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { NavScrollTo } from "./../../components/nav-scroll-to/nav-scroll-to.jsx";
import GoogleMapReact from "google-map-react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { ModalBody, Modal } from "react-bootstrap";
function Map({ allIpdData, markers, markerLines, from }) {
  const [code1, setCode1] = useState();
  const [code2, setCode2] = useState();
  const [ipModal, setIpModal] = useState(false);
  const [agentIp, setAgentIp] = useState([]);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  // var markers = [
  //   { name: "Egypt", coords: [26.8206, 30.8025] },
  //   { name: "Russia", coords: [61.524, 105.3188] },
  //   { name: "Canada", coords: [56.1304, -106.3468] },
  //   { name: "Greenland", coords: [71.7069, -42.6043] },
  //   { name: "Brazil", coords: [-14.235, -51.9253] }
  //  ];
  const tooltipTemplateDashboard = function (marker, agentCount) {
    return `
    <div con class="tootTipClass">
      <span class="d-flex align-items-center mb-2">
        <span class="h5 mb-0 text-black">${marker?.name}</span>
      </span>
      <div class="d-flex justify-content-start" style="max-width: 10rem;">
        <strong>Country:</strong>
        <span class="ms-2">${marker?.country}</span>
      </div>
      <div class="d-flex justify-content-start" style="max-width: 10rem;">
        <strong>City:</strong>
        <span class="ms-2">${marker?.city}</span>
      </div>
      <div class="d-flex justify-content-start" style="max-width: 10rem;">
        <strong>Ip:</strong>
        <span class="ms-2">${marker?.ip}</span>
      </div>
      <div class="d-flex justify-content-start" style="max-width: 10rem;">
        <strong>Total Agent:</strong>
        <span class="ms-2">${agentCount}</span>
      </div>
    <div>
    `;
  };

  const tooltipTemplate = function (marker) {
    let vulnerableContent = '';

  if (marker?.is_agent) {
    vulnerableContent = `
      <div class="d-flex justify-content-between" style="max-width: 10rem;">
        <strong>Agent Ip:</strong>(public)
      </div>
    `;
  }else {
    vulnerableContent = `
      <div class="d-flex justify-content-between" style="max-width: 10rem;">
        <strong>Vulnerable:</strong>
        <span class="ms-2">${marker.vulnerable}</span>
      </div>
    `;
  }
    console.log("Marker",marker)
    return `
      <span class="d-flex align-items-left mb-2">
        <span class="h5 mb-0 text-black">${marker?.name}</span>
      </span>
      <div class="d-flex justify-content-start" style="max-width: 10rem;">
        <strong>Country:</strong>
        <span class="ms-2">${marker?.country}</span>
      </div>
      <div class="d-flex justify-content-start" style="max-width: 10rem;">
        <strong>City:</strong>
        <span class="ms-2">${marker?.city}</span>
      </div>
      <div class="d-flex justify-content-start" style="max-width: 10rem;">
        <strong>Ip:</strong>
        <span class="ms-2">${marker?.ip}</span>
      </div>
      <div class="d-flex justify-content-start" style="max-width: 10rem;">
      ${vulnerableContent}
    </div>
    `;
  };

  function renderMap() {
    var theme = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();
    var bodyFontFamily = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();
    const map = document.getElementById("jvectorMap");
    const mapElm = document.querySelectorAll(".jvm-tooltip");

    if (map) {
      for (let i = 0; i < mapElm.length; i++) {
        mapElm[i].remove();
      }
      map.innerHTML = "";

      new jsVectorMap({
        selector: "#jvectorMap",
        map: "world",
        zoomButtons: true,
        normalizeFunction: "polynomial",
        hoverOpacity: 0.5,
        hoverColor: false,
        zoomOnScroll: false,
        series: {
          regions: [
            {
              normalizeFunction: "polynomial",
            },
          ],
        },
        // labels: {
        //   markers: {
        //     render: (marker) => marker.name
        //   }
        // },
        focusOn: {
          x: 0.5,
          y: 0.5,
          scale: 1,
        },
        markers: markers,
        markerStyle: {
          initial: {
            // fill: theme,
            fill: "pink",
            stroke: "none",
            r: 5,
          },
          hover: {
            fill: theme,
          },
        },
        markerLabelStyle: {
          initial: {
            fontFamily: bodyFontFamily,
            fontSize: "12px",
            fill: "rgba(" + inverseRgb + ", .75)",
          },
        },
        regionStyle: {
          initial: {
            fill: inverse,
            fillOpacity: 0.15,
            stroke: "none",
            strokeWidth: 0.4,
            strokeOpacity: 1,
          },
          hover: {
            fillOpacity: 0.5,
          },
        },
        onRegionTooltipShow(map, tooltip, code) {
          let marker = markers.find(function (marker) {
            return marker.code === code;
          });

          if (marker) {
            tooltip._tooltip.style.display = null;
            tooltip._tooltip.innerHTML =
              from == "dashboard"
                ? tooltipTemplateDashboard(marker)
                : tooltipTemplate(marker);
          } else {
            tooltip._tooltip.style.display = "none";
          }
        },
        onMarkerTooltipShow: function (map, tooltip, code) {
          // setIpModal(true)

          const result = markers.filter(
            (mark) =>
              mark.coords[0] === markers[code].coords[0] &&
              mark.coords[1] === markers[code].coords[1]
          );
          const agentCount = result.reduce(
            (accumulator, currentValue) => accumulator + currentValue.count,
            0
          );
          setAgentIp(result);
          tooltip._tooltip.style.display = null;
          tooltip._tooltip.innerHTML =
            from == "dashboard"
              ? tooltipTemplateDashboard(markers[code], agentCount)
              : tooltipTemplate(markers[code]);
        },

        onMarkerClick: function (map, tooltip, code) {
         markers.length > 0 && from === "dashboard" ? setIpModal(true) : setIpModal(false);
        },
        backgroundColor: "transparent",
        lines: markerLines,
        lineStyle: {
          stroke: "#676767",
          strokeWidth: 1.5,
          fill: "#FF5566",
          fillOpacity: 1,
          strokeDasharray: "6 3 6", // OR: [6, 2, 6]
          animation: true, // Enables animation
        },
      });
    }
  }
  useEffect(() => {
    fetch("/assets/data/map/code-1.json")
      .then(function (response) {
        return response.text();
      })
      .then((html) => {
        setCode1(html);
      });
    fetch("/assets/data/map/code-2.json")
      .then(function (response) {
        return response.text();
      })
      .then((html) => {
        setCode2(html);
      });
    // eslint-diable-next-line
    renderMap();
    // document.addEventListener('theme-reload', () => {
    //   renderMap();
    // });
    // console.log("hello", `${modalPosition.y}px`, modalPosition.x - 300);
  }, [markers]);
  return (
    <div
      onMouseDown={(event) => {
        if (!ipModal) {
          setModalPosition({ x: event.clientX, y: event.clientY });
        }
      }}
      className="container"
    >
      <Modal
        scrollable={true}
        show={ipModal}
        onHide={() => setIpModal(false)}
        className="mapAgentModal"
        style={{ top: `${modalPosition.y - 100}px` }}
      >
        <ModalBody
          style={{ height: "100px", width: "250px" }}
          className="overflow-scroll"
        >
          {agentIp.length > 0 ? (
            agentIp.map((marker) => {
              return (
                <div className="mb-4">
                  <span className="d-flex align-items-center mb-2">
                    <span className="h5 mb-0 text-white">{marker?.name}</span>
                  </span>
                  <div className="d-flex justify-content-start">
                    <strong>Count:</strong>
                    <span className="ms-2">{marker?.count}</span>
                  </div>
                  <div className="d-flex justify-content-start">
                    <strong>Ip:</strong>
                    <span
                      onClick={() => window.location.href = `publicIp/${marker?.ip}`}
                      className="ms-2 mapIpClick"
                    >
                      {marker?.ip}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <h3>No Ip data Found</h3>
          )}
        </ModalBody>
      </Modal>
      <div style={{ height: "415px" }} className="col-xl-12">
        <div id="jvectorMap" style={{ height: "100%" }}></div>
      </div>
    </div>
  );
}
export default Map;
