import React, { useState } from "react";

const FilterComponentsBGProcess = ({
  tableName,
  agentAllBgProcessDetails,
  agentAllBGProcessdata,
}) => {
  const [inputs, setInputs] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [selectedOptions, setSelectedOptions] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [showFilter, setShowFilter] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const handleInputChange = (index, event) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = event.target.value;
    setInputs(updatedInputs);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = value;
    setSelectedOptions(updatedOptions);
  };

  const handleShowFilter = (index, value) => {
    const updatedOptions = [...showFilter];
    updatedOptions[index] = value;
    setShowFilter(updatedOptions);
  };

  const handleFilterCall = (e) => {
    e.preventDefault();
    let filters = {};
    Object.keys(agentAllBGProcessdata[0]).forEach((data, index) => {
      if (!showFilter[index]) {
        return;
      }
      filters[data] = [
        {
          value: inputs[index],
          is_exact: selectedOptions[index] === "is_exact" ? true : false,
          is_contains: selectedOptions[index] === "is_contains" ? true : false,
        },
      ];
    });

    if (tableName === "bgProcess") {
      agentAllBgProcessDetails(JSON.stringify(filters));
    }
    // Perform form submission or validation logic here
  };

  return (
    <form onSubmit={handleFilterCall}>
      <div className="mb-3">
        {Object.keys(agentAllBGProcessdata[0]).map((data, index) => {
          return (
            <div className="" key={index}>
              <div className="mb-1">
                <label className="form-label mb-1 text-uppercase">
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    className="me-2"
                    value={index}
                    onChange={(e) =>
                      handleShowFilter(index, showFilter[index] ? false : true)
                    }
                  />
                  {data}
                </label>
              </div>
              {showFilter[index] ? (
                <div className="mb-3 ms-2">
                  <div>
                    <input
                      type="text"
                      value={[inputs[index]]}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control form-control-sm bg-white bg-opacity-5"
                    />
                  </div>
                  <label className="form-label mb-1">Select filter type </label>
                  <div>
                    <input
                      type="radio"
                      value="is_exact"
                      checked={selectedOptions[index] === "is_exact"}
                      onChange={() => handleOptionChange(index, "is_exact")}
                      className="bg-white bg-opacity-5"
                    />
                    <label className="fs-5 ms-2">is exact</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="is_contains"
                      checked={selectedOptions[index] === "is_contains"}
                      onChange={() => handleOptionChange(index, "is_contains")}
                      className="bg-white bg-opacity-5"
                    />
                    <label className="fs-5 ms-2">is contain</label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
      <button
        className="btn btn-outline-theme btn-lg d-block w-fit fw-500 mt-3 mb-3"
        type="submit"
      >
        Apply filter
      </button>
    </form>
  );
};
const FilterComponentsAgentPort = ({
  tableName,
  agentAllPortDetails,
  agentPortdataModAll,
}) => {
  const [inputs, setInputs] = useState(["", "", "", "", "", "", "", ""]);
  const [selectedOptions, setSelectedOptions] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [showFilter, setShowFilter] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const handleInputChange = (index, event) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = event.target.value;
    setInputs(updatedInputs);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = value;
    setSelectedOptions(updatedOptions);
  };

  const handleShowFilter = (index, value) => {
    const updatedOptions = [...showFilter];
    updatedOptions[index] = value;
    setShowFilter(updatedOptions);
  };

  const handleFilterCall = (e) => {
    e.preventDefault();
    let filters = {};
    Object.keys(agentPortdataModAll[0]).forEach((data, index) => {
      if (!showFilter[index]) {
        return;
      }
      filters[
        data === "Name"
          ? "Service%23Name"
          : data === "Ostype"
          ? "Service%23Ostype"
          : data === "Version"
          ? "Service%23Version"
          : data === "State"
          ? "State%23State"
          : data === "Reason"
          ? "State%23Reason"
          : data
      ] = [
        {
          value: inputs[index],
          is_exact: selectedOptions[index] === "is_exact" ? true : false,
          is_contains: selectedOptions[index] === "is_contains" ? true : false,
        },
      ];
    });

    if (tableName === "port") {
      agentAllPortDetails(JSON.stringify(filters));
    }
    // Perform form submission or validation logic here
  };

  return (
    <form onSubmit={handleFilterCall}>
      <div className="mb-3">
        {Object.keys(agentPortdataModAll[0]).map((data, index) => {
          return (
            <div className="" key={index}>
              <div className="mb-1">
                <label className="form-label mb-1 text-uppercase">
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    className="me-2"
                    value={index}
                    onChange={(e) =>
                      handleShowFilter(index, showFilter[index] ? false : true)
                    }
                  />
                  {data}
                </label>
              </div>
              {showFilter[index] ? (
                <div className="mb-3 ms-2">
                  <div>
                    <input
                      type="text"
                      value={[inputs[index]]}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control form-control-sm bg-white bg-opacity-5"
                    />
                  </div>
                  <label className="form-label mb-1">Select filter type </label>
                  <div>
                    <input
                      type="radio"
                      value="is_exact"
                      checked={selectedOptions[index] === "is_exact"}
                      onChange={() => handleOptionChange(index, "is_exact")}
                      className="bg-white bg-opacity-5"
                    />
                    <label className="fs-5 ms-2">is exact</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="is_contains"
                      checked={selectedOptions[index] === "is_contains"}
                      onChange={() => handleOptionChange(index, "is_contains")}
                      className="bg-white bg-opacity-5"
                    />
                    <label className="fs-5 ms-2">is contain</label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
      <button
        className="btn btn-outline-theme btn-lg d-block w-fit fw-500 mt-3 mb-3"
        type="submit"
      >
        Apply filter
      </button>
    </form>
  );
};
const FilterComponentsAgentProcess = ({
  tableName,
  agentAllPortDetails,
  agentPortdataModAll,
}) => {
  const [inputs, setInputs] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [selectedOptions, setSelectedOptions] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [showFilter, setShowFilter] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const handleInputChange = (index, event) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = event.target.value;
    setInputs(updatedInputs);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = value;
    setSelectedOptions(updatedOptions);
  };

  const handleShowFilter = (index, value) => {
    const updatedOptions = [...showFilter];
    updatedOptions[index] = value;
    setShowFilter(updatedOptions);
  };

  const handleFilterCall = (e) => {
    e.preventDefault();
    let filters = {};
    Object.keys(agentPortdataModAll[0]).forEach((data, index) => {
      if (!showFilter[index]) {
        return;
      }
      filters[
        data === "remoteaddr_ip"
          ? "remoteaddr%23ip"
          : data === "localaddr_ip"
          ? "localaddr%23ip"
          : data === "localaddr_port"
          ? "localaddr%23port"
          : data === "remoteaddr_port"
          ? "remoteaddr%23port"
          : data
      ] = [
        {
          value: inputs[index],
          is_exact: selectedOptions[index] === "is_exact" ? true : false,
          is_contains: selectedOptions[index] === "is_contains" ? true : false,
        },
      ];
    });

    if (tableName === "agentProcess") {
      agentAllPortDetails(JSON.stringify(filters));
    }
    // Perform form submission or validation logic here
  };

  return (
    <form onSubmit={handleFilterCall}>
      <div className="mb-3">
        {Object.keys(agentPortdataModAll[0]).map((data, index) => {
          return (
            <div className="" key={index}>
              <div className="mb-1">
                <label className="form-label mb-1 text-uppercase">
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    className="me-2"
                    value={index}
                    onChange={(e) =>
                      handleShowFilter(index, showFilter[index] ? false : true)
                    }
                  />
                  {data}
                </label>
              </div>
              {showFilter[index] ? (
                <div className="mb-3 ms-2">
                  <div>
                    <input
                      type="text"
                      value={[inputs[index]]}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control form-control-sm bg-white bg-opacity-5"
                    />
                  </div>
                  <label className="form-label mb-1">Select filter type </label>
                  <div>
                    <input
                      type="radio"
                      value="is_exact"
                      checked={selectedOptions[index] === "is_exact"}
                      onChange={() => handleOptionChange(index, "is_exact")}
                      className="bg-white bg-opacity-5"
                    />
                    <label className="fs-5 ms-2">is exact</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="is_contains"
                      checked={selectedOptions[index] === "is_contains"}
                      onChange={() => handleOptionChange(index, "is_contains")}
                      className="bg-white bg-opacity-5"
                    />
                    <label className="fs-5 ms-2">is contain</label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
      <button
        className="btn btn-outline-theme btn-lg d-block w-fit fw-500 mt-3 mb-3"
        type="submit"
      >
        Apply filter
      </button>
    </form>
  );
};
const FilterComponentsEventLog = ({
  tableName,
  agentAllPortDetails,
  agentPortdataModAll,
  hostIp,
  eventlogState,
}) => {
  const [inputs, setInputs] = useState(["", "", "", "", "", "", "", "", ""]);
  const [selectedOptions, setSelectedOptions] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [showFilter, setShowFilter] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const handleInputChange = (index, event) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = event.target.value;
    setInputs(updatedInputs);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = value;
    setSelectedOptions(updatedOptions);
  };

  const handleShowFilter = (index, value) => {
    const updatedOptions = [...showFilter];
    updatedOptions[index] = value;
    setShowFilter(updatedOptions);
  };

  const keysToFilterAndMap = [
    "ProcessId",
    "RecordId",
    "LogName",
    "MachineName",
    "ProviderName",
    "TaskDisplayName",
    "Message",
    "alias_name",
  ];

  // Filter and map the array of objects
  const filteredAndMappedArray = agentPortdataModAll.map((obj) => {
    const filteredObj = keysToFilterAndMap.reduce((acc, key) => {
      if (obj.hasOwnProperty(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});

    return filteredObj;
  });

  const handleFilterCall = (e) => {
    e.preventDefault();
    let filters = {};
    Object.keys(filteredAndMappedArray[0]).forEach((data, index) => {
      if (!showFilter[index]) {
        return;
      }
      filters[data] = [
        {
          value: inputs[index],
          is_exact: selectedOptions[index] === "is_exact" ? true : false,
          is_contains: selectedOptions[index] === "is_contains" ? true : false,
        },
      ];
    });

    console.log("filters", filters);

    if (tableName === "eventlog") {
      agentAllPortDetails(hostIp, eventlogState, JSON.stringify(filters));
    }
    // Perform form submission or validation logic here
  };

  return (
    <form onSubmit={handleFilterCall}>
      <div className="mb-3">
        {Object.keys(filteredAndMappedArray[0]).map((data, index) => {
          return (
            <div className="" key={index}>
              <div className="mb-1">
                <label className="form-label mb-1 text-uppercase">
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    className="me-2"
                    value={index}
                    onChange={(e) =>
                      handleShowFilter(index, showFilter[index] ? false : true)
                    }
                  />
                  {data}
                </label>
              </div>
              {showFilter[index] ? (
                <div className="mb-3 ms-2">
                  <div>
                    <input
                      type="text"
                      value={[inputs[index]]}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control form-control-sm bg-white bg-opacity-5"
                    />
                  </div>
                  <label className="form-label mb-1">Select filter type </label>
                  <div>
                    <input
                      type="radio"
                      value="is_exact"
                      checked={selectedOptions[index] === "is_exact"}
                      onChange={() => handleOptionChange(index, "is_exact")}
                      className="bg-white bg-opacity-5"
                    />
                    <label className="fs-5 ms-2">is exact</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="is_contains"
                      checked={selectedOptions[index] === "is_contains"}
                      onChange={() => handleOptionChange(index, "is_contains")}
                      className="bg-white bg-opacity-5"
                    />
                    <label className="fs-5 ms-2">is contain</label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
      <button
        className="btn btn-outline-theme btn-lg d-block w-fit fw-500 mt-3 mb-3"
        type="submit"
      >
        Apply filter
      </button>
    </form>
  );
};
export {
  FilterComponentsBGProcess,
  FilterComponentsAgentPort,
  FilterComponentsAgentProcess,
  FilterComponentsEventLog,
};
