import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { orgAxios } from "../../config/org.axios.js";
import { toast } from "react-toastify";
import IocTable from "../../components/table/IocTable.js";
import { IOC_COLUMNS } from "../../components/table/Columns.js";
import CustomIoc from "../../components/ioc/customIoc.js";
import AllIoc from "../../components/ioc/allIoc.js";
import UploadForm from "../../components/ioc/uploadForm.js";
import UploadCsv from "../../components/ioc/uploadCsv.js";
import { AppSettings } from "../../config/app-settings.js";

const IocUpload = () => {
  const [key, setKey] = useState("allIoc");
  const [globalocCount, setGlobalIocCount] = useState(0);
  const {customIocCount, setCustomIocCount }= useContext(AppSettings);
  return (
    <>
      <div className="mb-1">
        <h3>Indicator of Compromise Uploader</h3>
      </div>
      <Container className="mt-[10px]">
        <Tabs
          defaultActiveKey="table"
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          {/* <Tab
            style={{
              zIndex: 0,
              position: "relative",
            }}
            eventKey="csv"
            title="Upload IOC in  CSV "
          >
            <UploadCsv />
          </Tab>
          <Tab
            style={{
              zIndex: 0,
              position: "relative",
            }}
            eventKey="form"
            title="Upload IOC using  Form "
          >
            <UploadForm />
          </Tab> */}
          <Tab
            style={{
              zIndex: 0,
              position: "relative",
            }}
            eventKey="allIoc"
            title={`All Ioc Table (${globalocCount})`}
          >
            <Card>
              <CardHeader>All Available IOC's</CardHeader>
              <CardBody className="">
                <AllIoc setGlobalIocCount={setGlobalIocCount} />
              </CardBody>
            </Card>
          </Tab>
          <Tab
            style={{
              zIndex: 0,
              position: "relative",
            }}
            eventKey="customIoc"
            title={`Custom Ioc Table (${customIocCount})`}
          >
            <Card>
              <CardHeader>Custom Available IOC's</CardHeader>
              <CardBody className="">
                <CustomIoc setCustomIocCount={setCustomIocCount} />
              </CardBody>
            </Card>
          </Tab>
          
        </Tabs>
      </Container>
    </>
  );
};

export default IocUpload;
