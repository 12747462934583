export const agentInfoTableDataFormatter = (agentData) => {
  let modifiedAgentDataRaw = [];
  agentData?.map((agent) => {
    modifiedAgentDataRaw.push({
      _id: agent?._id ? agent?._id : "N/A",
      hostname: agent?.hostInfo?.hostname ? agent?.hostInfo?.hostname : "N/A",
      physicalId: agent?.cpuInfo?.[0]?.physicalId
        ? agent?.cpuInfo?.[0]?.physicalId
        : "N/A",
      modelName: agent?.cpuInfo?.[0]?.modelName
        ? agent?.cpuInfo?.[0]?.modelName
        : "N/A",
      vendorId: agent?.cpuInfo?.[0]?.vendorId
        ? agent?.cpuInfo?.[0]?.vendorId
        : "N/A",
      os: agent?.hostInfo.os ? agent?.hostInfo.os : "N/A",
      platform: agent?.hostInfo.platform ? agent?.hostInfo.platform : "N/A",
      hostIP: agent?.hostIP ? agent?.hostIP : "N/A",
      status: agent?.status ? agent?.status : "N/A",
      alias_name: agent?.alias_name ? agent?.alias_name : "N/A",
    });
  });

  return modifiedAgentDataRaw;
};

export const allAgentDataFormatter = (allAgentData) => {
  let modifiedAllAgentDataRaw = [];
  allAgentData.map((agent) => {
    modifiedAllAgentDataRaw.push({
      _id: agent?._id ? agent?._id : "N/A",
      hostname: agent?.hostInfo?.hostname ? agent?.hostInfo?.hostname : "N/A",
      uptime: agent?.hostInfo?.uptime ? agent?.hostInfo?.uptime : "N/A",
      procs: agent?.hostInfo?.procs ? agent?.hostInfo?.procs : "N/A",
      os: agent?.hostInfo?.os ? agent?.hostInfo?.os : "N/A",
      platform: agent?.hostInfo?.platform ? agent?.hostInfo?.platform : "N/A",
      platformFamily: agent?.hostInfo?.platformFamily
        ? agent?.hostInfo?.platformFamily
        : "N/A",
      platformVersion: agent?.hostInfo?.platformVersion
        ? agent?.hostInfo?.platformVersion
        : "N/A",
      kernelVersion: agent?.hostInfo?.kernelVersion
        ? agent?.hostInfo?.kernelVersion
        : "N/A",
      kernelArch: agent?.hostInfo?.kernelArch
        ? agent?.hostInfo?.kernelArch
        : "N/A",
      cpu: agent?.cpuInfo?.[0] ? agent?.cpuInfo?.[0]?.cpu : "N/A",
      vendorId: agent?.cpuInfo?.[0]?.vendorId
        ? agent?.cpuInfo?.[0]?.vendorId
        : "N/A",
      family: agent?.cpuInfo?.[0]?.family ? agent?.cpuInfo?.[0]?.family : "N/A",
      model: agent?.cpuInfo?.[0]?.model ? agent?.cpuInfo?.[0]?.model : "N/A",
      stepping: agent?.cpuInfo?.[0]?.stepping
        ? agent?.cpuInfo?.[0]?.stepping
        : "N/A",
      physicalId: agent?.cpuInfo?.[0]?.physicalId
        ? agent?.cpuInfo?.[0]?.physicalId
        : "N/A",
      coreId: agent?.cpuInfo?.[0]?.coreId ? agent?.cpuInfo?.[0]?.coreId : "N/A",
      cores: agent?.cpuInfo?.[0]?.cores ? agent?.cpuInfo?.[0]?.cores : "N/A",
      modelName: agent?.cpuInfo?.[0]?.modelName
        ? agent?.cpuInfo?.[0]?.modelName
        : "N/A",
      mhz: agent?.cpuInfo?.[0]?.mhz ? agent?.cpuInfo?.[0]?.mhz : "N/A",
      cacheSize: agent?.cpuInfo?.[0]?.cacheSize
        ? agent?.cpuInfo?.[0]?.cacheSize
        : "N/A",
      microcode: agent?.cpuInfo?.[0]?.microcode
        ? agent?.cpuInfo?.[0]?.microcode
        : "N/A",
      diskInfofree: agent?.diskInfo?.free ? agent?.diskInfo?.free : "N/A",
      fstype: agent?.diskInfo?.fstype ? agent?.diskInfo?.fstype : "N/A",
      inodesFree: agent?.diskInfo?.inodesFree
        ? agent?.diskInfo?.inodesFree
        : "N/A",
      inodesTotal: agent?.diskInfo?.inodesTotal
        ? agent?.diskInfo?.inodesTotal
        : "N/A",
      inodesUsed: agent?.diskInfo?.inodesUsed
        ? agent?.diskInfo?.inodesUsed
        : "N/A",
      inodesUsedPercent: agent?.diskInfo?.inodesUsedPercent
        ? agent?.diskInfo?.inodesUsedPercent
        : "N/A",
      path: agent?.diskInfo?.path ? agent?.diskInfo?.path : "N/A",
      used: agent?.diskInfo?.used ? agent?.diskInfo?.used : "N/A",
      usedPercent: agent?.diskInfo?.usedPercent
        ? agent?.diskInfo?.usedPercent
        : "N/A",
      diskInfototal: agent?.diskInfo?.total ? agent?.diskInfo?.total : "N/A",
      hostIP: agent?.hostIP ? agent?.hostIP : "N/A",
      bootTime: agent?.hostInfo?.bootTime ? agent?.hostInfo?.bootTime : "N/A",
      hostid: agent?.hostInfo?.hostid ? agent?.hostInfo?.hostid : "N/A",
      virtualizationRole: agent?.hostInfo?.virtualizationRole
        ? agent?.hostInfo?.virtualizationRole
        : "N/A",
      virtualizationSystem: agent?.hostInfo?.virtualizationSystem
        ? agent?.hostInfo?.virtualizationSystem
        : "N/A",
      active: agent?.memoryInfo?.active ? agent?.memoryInfo?.active : "N/A",
      available: agent?.memoryInfo?.available
        ? agent?.memoryInfo?.available
        : "N/A",
      buffers: agent?.memoryInfo?.buffers ? agent?.memoryInfo?.buffers : "N/A",
      cached: agent?.memoryInfo?.cached ? agent?.memoryInfo?.cached : "N/A",
      commitlimit: agent?.memoryInfo?.commitlimit
        ? agent?.memoryInfo?.commitlimit
        : "N/A",
      committedas: agent?.memoryInfo?.committedas
        ? agent?.memoryInfo?.committedas
        : "N/A",
      dirty: agent?.memoryInfo?.dirty ? agent?.memoryInfo?.dirty : "N/A",
      free: agent?.memoryInfo?.free ? agent?.memoryInfo?.free : "N/A",
      highfree: agent?.memoryInfo?.highfree
        ? agent?.memoryInfo?.highfree
        : "N/A",
      hightotal: agent?.memoryInfo?.hightotal
        ? agent?.memoryInfo?.hightotal
        : "N/A",
      hugepagesfree: agent?.memoryInfo?.hugepagesfree
        ? agent?.memoryInfo?.hugepagesfree
        : "N/A",
      hugepagesize: agent?.memoryInfo?.hugepagesize
        ? agent?.memoryInfo?.hugepagesize
        : "N/A",
      hugepagestotal: agent?.memoryInfo?.hugepagestotal
        ? agent?.memoryInfo?.hugepagestotal
        : "N/A",
      inactive: agent?.memoryInfo?.inactive
        ? agent?.memoryInfo?.inactive
        : "N/A",
      laundry: agent?.memoryInfo?.laundry ? agent?.memoryInfo?.laundry : "N/A",
      lowfree: agent?.memoryInfo?.lowfree ? agent?.memoryInfo?.lowfree : "N/A",
      lowtotal: agent?.memoryInfo?.lowtotal
        ? agent?.memoryInfo?.lowtotal
        : "N/A",
      mapped: agent?.memoryInfo?.mapped ? agent?.memoryInfo?.mapped : "N/A",
      pagetables: agent?.memoryInfo?.pagetables
        ? agent?.memoryInfo?.pagetables
        : "N/A",
      shared: agent?.memoryInfo?.shared ? agent?.memoryInfo?.shared : "N/A",
      slab: agent?.memoryInfo?.slab ? agent?.memoryInfo?.slab : "N/A",
      sreclaimable: agent?.memoryInfo?.sreclaimable
        ? agent?.memoryInfo?.sreclaimable
        : "N/A",
      sunreclaim: agent?.memoryInfo?.sunreclaim
        ? agent?.memoryInfo?.sunreclaim
        : "N/A",
      swapcached: agent?.memoryInfo?.swapcached
        ? agent?.memoryInfo?.swapcached
        : "N/A",
      swapfree: agent?.memoryInfo?.swapfree
        ? agent?.memoryInfo?.swapfree
        : "N/A",
      swaptotal: agent?.memoryInfo?.swaptotal
        ? agent?.memoryInfo?.swaptotal
        : "N/A",
      total: agent?.memoryInfo?.total ? agent?.memoryInfo?.total : "N/A",
      memoryInfoused: agent?.memoryInfo?.used ? agent?.memoryInfo?.used : "N/A",
      memoryInfousedPercent: agent?.memoryInfo?.usedPercent
        ? agent?.memoryInfo?.usedPercent
        : "N/A",
      vmallocchunk: agent?.memoryInfo?.vmallocchunk
        ? agent?.memoryInfo?.vmallocchunk
        : "N/A",
      vmalloctotal: agent?.memoryInfo?.vmalloctotal
        ? agent?.memoryInfo?.vmalloctotal
        : "N/A",
      vmallocused: agent?.memoryInfo?.vmallocused
        ? agent?.memoryInfo?.vmallocused
        : "N/A",
      wired: agent?.memoryInfo?.wired ? agent?.memoryInfo?.wired : "N/A",
      writeback: agent?.memoryInfo?.writeback
        ? agent?.memoryInfo?.writeback
        : "N/A",
      writebacktmp: agent?.memoryInfo?.writebacktmp
        ? agent?.memoryInfo?.writebacktmp
        : "N/A",
      status: agent?.status ? agent?.status : "N/A",
      alias_name: agent?.alias_name ? agent?.alias_name : "N/A",
    });
  });
  return modifiedAllAgentDataRaw;
};

export const processDataFormatter = (processData) => {
  let modifiedProcessDataRaw = [];
  processData.map((process) => {
    modifiedProcessDataRaw.push({
      pid: process?.pid ? process?.pid : "N/A",
      vulnerable: process?.vulnerable ? process?.vulnerable : "N/A",
      remoteaddr_ip: process?.remoteaddr?.ip ? process?.remoteaddr?.ip : "N/A",
      family: process?.family ? process?.family : "N/A",
      localaddr_ip: process?.localaddr.ip ? process?.localaddr.ip : "N/A",
      localaddr_port: process?.localaddr.port ? process?.localaddr.port : "N/A",
      processname: process?.processname ? process?.processname : "N/A",
      protocol: process?.protocol ? process?.protocol : "N/A",
      remoteaddr_port: process?.remoteaddr.port
        ? process?.remoteaddr.port
        : "N/A",
      type: process?.type ? process?.type : "N/A",
      uids: process?.uids ? process?.uids : "N/A",
      status: process?.status ? process?.status : "N/A",
    });
  });
  return modifiedProcessDataRaw;
};

export const agentPortDataFormatter = (portData) => {

  let modifiedProcessDataRaw = [];
  portData.map((port) => {
    modifiedProcessDataRaw.push({
      date:port?.date ? port?.date : "N/A",
      Portid: port?.Portid ? port?.Portid : "N/A",
      Name: port?.Service?.Name ? port?.Service?.Name : "N/A",
      Ostype: port?.Service?.Ostype ? port?.Service?.Ostype : "N/A",
      Reason: port?.State?.Reason ? port?.State?.Reason : "N/A",
      State: port?.State?.State ? port?.State?.State : "N/A",
      Protocol: port?.Protocol ? port?.Protocol : "N/A",
      Version: port?.Service?.Version ? port?.Service?.Version : "N/A",
    });
  });
  return modifiedProcessDataRaw;
};
