import axios from "axios";
import { orgAxios } from "../../config/org.axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useFetcher, useParams } from "react-router-dom";
import {
  removeAccessToken,
  removeOrgToken,
  getOrgToken,
  getUser,
  removeUser,
} from "../../config/global";
import { AppSettings } from "../../config/app-settings";
import Avatar from "react-avatar";

function Header() {
  const context = useContext(AppSettings);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [fadeAmin, setFadeAmin] = useState(false);
  const orgID = JSON.parse(getOrgToken());

  const AllUnreadNotificationsCall = async () => {
    let res = await orgAxios.get(`get_notifications`);
    context.setNotification(res?.data?.data);
  };

  useEffect(() => {
    AllUnreadNotificationsCall();
    if (context.notification?.counts > 0) {
      setNotificationData(context.notification.notifications);
      setNotificationCount(context.notification.counts);
    }
  }, [context.notification.counts]);

  const getProfileDetails = async () => {
    await orgAxios.get(`user/`).then((response) => {
      context.setClientProfileData(response?.data?.data?.client_data);
      context.setUserProfileData(response?.data?.data?.user_data);
    });
  };
  useEffect(() => {
    getProfileDetails();
  }, []);

  const toggleAppSidebarDesktop = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-sidebar-collapsed");
  };

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-sidebar-mobile-toggled");
  };

  const toggleAppHeaderSearch = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-header-menu-search-toggled");
  };

  const readNotication = async (id) => {
    let res = await orgAxios.put(`read_notification`, { notify_id: id });
    if (res?.data?.data) {
      AllUnreadNotificationsCall();
    }
  };

  const moveToNotification = (id) => {
    window.location.href = `/${orgID}/analytics/all/${id}`;
    console.log("moveToAgebt");
  };

  return (
    <div id="header" className="app-header">
      <div className="desktop-toggler">
        <button
          type="button"
          className="menu-toggler"
          onClick={toggleAppSidebarDesktop}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="mobile-toggler">
        <button
          type="button"
          className="menu-toggler"
          onClick={toggleAppSidebarMobile}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="brand">
        <Link to={`/${orgID}/dashboard`} className="brand-logo">
          <div className="text-center5">
            <img
              alt="SHIELD XDR"
              src="/assets/img/xdrLogoSmall.png"
              height={20}
            />
          </div>
        </Link>
      </div>

      <div className="menu">
        {/* <div className="menu-item dropdown">
          <a href="#/" onClick={toggleAppHeaderSearch} className="menu-link">
            <div className="menu-icon">
              <i className="bi bi-search nav-icon"></i>
            </div>
          </a>
        </div> */}
        {/* <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-icon">
              <i className="bi bi-grid-3x3-gap nav-icon"></i>
            </div>
          </a>
          <div className="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1">
            <div className="row row-grid gx-0">
              <div className="col-4">
                <Link
                  to="/email/inbox"
                  className="dropdown-item text-decoration-none p-3 bg-none"
                >
                  <div className="position-relative">
                    <i className="bi bi-circle-fill position-absolute text-theme top-0 mt-n2 me-n2 fs-6px d-block text-center w-100"></i>
                    <i className="bi bi-envelope h2 opacity-5 d-block my-1"></i>
                  </div>
                  <div className="fw-500 fs-10px text-inverse">INBOX</div>
                </Link>
              </div>
              <div className="col-4">
                <Link
                  to="/pos/customer-order"
                  className="dropdown-item text-decoration-none p-3 bg-none"
                >
                  <div>
                    <i className="bi bi-hdd-network h2 opacity-5 d-block my-1"></i>
                  </div>
                  <div className="fw-500 fs-10px text-inverse">POS SYSTEM</div>
                </Link>
              </div>
              <div className="col-4">
                <Link
                  to="/calendar"
                  className="dropdown-item text-decoration-none p-3 bg-none"
                >
                  <div>
                    <i className="bi bi-calendar4 h2 opacity-5 d-block my-1"></i>
                  </div>
                  <div className="fw-500 fs-10px text-inverse">CALENDAR</div>
                </Link>
              </div>
            </div>
            <div className="row row-grid gx-0">
              <div className="col-4">
                <Link
                  to="/helper"
                  className="dropdown-item text-decoration-none p-3 bg-none"
                >
                  <div>
                    <i className="bi bi-terminal h2 opacity-5 d-block my-1"></i>
                  </div>
                  <div className="fw-500 fs-10px text-inverse">HELPER</div>
                </Link>
              </div>
              <div className="col-4">
                <Link
                  to="/settings"
                  className="dropdown-item text-decoration-none p-3 bg-none"
                >
                  <div className="position-relative">
                    <i className="bi bi-circle-fill position-absolute text-theme top-0 mt-n2 me-n2 fs-6px d-block text-center w-100"></i>
                    <i className="bi bi-sliders h2 opacity-5 d-block my-1"></i>
                  </div>
                  <div className="fw-500 fs-10px text-inverse">SETTINGS</div>
                </Link>
              </div>
              <div className="col-4">
                <Link
                  to="/widgets"
                  className="dropdown-item text-decoration-none p-3 bg-none"
                >
                  <div>
                    <i className="bi bi-collection-play h2 opacity-5 d-block my-1"></i>
                  </div>
                  <div className="fw-500 fs-10px text-inverse">WIDGETS</div>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="menu-item me-2">
          <a className="px-2 text-decoration-none " href={`/${orgID}/agentDownload `}>
            <button className="btn btn-outline-theme btn-sm d-block w-100 agentDownLoadButton me-2">
              {" "}
              Download Agent{" "}
              <i className="bi bi-download ms-1"></i>
            </button>
          </a>
        </div>
        <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#/"
            data-bs-auto-close="outside"
            data-bs-toggle="dropdown"
            className="menu-link"
            id="dropdownMenuClickableInside"
          >
            <div className="menu-icon">
              <i className="bi bi-bell nav-icon"></i>
            </div>
            <div
              // style={{
              //   background: "green",
              //   marginTop: "-18px",
              //   borderRadius: "50%",
              //   padding: "2px",
              //   marginLeft: "-7px",
              // }}
              className="notificationIcon"
            >
              {notificationCount}
            </div>
          </a>
          <div
            aria-labelledby="dropdownMenuClickableInside"
            className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1 h-400px overflow-scroll"
          >
            <h6 className="dropdown-header fs-10px mb-1">NOTIFICATIONS</h6>
            <div className="dropdown-divider mt-1"> </div>
            {notificationData?.length > 0 ? (
              notificationData.map((notification, index) => (
                <div
                  href="#/"
                  style={{ cursor: "pointer", display: "block" }}
                  key={index}
                  onClick={() => {
                    readNotication(notification._id);
                    moveToNotification(notification.id);
                    const activeId = document.getElementById(
                      `fadeActive-${index}`
                    );
                    // activeId.style.transitionDuration='2s'
                  }}
                  className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold"
                  id={`fadeActive`}
                >
                  <div className="flex-1 flex-wrap ps-3">
                    <div className="mb-1 text-inverse ">
                      {notification?.data?.data}
                    </div>
                  </div>
                  {!notification.is_read ? (
                    <>
                      <div
                        style={{ cursor: "pointer", display: "block" }}
                        onClick={() => readNotication(notification._id)}
                        className="ps-2 fs-16px"
                      >
                        <i className="fa fa-eye"></i>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            ) : (
              <div className="dropdown-notification-item p-3 text-center">
                No record found
              </div>
            )}
          </div>
        </div>
        <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-text d-sm-block d-none w-40px">
              {context.userprofileData?.full_name ? (
                <Avatar
                  size="34"
                  round
                  name={context.userprofileData?.full_name}
                  maxInitials={2}
                />
              ) : (
                <Avatar size="34" round name={"User"} maxInitials={2} />
              )}
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
            <div
              onClick={() => {
                window.location.href = `/${orgID}/profile`;
              }}
              className="dropdown-item d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              PROFILE{" "}
              <i className="bi bi-person-lines-fill ms-auto text-theme fs-16px my-n1"></i>
            </div>
            <Link
              onClick={() => {
                removeOrgToken();
                removeAccessToken();
                removeUser();
                localStorage.removeItem("xdr_notification");
                window.location.href = `/pages/login`;
              }}
              to={`/pages/login`}
              className="dropdown-item d-flex align-items-center"
            >
              LOGOUT{" "}
              <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i>
            </Link>
          </div>
        </div>
      </div>

      {/* <form className="menu-search" method="POST" name="header_search_form">
        <div className="menu-search-container">
          <div className="menu-search-icon">
            <i className="bi bi-search"></i>
          </div>
          <div className="menu-search-input">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Search menu..."
            />
          </div>
          <div className="menu-search-icon">
            <a href="#/" onClick={toggleAppHeaderSearch}>
              <i className="bi bi-x-lg"></i>
            </a>
          </div>
        </div>
      </form> */}
    </div>
  );
}

export default Header;
