import React, { useContext, useEffect, useState } from "react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import { orgAxios } from "../../config/org.axios.js";
import { socket } from "../../socket/SocketConnection.js";
import { Link, useParams } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal, ModalBody } from "react-bootstrap";
// import Map from "../map/map.js";
import Map from "../NewMap/map.js";

import TableShorting from "../../components/table/AllShortingTable.js";
import {
  AGENT_INFO_COLUMNS,
  SUSPICIOUS_AGENTS_COLUMNS,
  ALERTS_COLUMNS,
} from "../../components/table/Columns.js";
import Notification from "../../components/notification/notification.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { agentInfoTableDataFormatter } from "../../config/tableDataFormator.js";
import { getOrgToken } from "../../config/global.js";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Col, Row } from "react-bootstrap";
import { AppSettings } from "../../config/app-settings.js";
import Welcome from "../../components/welcomePage/welcome.js";
import { Loader } from "../../components/loader.js";
import TableShortingWithoutPagination from "../../components/table/AllShortingWithoutPagination.js";

const tableHeader = [
  "Physical Id",
  "Model Name",
  "Vendor Id",
  "OS",
  "Platform",
  "Host IP",
  "Status",
];

var date = new Date();

const customIcon = L.icon({
  iconUrl: "/assets/img/cross.svg", // Path to your custom marker icon
  iconSize: [25, 41], // Adjust the width and height of the icon
  iconAnchor: [12, 41], // Adjust the position of the icon anchor
  popupAnchor: [0, -41], // Adjust the position of the popup anchor
});

function dateFormatter(d) {
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

  return `${ye}-${mo}-${da}`;
}
function Dashboard() {
  const { welcomePage, setWelcomePage, rowData, setRowData } =
    useContext(AppSettings);
  const orgID = JSON.parse(getOrgToken());
  const [lastSeventActiveLabels, setLastSeventActiveLabels] = useState([]);
  const [lastSeventActiveLabelsValue, selastSeventActiveLabelsValue] = useState(
    []
  );
  const [show, setShow] = useState(false);
  const [labelsData, setLabels] = useState([
    "Active",
    "Disconnected",
    "Never Connected",
    "Pending",
  ]);
  const [seriesData, setSeries] = useState([]);
  const [agentData, setAgentData] = useState([]);
  const [singleBar, setSingleBar] = useState([]);
  const [ddlSelectedOption, setDdlSelectedOption] = useState("ALL");
  const [modifiedAgentData, setModifiedAgentData] = useState([]);

  const themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  const themeColorRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme-rgb")
    .trim();

  function randomNo() {
    return Math.floor(Math.random() * 60) + 30;
  }

  // server chart
  var serverChartData = [
    { name: "MEMORY USAGE", data: 2 },
    { name: "CPU USAGE", data: 4 },
  ];

  const [statsData, setStatsData] = useState();
  const [serverData, setServerData] = useState();
  const [countryData, setCountryData] = useState();
  const [sourceData, setSourceData] = useState();
  const [sourceChartData, setSourceChartData] = useState();
  const [productData, setProductData] = useState();
  const [activityLogData, setActivityLogData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions());
  const [mapMarker, setMapMarker] = useState([]);
  const [allIpdData, setAllIpdData] = useState();
  const [serverChartOptions, setServerChartOptions] = useState(
    getServerChartOptions()
  );
  const navigateData = [
    "active",
    "all",
    "disconnect",
    "never_connected",
    "pending",
  ];
  const [ddlOptions, setDDLOptions] = useState([
    {
      label: "ALL",
      isSelected: true,
      value: "all",
    },

    {
      label: "ACTIVE",
      isSelected: false,
      value: "active",
    },
    {
      label: "DISCONNECT",
      isSelected: false,
      value: "disconnect",
    },
    {
      label: "PENDING",
      isSelected: false,
      value: "pending",
    },
    {
      label: "NEVER CONNECTED",
      isSelected: false,
      value: "never_connected",
    },
  ]);

  function fncDDLSelection(e) {
    let data = ddlOptions;
    var newData = data.map((da) => {
      if (da.value == e.target.id) {
        da.isSelected = true;
      } else {
        da.isSelected = false;
      }
      return da;
    });

    setDDLOptions(newData);
    setDdlSelectedOption(e.target.textContent);
    getStatusWiseDataRequest(e.target.id);
  }

  //socket work and apis call
  const [dashData, setDashData] = useState([]);
  const [dashDataArray, setDashDataArray] = useState([]);
  const [suspiciousAgentData, setSuspiciousAgentData] = useState([]);
  const [alertAgentData, setAlertAgentData] = useState([]);
  const [alertsAgentTotal, setAlertsAgentTotal] = useState(0);
  const [suspiciousAgentTotal, setSuspiciousAgentTotal] = useState(0);
  const [endDatedata, setEndDate] = useState(new Date());
  const [startDatedata, setStartDate] = useState(() => {
    var d = new Date();
    var day = d.getDate() - 7;
    d.setDate(day);
    return d;
  });

  const [fullViewTable, setFullViewTable] = useState(false);
  const [tableName, setTableName] = useState();

  function handleEvent(event, { startDate, endDate }) {
    setStartDate(startDate.format("YYYY-MM-DD"));
    setEndDate(endDate.format("YYYY-MM-DD"));
    getHistoryData(
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    );
  }

  async function doGetRequest() {
    let res = await orgAxios.get(`agents_metadata`);
    setDashData(res?.data?.data?.agents_info);

    let seriesD = [
      res?.data?.data?.agents_info?.total_active_agents,
      res?.data?.data?.agents_info?.total_disconnected_agents,
      res?.data?.data?.agents_info?.total_never_connected_agents,
      res?.data?.data?.agents_info?.total_pending_agents,
    ];
    setSeries(seriesD);
    setDashDataArray(
      Object.entries(res?.data?.data?.agents_info).map(([key, value]) => ({
        key,
        value,
      }))
    );
    if (res?.data?.data?.agents_data.length > 0) {
      setWelcomePage(2);
    } else if (!res?.data?.data?.installed_agents) {
      setWelcomePage(1);
    }
  }
  const geoDataHits = async () => {
    await orgAxios.get("index/geo-location").then((geoData) => {
      let singleGeoArry = [];
      setAllIpdData(geoData?.data?.data);
      Object.entries(geoData?.data?.data).forEach(([key, value]) => {
        singleGeoArry.push({
          name: `${value.country},${value.city}`,
          count: value.count,
          coords: [value.lat, value.lon],
          city: value.city,
          country: value.country,
          ip: key,
        });
      });
      setMapMarker(singleGeoArry);
    });
  };

  useEffect(() => {
    modifiedAgentInfo(agentData);
  }, [agentData]);

  async function getStatusWiseDataRequest(status) {
    let res = await orgAxios.get(`agents_metadata?status=${status}`);

    setAgentData(res?.data?.data?.agents_data);
    setModifiedAgentData(
      agentInfoTableDataFormatter(res?.data?.data?.agents_data)
    );
  }

  async function getHistoryData(start, end) {
    setLastSeventActiveLabels([]);
    selastSeventActiveLabelsValue([]);

    try {
      let labels = [];
      let values = [];
      let res = await orgAxios.get(
        `aggregate_agent_status_wise_data?status=active&from_date=${
          start == undefined ? dateFormatter(startDatedata) : start
        }&to_date=${end == undefined ? dateFormatter(endDatedata) : end}`
      );
      res?.data?.data.map((d, i) => {
        labels.push(d.date);
        values.push(d.count);
      });
      setLastSeventActiveLabels(labels);
      selastSeventActiveLabelsValue(values);
    } catch (err) {}
  }

  async function getSuspiciousAgentsApi(start, end) {
    let res = await orgAxios.get(`suspicious_agents`);
    setSuspiciousAgentData(res?.data?.data?.agents);
    setSuspiciousAgentTotal(res?.data?.data?.affected_agents);
  }

  async function getAlertsAgentsApi(start, end) {
    let res = await orgAxios.get(`agent/alerts/`);
    setAlertAgentData(res?.data?.data);
    setAlertsAgentTotal(res?.data?.data?.length);
  }

  //agent data formations for new custom table
  const modifiedAgentInfo = (agentData) => {
    let modifiedAgentDataRaw = [];
    agentData?.map((agent) => {
      modifiedAgentDataRaw.push({
        _id: agent._id ? agent._id : "-",
        hostname: agent.hostInfo?.hostname ? agent.hostInfo?.hostname : "-",
        physicalId: agent.cpuInfo?.[0]?.physicalId
          ? agent.cpuInfo?.[0]?.physicalId
          : "-",
        modelName: agent.cpuInfo?.[0]?.modelName
          ? agent.cpuInfo?.[0]?.modelName
          : "-",
        vendorId: agent.cpuInfo?.[0]?.vendorId
          ? agent.cpuInfo?.[0]?.vendorId
          : "-",
        os: agent.hostInfo.os ? agent.hostInfo.os : "-",
        platform: agent.hostInfo.platform ? agent.hostInfo.platform : "-",
        hostIP: agent.hostIP ? agent.hostIP : "-",
        status: agent.status ? agent.status : "-",
        alias_name: agent?.alias_name ? agent?.alias_name : "-",
      });
    });
    setModifiedAgentData(modifiedAgentDataRaw);
  };

  useEffect(() => {
    doGetRequest();
    getStatusWiseDataRequest("all");
    getHistoryData();
    getSuspiciousAgentsApi();
    getAlertsAgentsApi();
    geoDataHits();
  }, []);
  useEffect(() => {
    function onConnect(data) {}

    function onDisconnect(data) {}
    socket.on("connect", onConnect());
    socket.on("disconnect", onDisconnect());
    // socket.on("agent_added", doGetRequest());
    socket.on("deactivate_agent", (data) => {
      doGetRequest("yes", data);
    });
    socket.on("activate_agent", (data) => {
      doGetRequest("yes", data);
    });

    return () => {
      socket.off("connect", onConnect());
      socket.off("disconnect", onDisconnect());
      // socket.off("agent_added",(data) =>  {
      //   console.log("agent_added", data);
      //   doGetRequest()});
        socket.off("activate_agent", (data) => {
          doGetRequest("yes", data);
        });
        socket.off("deactivate_agent", (data) => {
          doGetRequest("yes", data);
        });
    };
  }, []);

  function getChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      line: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { curve: "straight", width: 2 },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      pie: {
        colors: [
          "rgba(" + themeColorRgb + ", 1)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .5)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      donut: {
        colors: [
          "rgba(" + themeColorRgb + ", .15)",
          "rgba(" + themeColorRgb + ", .35)",
          "rgba(" + themeColorRgb + ", .55)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .95)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }

  function getServerChartOptions() {
    var borderColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-border-color")
      .trim();
    var bodyColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-color")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeFont = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();

    return {
      chart: { toolbar: { show: false } },
      plotOptions: {
        bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" },
      },
      dataLabels: { enabled: false },
      grid: { show: true, borderColor: borderColor },
      stroke: { show: false },
      colors: ["rgba(" + inverseRgb + ", .15)", themeColor],
      legend: { fontFamily: themeFont, labels: { colors: bodyColor } },
      xaxis: {
        categories: ["Jan", "Feb"],
        labels: { show: false },
        axisBorder: {
          show: true,
          color: borderColor,
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: -1,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: bodyColor,
            fontSize: "12px",
            fontFamily: themeFont,
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      fill: { opacity: 0.65 },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    };
  }

  function renderMap() {
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    const map = document.getElementById("world-map");
    const mapElm = document.querySelectorAll(".jvm-tooltip");

    if (map) {
      for (let i = 0; i < mapElm.length; i++) {
        mapElm[i].remove();
      }
      map.innerHTML = "";

      new jsVectorMap({
        selector: "#world-map",
        map: "world",
        zoomButtons: true,
        normalizeFunction: "polynomial",
        hoverOpacity: 0.5,
        hoverColor: false,
        zoomOnScroll: false,
        series: { regions: [{ normalizeFunction: "polynomial" }] },
        labels: { markers: { render: (marker) => marker.name } },
        focusOn: { x: 0.5, y: 0.5, scale: 1 },
        markerStyle: {
          initial: { fill: themeColor, stroke: "none", r: 5 },
          hover: { fill: themeColor },
        },
        regionStyle: {
          initial: {
            fill: inverse,
            fillOpacity: 0.35,
            stroke: "none",
            strokeWidth: 0.4,
            strokeOpacity: 1,
          },
          hover: { fillOpacity: 0.5 },
        },
        backgroundColor: "transparent",
      });
    }
  }

  useEffect(() => {
    fetch("/assets/data/dashboard/stats.json")
      .then((res) => res.json())
      .then((result) => {
        setStatsData(result);
      });
    fetch("/assets/data/dashboard/server.json")
      .then((res) => res.json())
      .then((result) => {
        setServerData(result);
      });
    fetch("/assets/data/dashboard/country.json")
      .then((res) => res.json())
      .then((result) => {
        setCountryData(result);
      });
    fetch("/assets/data/dashboard/source.json")
      .then((res) => res.json())
      .then((result) => {
        setSourceData(result);
      });
    fetch("/assets/data/dashboard/source-chart.json")
      .then((res) => res.json())
      .then((result) => {
        setSourceChartData(result);
      });
    fetch("/assets/data/dashboard/product.json")
      .then((res) => res.json())
      .then((result) => {
        setProductData(result);
      });
    fetch("/assets/data/dashboard/activity-log.json")
      .then((res) => res.json())
      .then((result) => {
        setActivityLogData(result);
      });

    renderMap();

    document.addEventListener("theme-reload", () => {
      setServerChartOptions(getServerChartOptions());
      setChartOptions(getChartOptions());
      renderMap();
    });

    // eslint-disable-next-line
  }, []);

  let pieOptions = {
    chart: {
      width: 200,
      type: "donut",
      foreColor: localStorage.getItem('appMode') === 'dark'? "#fffff" : "#000000"
    },
    labels: labelsData,
    colors: ["#0FE819", "#E83A0F", "#1CDBD5", "#0A0000"],
    fill: {
      colors: ["#0FE819", "#E83A0F", "#1CDBD5", "#0A0000"],
    },
    stroke: { show: false },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 720,
        options: {
          chart: {
            width: 300,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            width: 300,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 350,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 350,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 350,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 350,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };
  let newStr;
  var threatOptions = {
    chart: {
      width: 400,
      height: 400,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 50,
          size: "50%",
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "2rem",
            fontFamily: undefined,
            fontWeight: 600,
            color: themeColor,
            offsetY: 10,
          },
          value: {
            show: false,
          },
        },
        track: {
          background: themeColor, // Set the background color of the ring here
        },
      },
    },
    fill: {
      colors: "#E83A0F",
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["6/10"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 720,
        options: {
          chart: {
            width: 300,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            width: 300,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 350,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 350,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 350,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };
  if (welcomePage === 0) {
    return <Loader />;
  } else if (welcomePage === 1) {
    return <Welcome />;
  } else {
    return (
      <div>
        {/* <Notification /> */}

        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <Row>
              <Col md={6}>
                <Card className="mb-3">
                  <CardHeader className="mb-3 mt-3">AGENT CHART</CardHeader>
                  <Chart
                    className="agrntChat"
                    type="donut"
                    options={pieOptions}
                    series={seriesData}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                    }}
                  />
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-3">
                  <CardHeader className="mb-3 mt-3">THREAT SCORE</CardHeader>
                  <Chart
                    className="threatClass"
                    type="radialBar"
                    options={threatOptions}
                    series={[60]}
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="row justify-content-center">
              {dashDataArray &&
                dashDataArray.length > 0 &&
                dashDataArray.map((stat, index) => (
                  <div className="col-xl-6 col-lg-6" key={index}>
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1 text-uppercase">
                            {stat.key.replace(/_/g, " ")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-7">
                            <h3 className="mb-0">{stat.value}</h3>
                          </div>
                          <div className="col-5">
                            <div className="mt-n2">
                              <Chart
                                type={"bar"}
                                height={30}
                                options={chartOptions["bar"]}
                                series={[
                                  {
                                    name: "Visitors",
                                    data: [
                                      69, 34, 70, 73, 41, 32, 73, 72, 89, 57,
                                      42, 86, 78,
                                    ],
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <Link
                            to={`/${orgID}/analytics/${navigateData[index]}`}
                          >
                            <h6>view</h6>
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">
                    ALERTS : {alertsAgentTotal}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFullViewTable(true);
                      setTableName("alert");
                      setRowData([]);
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                </div>
                <div style={{ maxHeight: "450px", overflow: "scroll" }}>
                  <TableShorting
                    tData={alertAgentData}
                    COLUMNS={ALERTS_COLUMNS}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-6">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">
                    SUSPICIOUS AGENTS : {suspiciousAgentTotal}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFullViewTable(true);
                      setTableName("suspiciousAgent");
                      setRowData([]);
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                </div>
                <div style={{ maxHeight: "450px", overflow: "scroll" }}>
                  <TableShorting
                    tData={suspiciousAgentData}
                    COLUMNS={SUSPICIOUS_AGENTS_COLUMNS}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-6">
            <Card>
              <CardHeader className="mb-3 mt-3">IP TRACKING MAP</CardHeader>
              <CardBody>
                {mapMarker.length > 0 && (
                  <Map
                    allIpdData={allIpdData}
                    markers={mapMarker}
                    from="dashboard"
                  />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        <div style={{ textAlign: "right", margin: "50px 0px 20px 0px" }}>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              style={{
                minWidth: "100px",
                backgroundColor: "transparent",
                border: "1px solid gray",
              }}
            >
              {ddlSelectedOption}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {ddlOptions.map((data, i) => (
                <Dropdown.Item
                  onClick={fncDDLSelection}
                  active={data.isSelected}
                  id={data.value}
                  key={i}
                >
                  {data.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">AGENTS INFO</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFullViewTable(true);
                      setTableName("agentInfo");
                      setRowData([]);
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                </div>
                <div style={{ maxHeight: "450px", overflow: "scroll" }}>
                  <TableShorting
                    tData={modifiedAgentData}
                    COLUMNS={AGENT_INFO_COLUMNS}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3">
            <Card className="mb-3 mt-5">
              <DateRangePicker
                onApply={handleEvent}
                initialSettings={{
                  startDate: startDatedata,
                  endDate: endDatedata,
                }}
              >
                <input type="text" className=" datePickerInput form-control" />
              </DateRangePicker>
            </Card>
          </div>
          <div className="col-xl-9"></div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <Card className="mb-3">
              <CardBody>
                <Chart
                  type="bar"
                  height={500}
                  series={[
                    {
                      name: "Active Count",
                      data: lastSeventActiveLabelsValue,
                    },
                  ]}
                  options={{
                    plotOptions: {
                      bar: {
                        columnWidth: "20px",
                      },
                    },
                    chart: {
                      events: {
                        dataPointSelection: async (e, chart, opts) => {
                          const selectedDate = lastSeventActiveLabels.find(
                            (d, i) => {
                              return i === opts.dataPointIndex;
                            }
                          );

                          let res = await orgAxios.get(
                            `agents_metadata_status_wise?status=active&date=${selectedDate}`
                          );

                          setSingleBar(res?.data?.data);
                          setShow(true);
                        },
                      },
                    },
                    title: {
                      // text: "Last 7 days Active Nodes",
                      style: { fontSize: 15 },
                      style: {
                        color: [
                          "rgba(" + themeColorRgb + ", 1)",
                          "rgba(" + themeColorRgb + ", .75)",
                          "rgba(" + themeColorRgb + ", .5)",
                        ],
                        fontSize: 15,
                      },
                    },

                    subtitle: {
                      text: "",
                      style: { fontSize: 18 },
                    },
                    colors: [
                      "rgba(" + themeColorRgb + ", 1)",
                      "rgba(" + themeColorRgb + ", .75)",
                      "rgba(" + themeColorRgb + ", .5)",
                    ],

                    xaxis: {
                      tickPlacement: "on",
                      categories: lastSeventActiveLabels,
                      labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        minHeight: undefined,
                        maxHeight: 120,
                        style: {
                          colors: "rgb(243, 79, 160)",
                          fontSize: "12px",
                          fontFamily: "Helvetica, Arial, sans-serif",
                          fontWeight: 400,
                          cssClass: "apexcharts-xaxis-label",
                        },
                        offsetX: 0,
                        offsetY: 0,
                        format: undefined,
                        formatter: undefined,
                        datetimeUTC: true,
                        datetimeFormatter: {
                          year: "yyyy",
                          month: "MMM 'yy",
                          day: "dd MMM",
                          hour: "HH:mm",
                        },
                      },
                      title: {
                        text: "Days of the Month",
                        style: {
                          color: [
                            "rgba(" + themeColorRgb + ", 1)",
                            "rgba(" + themeColorRgb + ", .75)",
                            "rgba(" + themeColorRgb + ", .5)",
                          ],
                          fontSize: 15,
                        },
                      },
                    },

                    yaxis: {
                      labels: {
                        formatter: (val) => {
                          return `${val}`;
                        },
                        style: {
                          fontSize: "15",
                          colors: [
                            "rgba(" + themeColorRgb + ", 1)",
                            "rgba(" + themeColorRgb + ", .75)",
                            "rgba(" + themeColorRgb + ", .5)",
                          ],
                        },
                      },
                      title: {
                        text: "No of Users",
                        style: {
                          color: [
                            "rgba(" + themeColorRgb + ", 1)",
                            "rgba(" + themeColorRgb + ", .75)",
                            "rgba(" + themeColorRgb + ", .5)",
                          ],
                          fontSize: 15,
                        },
                      },
                    },

                    legend: {
                      show: true,
                      position: "right",
                    },

                    dataLabels: {
                      formatter: (val) => {
                        return `${val}`;
                      },
                      style: {
                        colors: [
                          "rgba(" + themeColorRgb + ", 1)",
                          "rgba(" + themeColorRgb + ", .75)",
                          "rgba(" + themeColorRgb + ", .5)",
                        ],
                        fontSize: 10,
                      },
                    },
                  }}
                ></Chart>
              </CardBody>
            </Card>
          </div>
        </div>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          fullscreen={false}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Total Active Agents : {singleBar.length}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    {tableHeader?.map((d, index) => (
                      <th key={index}>{d}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {singleBar &&
                    singleBar?.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {
                            data?.agent_metadata_detail?.cpuInfo?.[0]
                              ?.physicalId
                          }
                        </td>
                        <td>
                          {data?.agent_metadata_detail?.cpuInfo?.[0]?.modelName}
                        </td>
                        <td>
                          {data?.agent_metadata_detail?.cpuInfo?.[0]?.vendorId}
                        </td>
                        <td>{data?.agent_metadata_detail?.hostInfo?.os}</td>
                        <td>
                          {data?.agent_metadata_detail?.hostInfo?.platform}
                        </td>
                        <td>{data?.agent_metadata_detail?.hostIP}</td>
                        <td>{data?.agent_metadata_detail?.status}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            }
            <div className="text-end pe-2 mb-3">
              <button
                className="btn btn-primary"
                onClick={() => setShow(false)}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* table bigger view modal */}
        <Modal
          show={fullViewTable}
          onHide={() => setFullViewTable(false)}
          fullscreen={true}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
          className={localStorage.getItem('appMode') === 'dark' ? 'detailsTableModal' : 'detailsTableModalLight'}
        >
          <Modal.Header className="d-flex justify-content-between align-items-center">
            <span>
              {tableName === "agentInfo" ? (
                `AGENTS INFO`
              ) : tableName === "alert" ? (
                ` ALERTS : ${alertsAgentTotal}`
              ) : tableName === "suspiciousAgent" ? (
                `SUSPICIOUS AGENTS : ${suspiciousAgentTotal}`
              ) : (
                <></>
              )}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => setFullViewTable(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
              />
            </svg>
          </Modal.Header>
          <ModalBody>
            <div className="row">
              <div
                className={`${
                  rowData.length > 0 ? "col-9 overflow-scroll" : "col-12"
                }`}
              >
                {tableName === "agentInfo" ? (
                  <TableShortingWithoutPagination
                    tData={modifiedAgentData}
                    COLUMNS={AGENT_INFO_COLUMNS}
                  />
                ) : tableName === "alert" ? (
                  <TableShortingWithoutPagination
                    tData={alertAgentData}
                    COLUMNS={ALERTS_COLUMNS}
                  />
                ) : tableName === "suspiciousAgent" ? (
                  <TableShortingWithoutPagination
                    tData={suspiciousAgentData}
                    COLUMNS={SUSPICIOUS_AGENTS_COLUMNS}
                  />
                ) : (
                  <></>
                )}
              </div>
              {rowData.length > 0 ? (
                <div className="col-3">
                  <div
                    style={{
                      padding: "8px 12px",
                      margin: "0 0 15px 0",
                      color: "gray",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    Details
                  </div>
                  <div
                    style={{
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <div
                      style={{
                        height: "90vh",
                        overflow: "scroll",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        className="accordion detailTableDetails"
                        id="accordionPanelsStayOpenExample"
                      >
                        {rowData.length > 0 &&
                          rowData.map((data, idx) => {
                            return data.column.Header !== "View" &&
                              data.column.Header !== "Action" ? (
                              <div
                                className={`${
                                  localStorage.getItem("appMode") === "dark"
                                    ? "mb-2 text-white accordion-item detailTableDetailsItems"
                                    : "detailTableDetailsItemsLight"
                                }`}
                                key={idx}
                              >
                                <h2
                                  className="accordion-header"
                                  id={`panelsStayOpen-heading${idx}`}
                                >
                                  <button
                                    className="accordion-button collapsed" // Add "collapsed" class
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-collapse${idx}`}
                                    aria-expanded="false" // Set aria-expanded to false
                                    aria-controls={`panelsStayOpen-collapse${idx}`}
                                  >
                                    {data.column.Header}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-collapse${idx}`}
                                  className="accordion-collapse collapse" // Remove "show" class
                                  aria-labelledby={`panelsStayOpen-heading${idx}`}
                                >
                                  <div className="accordion-body">
                                    {data.value}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Dashboard;
