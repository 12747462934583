import React, { useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import { orgAxios } from "../../config/org.axios.js";
import { getAccessToken, getOrgToken } from "../../config/global.js";
import axios from "axios";
import { error } from "jquery";
import { toast } from "react-toastify";
import { LineWaveLoader } from "../../components/loader.js";

const AgentDownload = () => {
  const [key, setKey] = useState("window");
  const token = JSON.parse(getAccessToken());
  const org_id = JSON.parse(getOrgToken());
  const [agentDownloadLoader, setAgentDownloadLoader] = useState(false);

  const handleDownload = async () => {
    setAgentDownloadLoader(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? "Bearer " + token : "",
          org_id: org_id ? org_id : "",
        },
        params: {
          os_type: key,
        },
        url: `${process.env.REACT_APP_API_URL}download_agent_installer`,
        responseType: "blob",
      };

      const response = await axios(options);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${key}agent_installer.zip`);
      document.body.appendChild(link);
      link.click();
      toast.success(`Downloading file: ${key}: agent_installer.zip`);
      setAgentDownloadLoader(false);
    } catch (error) {
      toast.error(`Error downloading file: Your email is not verified`);
      setAgentDownloadLoader(false);
    }
  };

  return (
    <>
      <div
        style={{
          marginBottom: "30px",
        }}
      >
        <h1>Agent Installing Steps</h1>
      </div>
      <Card>
        <CardHeader style={{ marginBottom: "40px" }} className="">
          {" "}
          Here We List All Available agent, please find your required agent and
          download it{" "}
        </CardHeader>
        <Container className="mt-[10px]">
          <Tabs
            defaultActiveKey="table"
            id="uncontrolled-tab-example"
            className="mb-3"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab
              style={{
                zIndex: 0,
                position: "relative",
              }}
              eventKey="window"
              title="Windows Agent"
            >
              <Card style={{ marginBottom: "20px" }}>
                <CardHeader className="d-flex justify-content-between">
                  <div className="mt-auto mb-auto">Windows Agent</div>
                  <div className="agentDownLoadButtonIndividual d-flex justify-content-center">
                    {agentDownloadLoader ? (
                      <div className="d-flex justify-content-center">
                        <LineWaveLoader color={"white"} />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <div
                          onClick={() => handleDownload()}
                          className="mr-[10px]"
                        >
                          Download Agent
                        </div>
                        <i
                          onClick={() => handleDownload()}
                          className="bi bi-download"
                        ></i>
                      </>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <p>
                    Installing software on your computer can be a simple
                    process. Here are the general steps:
                  </p>
                  <ol>
                    <li>Download the software installer from the internet.</li>
                    <li>Run the installer file by double-clicking on it.</li>
                    <li>
                      Follow the prompts in the installer to complete the
                      installation.
                    </li>
                    <li>Launch the software and start using it.</li>
                  </ol>

                  <h2>Step 1: Download the Software Installer</h2>
                  <p>
                    The first step in installing software is to download the
                    installer from the internet. This can usually be done by
                    going to the software developer's website and clicking on a
                    download link. Here's an example command to download an
                    installer for a software called "example-software" on a
                    Linux system using the command line:
                  </p>
                  <code>
                    wget
                    https://www.example.com/downloads/example-software-linux-installer.sh
                  </code>
                  <p>
                    Make sure to download the correct installer for your
                    operating system, such as Windows, Mac, or Linux.
                  </p>

                  <h2>Step 2: Run the Installer File</h2>
                  <p>
                    Once you have downloaded the installer, navigate to the
                    location where it was saved and double-click on the file to
                    run it. Depending on your operating system, you may need to
                    confirm that you want to run the file and grant it
                    permission to make changes to your system. Here's an example
                    command to run the installer for "example-software" on a
                    Linux system using the command line:
                  </p>
                  <code>sh example-software-linux-installer.sh</code>

                  <h2>Step 3: Follow the Prompts in the Installer</h2>
                  <p>
                    The installer will guide you through the installation
                    process with a series of prompts. You may be asked to agree
                    to the software's license agreement, choose an installation
                    location, or select additional features to install. Follow
                    the prompts carefully and make any necessary selections.
                  </p>

                  <h2>Step 4: Launch the Software</h2>
                  <p>
                    Once the installation is complete, you can launch the
                    software by finding it in your applications or by
                    double-clicking on its desktop shortcut, if one was created
                    during installation. Here's an example command to launch
                    "example-software" on a Linux system using the command line:
                  </p>
                  <code>example-software</code>
                  <p>The software should now be ready to use.</p>

                  <p>
                    That's it! You have now successfully installed your
                    software.
                  </p>
                  {agentDownloadLoader ? (
                    <div className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-5 mb-3">
                      <div className="d-flex justify-content-center">
                        <LineWaveLoader />
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => console.log("linux agents")}
                      className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-5 mb-3"
                    >
                      Download Agent
                    </button>
                  )}
                </CardBody>
              </Card>
            </Tab>
            <Tab
              style={{
                zIndex: 0,
                position: "relative",
              }}
              eventKey="mac"
              title="Mac Agent"
            >
              <Card style={{ marginBottom: "20px" }}>
                <CardHeader className="d-flex justify-content-between">
                  <div className="mt-auto mb-auto">MacOs Agent</div>
                  <div className="agentDownLoadButtonIndividual d-flex">
                    <div className="mr-[10px]">Download Agent</div>
                    <i
                      onClick={() => console.log("Download Agent Mac")}
                      className="bi bi-download"
                    ></i>
                  </div>
                </CardHeader>
                <CardBody>
                  <p>
                    Installing software on your computer can be a simple
                    process. Here are the general steps:
                  </p>
                  <ol>
                    <li>Download the software installer from the internet.</li>
                    <li>Run the installer file by double-clicking on it.</li>
                    <li>
                      Follow the prompts in the installer to complete the
                      installation.
                    </li>
                    <li>Launch the software and start using it.</li>
                  </ol>

                  <h2>Step 1: Download the Software Installer</h2>
                  <p>
                    The first step in installing software is to download the
                    installer from the internet. This can usually be done by
                    going to the software developer's website and clicking on a
                    download link. Here's an example command to download an
                    installer for a software called "example-software" on a
                    Linux system using the command line:
                  </p>
                  <code>
                    wget
                    https://www.example.com/downloads/example-software-linux-installer.sh
                  </code>
                  <p>
                    Make sure to download the correct installer for your
                    operating system, such as Windows, Mac, or Linux.
                  </p>

                  <h2>Step 2: Run the Installer File</h2>
                  <p>
                    Once you have downloaded the installer, navigate to the
                    location where it was saved and double-click on the file to
                    run it. Depending on your operating system, you may need to
                    confirm that you want to run the file and grant it
                    permission to make changes to your system. Here's an example
                    command to run the installer for "example-software" on a
                    Linux system using the command line:
                  </p>
                  <code>sh example-software-linux-installer.sh</code>

                  <h2>Step 3: Follow the Prompts in the Installer</h2>
                  <p>
                    The installer will guide you through the installation
                    process with a series of prompts. You may be asked to agree
                    to the software's license agreement, choose an installation
                    location, or select additional features to install. Follow
                    the prompts carefully and make any necessary selections.
                  </p>

                  <h2>Step 4: Launch the Software</h2>
                  <p>
                    Once the installation is complete, you can launch the
                    software by finding it in your applications or by
                    double-clicking on its desktop shortcut, if one was created
                    during installation. Here's an example command to launch
                    "example-software" on a Linux system using the command line:
                  </p>
                  <code>example-software</code>
                  <p>The software should now be ready to use.</p>

                  <p>
                    That's it! You have now successfully installed your
                    software.
                  </p>
                  {agentDownloadLoader ? (
                    <div className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-5 mb-3">
                      <div className="d-flex justify-content-center">
                        <LineWaveLoader />
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => console.log("linux agents")}
                      className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-5 mb-3"
                    >
                      Download Agent
                    </button>
                  )}
                </CardBody>
              </Card>
            </Tab>
            <Tab
              style={{
                zIndex: 0,
                position: "relative",
              }}
              eventKey="linux"
              title="Linux Agent"
            >
              <Card style={{ marginBottom: "20px" }}>
                <CardHeader className="d-flex justify-content-between">
                  <div className="mt-auto mb-auto">Linux Agent</div>
                  <div className="agentDownLoadButtonIndividual d-flex">
                    <div className="mr-[10px]">Download Agent</div>
                    <i
                      onClick={() => console.log("Download Agent Linux")}
                      className="bi bi-download"
                    ></i>
                  </div>
                </CardHeader>
                <CardBody>
                  <p>
                    Installing software on your computer can be a simple
                    process. Here are the general steps:
                  </p>
                  <ol>
                    <li>Download the software installer from the internet.</li>
                    <li>Run the installer file by double-clicking on it.</li>
                    <li>
                      Follow the prompts in the installer to complete the
                      installation.
                    </li>
                    <li>Launch the software and start using it.</li>
                  </ol>

                  <h2>Step 1: Download the Software Installer</h2>
                  <p>
                    The first step in installing software is to download the
                    installer from the internet. This can usually be done by
                    going to the software developer's website and clicking on a
                    download link. Here's an example command to download an
                    installer for a software called "example-software" on a
                    Linux system using the command line:
                  </p>
                  <code>
                    wget
                    https://www.example.com/downloads/example-software-linux-installer.sh
                  </code>
                  <p>
                    Make sure to download the correct installer for your
                    operating system, such as Windows, Mac, or Linux.
                  </p>

                  <h2>Step 2: Run the Installer File</h2>
                  <p>
                    Once you have downloaded the installer, navigate to the
                    location where it was saved and double-click on the file to
                    run it. Depending on your operating system, you may need to
                    confirm that you want to run the file and grant it
                    permission to make changes to your system. Here's an example
                    command to run the installer for "example-software" on a
                    Linux system using the command line:
                  </p>
                  <code>sh example-software-linux-installer.sh</code>

                  <h2>Step 3: Follow the Prompts in the Installer</h2>
                  <p>
                    The installer will guide you through the installation
                    process with a series of prompts. You may be asked to agree
                    to the software's license agreement, choose an installation
                    location, or select additional features to install. Follow
                    the prompts carefully and make any necessary selections.
                  </p>

                  <h2>Step 4: Launch the Software</h2>
                  <p>
                    Once the installation is complete, you can launch the
                    software by finding it in your applications or by
                    double-clicking on its desktop shortcut, if one was created
                    during installation. Here's an example command to launch
                    "example-software" on a Linux system using the command line:
                  </p>
                  <code>example-software</code>
                  <p>The software should now be ready to use.</p>

                  <p>
                    That's it! You have now successfully installed your
                    software.
                  </p>
                  {agentDownloadLoader ? (
                    <div className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-5 mb-3">
                      <div className="d-flex justify-content-center">
                        <LineWaveLoader />
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => console.log("linux agents")}
                      className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-5 mb-3"
                    >
                      Download Agent
                    </button>
                  )}
                </CardBody>
              </Card>
            </Tab>
          </Tabs>
        </Container>
      </Card>
    </>
  );
};

export default AgentDownload;
