import React from 'react';
import App from './../app.jsx';
import { Navigate } from 'react-router-dom';

import Dashboard from './../pages/dashboard/dashboard.js';
import Analytics from './../pages/analytics/analytics.js';
import EmailInbox from './../pages/email/inbox.js';
import EmailCompose from './../pages/email/compose.js';
import EmailDetail from './../pages/email/detail.js';
import Widgets from './../pages/widgets/widgets.js';
import PosCustomerOrder from './../pages/pos/customer-order.js';
import PosKitchenOrder from './../pages/pos/kitchen-order.js';
import PosCounterCheckout from './../pages/pos/counter-checkout.js';
import PosTableBooking from './../pages/pos/table-booking.js';
import PosMenuStock from './../pages/pos/menu-stock.js';
import UiBootstrap from './../pages/ui/bootstrap.js';
import UiButtons from './../pages/ui/buttons.js';
import UiCard from './../pages/ui/card.js';
import UiIcons from './../pages/ui/icons.js';
import UiModalNotifications from './../pages/ui/modal-notifications.js';
import UiTypography from './../pages/ui/typography.js';
import UiTabsAccordions from './../pages/ui/tabs-accordions.js';
import FormElements from './../pages/form/elements.js';
import FormPlugins from './../pages/form/plugins.js';
import FormWizards from './../pages/form/wizards.js';
import TableElements from './../pages/table/elements.js';
import TablePlugins from './../pages/table/plugins.js';
import ChartJs from './../pages/charts/js.js';
import ChartApex from './../pages/charts/apex.js';
import Map from './../pages/map/map.js';
import LayoutStarter from './../pages/layout/starter.js';
import LayoutFixedFooter from './../pages/layout/fixed-footer.js';
import LayoutFullHeight from './../pages/layout/full-height.js';
import LayoutFullWidth from './../pages/layout/full-width.js';
import LayoutBoxedLayout from './../pages/layout/boxed-layout.js';
import LayoutCollapsedSidebar from './../pages/layout/collapsed-sidebar.js';
import PagesScrumBoard from './../pages/pages/scrum-board.js';
import PagesProducts from './../pages/pages/products.js';
import PagesProductDetails from './../pages/pages/product-details.js';
import PagesOrders from './../pages/pages/orders.js';
import PagesOrderDetails from './../pages/pages/order-details.js';
import PagesGallery from './../pages/pages/gallery.js';
import PagesSearchResults from './../pages/pages/search-results.js';
import PagesComingSoon from './../pages/pages/coming-soon.js';
import PagesError from './../pages/pages/error.js';
import PagesLogin from './../pages/pages/login.js';
import PagesRegister from './../pages/pages/register.js';
import PagesMessenger from './../pages/pages/messenger.js';
import PagesDataManagement from './../pages/pages/data-management.js';
import Profile from './../pages/profile/profile.js';
import Calendar from './../pages/calendar/calendar.js';
import Settings from './../pages/settings/settings.js';
import Helper from './../pages/helper/helper.js';
import LogDetails from '../pages/logdetails/logdetails.js';
import AgentDownload from '../pages/agentDownload/agentDownload.js';
import IocUpload from '../pages/iocUpload/iocUpoload.js';
import PublicIp from '../pages/publicIp/publicIp.js';
import VerifyEmail from '../pages/verifyEmail/verifyEmail.js';
import ForgetPassword from '../pages/forgetPassword/forgetPassword.js';
import { getOrgToken } from './global.js';
import AgentActions from '../pages/agentActions/agentActions.js';

const AppRoute = [
  {
	path: '*',
    element: <App />,
    children: [
		{
			path: '',
			element: <Navigate to={`/${JSON.parse(getOrgToken())}/dashboard`} replace />
		  },
		{ path: ':orgID/*', 
		children: [
			{ path: '', element: <Navigate to={`/${JSON.parse(getOrgToken())}/dashboard`} /> },
			{ path: 'dashboard', element: <Dashboard /> },
			{ path: 'analytics/:agentID/:id', element: <LogDetails /> },
			{ path: 'analytics/:agentID', element: <Analytics /> },
			{ path: 'publicIp/:publicIp', element: <PublicIp /> },
			{path:'agentDownload',element: <AgentDownload /> },
			{path:'iocUpload',element: <IocUpload/>},
			{path:'profile',element: <Profile/>},
			{path:'agentactions/:agentHostIp',element:<AgentActions/>},
			{ path: '*', element: <PagesError /> },
			]
		},
		{
			path: '*',
			children: [
				{path:'client/verify-email/:emailVerificationToken', element: <VerifyEmail/>},
				{path:'reset-password/:resetPasswordToken', element: <ForgetPassword/>},
			]
		},
    	{ path: 'pages/*', 
    		children: [
					{ path: 'scrum-board', element: <PagesScrumBoard /> },
					{ path: 'products', element: <PagesProducts /> },
					{ path: 'product-details', element: <PagesProductDetails /> },
					{ path: 'orders', element: <PagesOrders /> },
					{ path: 'order-details', element: <PagesOrderDetails /> },
					{ path: 'gallery', element: <PagesGallery /> },
					{ path: 'search-results', element: <PagesSearchResults /> },
					{ path: 'coming-soon', element: <PagesComingSoon /> },
					{ path: 'error', element: <PagesError /> },
					{ path: 'login', element: <PagesLogin /> },
					{ path: 'register', element: <PagesRegister /> },
					{ path: 'messenger', element: <PagesMessenger /> },
					{ path: 'data-management', element: <PagesDataManagement /> },
				]
		},
		]
  }
];


export default AppRoute;
