import React, { useEffect, useState } from "react";
import Header from "./components/header/header.jsx";
import Sidebar from "./components/sidebar/sidebar.jsx";
import Content from "./components/content/content.jsx";
import Footer from "./components/footer/footer.jsx";
import ThemePanel from "./components/theme-panel/theme-panel.jsx";
import PagesLogin from "./pages/pages/login.js";
import { AppSettings } from "./config/app-settings.js";
import { Link, Navigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Notification from "./components/notification/notification.js";
import PagesRegister from "./pages/pages/register.js";
import {
  getAccessToken,
  getOrgToken,
  removeAccessToken,
  removeOrgToken,
  removeUser,
} from "./config/global.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import VerifyEmail from "./pages/verifyEmail/verifyEmail.js";
import ForgetPassword from "./pages/forgetPassword/forgetPassword.js";
import PagesError from "./pages/pages/error.js";

function App() {
  const { orgID } = useParams();
  var defaultOptions = {
    appMode: "",
    appTheme: "",
    appCover: "",
    appHeaderNone: false,
    appSidebarNone: false,
    appSidebarCollapsed: false,
    appContentNone: false,
    appContentClass: "",
    appContentFullHeight: false,
    appBoxedLayout: false,
    appFooter: false,
    hasScroll: false,
  };
  const [notification, setNotification] = useState({});
  const [customIocCanPreviousPage, setCustomIocCanPreviousPage] =
    useState(false);
  const [customIocCanNextPage, setCustomIocCanNextPage] = useState(true);
  const [customIocCurrentPage, setCustomIocCurrentPage] = useState(1);
  const [totalPageCustomIoc, setTotalPageCustomIoc] = useState(0);
  const [customIocData, setCustomIocData] = useState([]);
  const [customIocSearchQuery, setCustomIocSearchQuery] = useState("");
  const [customIocCount, setCustomIocCount] = useState(0);
  const [customIocLimit, setCustomIocLimit] = useState(50);
  const [welcomePage, setWelcomePage] = useState(0);
  const [agentHostIp, setAgentHostIp] = useState();
  const [rowData, setRowData] = useState([]);

  const [userprofileData, setUserProfileData] = useState({});
  const [clientProfileData, setClientProfileData] = useState({});
  const [appHeaderNone, setAppHeaderNone] = useState(
    defaultOptions.appHeaderNone
  );

  const [appSidebarNone, setAppSidebarNone] = useState(
    defaultOptions.appSidebarNone
  );
  const [appSidebarCollapsed, setAppSidebarCollapsed] = useState(
    defaultOptions.appSidebarCollapsed
  );
  const [appContentNone, setAppContentNone] = useState(
    defaultOptions.appContentNone
  );
  const [appContentClass, setAppContentClass] = useState(
    defaultOptions.appContentClass
  );
  const [appContentFullHeight, setAppContentFullHeight] = useState(
    defaultOptions.appContentFullHeight
  );
  const [appBoxedLayout, setAppBoxedLayout] = useState(
    defaultOptions.appBoxedLayout
  );
  const [appFooter, setAppFooter] = useState(defaultOptions.appFooter);
  const [hasScroll, setHasScroll] = useState(defaultOptions.hasScroll);
  const providerValue = {
    setAppHeaderNone,
    setAppSidebarNone,
    setAppSidebarCollapsed,
    setAppContentNone,
    setAppContentClass,
    setAppContentFullHeight,
    setAppBoxedLayout,
    setAppFooter,
    notification,
    setNotification,
    clientProfileData,
    setClientProfileData,
    userprofileData,
    setUserProfileData,
    customIocCanPreviousPage,
    setCustomIocCanPreviousPage,
    customIocCanNextPage,
    setCustomIocCanNextPage,
    customIocCurrentPage,
    setCustomIocCurrentPage,
    totalPageCustomIoc,
    setTotalPageCustomIoc,
    customIocData,
    setCustomIocData,
    customIocSearchQuery,
    setCustomIocSearchQuery,
    customIocCount,
    setCustomIocCount,
    customIocLimit,
    setCustomIocLimit,
    welcomePage,
    setWelcomePage,
    agentHostIp,
    setAgentHostIp,
    rowData,
    setRowData,
  };
  //   const { orgID } = useParams();
  var handleSetAppTheme = (value) => {
    if (value) {
      var newTheme = value;
      for (var x = 0; x < document.body.classList.length; x++) {
        if (
          document.body.classList[x].indexOf("theme-") > -1 &&
          document.body.classList[x] !== newTheme
        ) {
          document.body.classList.remove(document.body.classList[x]);
        }
      }

      if (localStorage && value) {
        localStorage.appTheme = value;
      }

      document.body.classList.add(newTheme);
      document.dispatchEvent(new Event("theme-reload"));
    }
  };

  var handleSetAppMode = (value) => {
    if (value) {
      document.documentElement.setAttribute("data-bs-theme", value);
    }
  };

  var handleSetAppCover = (value) => {
    if (value) {
      var htmlElm = document.querySelector("html");
      for (var x = 0; x < document.documentElement.classList.length; x++) {
        var targetClass = document.documentElement.classList[x];
        if (targetClass.search("bg-cover-") > -1) {
          htmlElm.classList.remove(targetClass);
        }
      }
      htmlElm.classList.add(value);

      if (localStorage && value) {
        localStorage.appCover = value;
      }
    }
  };

  useEffect(() => {
    if (defaultOptions.appMode) {
      handleSetAppMode(defaultOptions.appMode);
    }
    if (defaultOptions.appTheme) {
      handleSetAppTheme(defaultOptions.appTheme);
    }
    if (defaultOptions.appCover) {
      handleSetAppCover(defaultOptions.appCover);
    }
    window.addEventListener("scroll", handleScroll);

    if (localStorage) {
      if (typeof localStorage.appMode !== "undefined") {
        handleSetAppMode(localStorage.appMode);
      }
      if (typeof localStorage.appTheme !== "undefined") {
        handleSetAppTheme(localStorage.appTheme);
      }
      if (typeof localStorage.appCover !== "undefined") {
        handleSetAppCover(localStorage.appCover);
      }
    }

    return function cleanUp() {
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line
  }, []);

  var handleScroll = () => {
    setHasScroll(window.scrollY > 0 ? true : false);
  };
  return (
    <GoogleOAuthProvider clientId="584238059885-p7ikbd7arf6q4g3al1cssjtk63ohgpc2.apps.googleusercontent.com">
      <AppSettings.Provider value={providerValue}>
        {getAccessToken() && getOrgToken() ? (
          JSON.parse(getOrgToken()) === orgID || orgID === undefined ? (
            <div
              className={
                "app " +
                (appBoxedLayout ? "app-boxed-layout " : "") +
                (appContentFullHeight ? "app-content-full-height " : "") +
                (appHeaderNone ? "app-without-header " : "") +
                (appSidebarNone ? "app-content-full-width " : "") +
                (appSidebarCollapsed ? "app-sidebar-collapsed " : "") +
                (appFooter ? "app-footer-fixed " : "")
                // +
                // (hasScroll ? 'has-scroll ' : '')
              }
            >
              {!appHeaderNone && <Header />}
              {!appSidebarNone && <Sidebar />}
              {!appContentNone && <Content className={appContentClass} />}
              {appFooter && <Footer />}
              <ThemePanel />
              <ToastContainer />
              <Notification />
            </div>
          ) : (
            <PagesError />
          )
        ) : (
          <>
            {window.location.pathname.includes("register") ? (
              <>
                {
                  (removeOrgToken(),
                  removeAccessToken(),
                  removeUser(),
                  localStorage.removeItem("xdr_notification"))
                }
                <PagesRegister />
                <ToastContainer />
              </>
            ) : window.location.pathname.includes("client/verify-email") ? (
              <>
                <VerifyEmail />
                <ToastContainer />
              </>
            ) : window.location.pathname.includes("reset-password") ? (
              <>
                {
                  (removeOrgToken(),
                  removeAccessToken(),
                  removeUser(),
                  localStorage.removeItem("xdr_notification"))
                }
                <ForgetPassword />
                <ToastContainer />
              </>
            ) : (
              <>
                {
                  (removeOrgToken(),
                  removeAccessToken(),
                  removeUser(),
                  localStorage.removeItem("xdr_notification"))
                }
                <PagesLogin />
                <ToastContainer />
              </>
            )}
          </>
        )}
      </AppSettings.Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
