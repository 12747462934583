import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useContext, useEffect, useRef, useState } from "react";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import { orgAxios } from "../../config/org.axios.js";
import { socket } from "../../socket/SocketConnection.js";
import { Link, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { Col, Container, ModalBody, Row } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import TableShorting from "../../components/table/AllShortingTable.js";
import {
  BACKGROUND_PROCESS_COLUMNS,
  IP_TRACKING_COLUMNS,
  PROCESS_INFO_COLUMNS,
  SYS_LOG_COLOUMNS,
  ALERTS_COLUMNS_LOGDETAILS,
  HASH_DETECTION_COLUMNS,
  SANDBOX_DETECTION_COLUMNS,
  AGENT_PORT_COLOUM,
} from "../../components/table/Columns.js";
import { Loader, LineWaveLoader } from "../../components/loader.js";
import {
  processDataFormatter,
  agentPortDataFormatter,
} from "../../config/tableDataFormator.js";
import Map from "../NewMap/map.js";
import { toast } from "react-toastify";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { instructAxios } from "../../config/instraction.axios.js";
import { AppSettings } from "../../config/app-settings.js";
import { getOrgToken } from "../../config/global.js";
import TableShortingWithoutPagination from "../../components/table/AllShortingWithoutPagination.js";
import {
  FilterComponentsAgentPort,
  FilterComponentsAgentProcess,
  FilterComponentsBGProcess,
  FilterComponentsEventLog,
} from "../../components/logdetails/filterComponents.js";
import {
  Pagination,
  PaginationEventLog,
  PaginationPort,
} from "../../components/logdetails/pagination.js";

const customIcon = L.icon({
  iconUrl: "/assets/img/cross.svg", // Path to your custom marker icon
  iconSize: [25, 41], // Adjust the width and height of the icon
  iconAnchor: [12, 41], // Adjust the position of the icon anchor
  popupAnchor: [0, -41], // Adjust the position of the popup anchor
});

const tableHeaderTCP = [
  "Pid",
  "Family",
  "LocalAddr IP",
  "LocalAddr Port",
  "Process Name",
  "Protocol",
  "Remote Address IP",
  "Remote Address Port",
  "Type",
  "UID",
  "Status",
];

const tableHeaderUDP = [
  "Pid",
  "Family",
  "LocalAddr IP",
  "LocalAddr Port",
  "Process Name",
  "Protocol",
  "Remote Address IP",
  "Remote Address Port",
  "Type",
  "UID",
  "Status",
];

const tableHeaderBG = [
  "ImageName",
  "CPUTime",
  "MemUsage",
  "PID",
  "SessionName",
  "SessionNum",
  "Status",
  "UserName",
  "WindowTitle",
];

function LogDetails() {
  const { agentHostIp, setAgentHostIp, rowData, setRowData } =
    useContext(AppSettings);
  const { agentID, id } = useParams();
  const [seriesDataPort, setSeriesPort] = useState([]);
  const [ipAddressDataPort, setIpAddressDataPort] = useState([]);
  const [pieVisiblePORT, setPieVisiblePORT] = useState(false);
  const [pieVisibleDiskInfo, setPieVisibleDiskInfo] = useState(false);
  const [pieVisibleMemoryInfo, setPieVisibleMemoryInfo] = useState(false);
  const [ipAddresPie, setIpAddressPie] = useState(false);
  const [ipTracking, setIpTracking] = useState("");
  const [ddlSelectedOption, setDdlSelectedOption] = useState("ALL");
  const [ddlEventLogSelectedOption, setDdlEventLogSelectedOption] =
    useState("Security Logs");

  const [alertAgentData, setAlertAgentData] = useState([]);
  const [alertsAgentTotal, setAlertsAgentTotal] = useState(0);

  const [ddlOptions, setDDLOptions] = useState([
    {
      label: "ALL",
      isSelected: true,
      value: "all",
    },

    {
      label: "TCP STATS",
      isSelected: false,
      value: "tcp",
    },
    {
      label: "UDP STATS",
      isSelected: false,
      value: "udp",
    },
  ]);

  const [ddlEventLogOptions, setDDLEventLogOptions] = useState([
    {
      label: "Security Logs",
      isSelected: true,
      value: "security_logs",
    },

    {
      label: "Application Logs",
      isSelected: false,
      value: "application_logs",
    },
    {
      label: "Event Logs",
      isSelected: false,
      value: "event_logs",
    },
    {
      label: "Sys Logs",
      isSelected: false,
      value: "sys_logs",
    },
  ]);
  const [levels, setLevel] = useState([]);
  // const [pckSent, setPctSent] = useState([86.02,13.02,51.02,77.02,85.02]);
  // const [pckRev, setPctRev] = useState([44.74,53.74,2.74,85.74,71.74]);
  // const [byteSent, setBytePctSent] = useState([24086526636,24086526636,24086526636,24086526636,24086526636]);
  // const [byteRev, setBytePctRev] = useState([1654779963,1654779963,1654779963,1654779963,1654779963]);
  const [pckSent, setPctSent] = useState([]);
  const [pckRev, setPctRev] = useState([]);
  const [byteSent, setBytePctSent] = useState([]);
  const [byteRev, setBytePctRev] = useState([]);
  const [key1, setKey1] = useState("pct");
  const [eventLogs, setEventLogs] = useState();
  const [eventLogsAll, setEventLogsAll] = useState();
  const [mapMarker, setMapMarker] = useState([]);
  const [mapMarkerLines, setMapMarkerLines] = useState([]);
  const [agentAliesName, setAgentAliesName] = useState("");
  const [updateAgentAlias, setUpdateAgentAlias] = useState("");
  const [aliasModal, setAliasModal] = useState(false);
  const [loaderShow, setLoaderShow] = useState(true);
  const [aliasLoader, setAliasLoader] = useState(false);
  const [fullViewTable, setFullViewTable] = useState(false);
  const [tableName, setTableName] = useState();

  const [blockLoader, setBlockLoader] = useState(false);
  const [blockWebsite, setBlockWebsite] = useState("");

  const [canNextPageBGprocess, setCanNextPageBGprocess] = useState(true);
  const [canPreviousPageBGprocess, setCanPreviousPageBGprocess] =
    useState(false);
  const [totalPagesBGprocess, setTotalPagesBGprocess] = useState(1);
  const [currentPageBGprocess, setCurrentPageBGprocess] = useState(1);
  const [pageLimitBGprocess, setPageLimitBGprocess] = useState(50);

  const [canNextPageAgentProcess, setCanNextPageAgentProcess] = useState(true);
  const [canPreviousPageAgentProcess, setCanPreviousPageAgentProcess] =
    useState(false);
  const [totalPagesAgentProcess, setTotalPagesAgentProcess] = useState(1);
  const [currentPageAgentProcess, setCurrentPageAgentProcess] = useState(1);
  const [processState, setProcessState] = useState("all");
  const [pageLimitAgentProcess, setPageLimitAgentProcess] = useState(50);

  const [canNextPageEventlog, setCanNextPageEventlog] = useState(true);
  const [canPreviousPageEventlog, setCanPreviousPageEventlog] = useState(false);
  const [totalPagesEventlog, setTotalPagesEventlog] = useState(1);
  const [currentPageEventlog, setCurrentPageEventlog] = useState(1);
  const [eventlogState, setEventlogState] = useState("security_logs");
  const [pageLimitEventlog, setPageLimitEventlog] = useState(50);

  const [to_date, setTo_date] = useState(dateFormatter(new Date()));
  const [from_date, setFrom_date] = useState(() => {
    var d = new Date();
    var day = d.getDate() - 1;
    d.setDate(day);
    return dateFormatter(d);
  });

  const [canNextPagePort, setCanNextPagePort] = useState(true);
  const [canPreviousPagePort, setCanPreviousPagePort] = useState(false);
  const [totalPagesPort, setTotalPagesPort] = useState(1);
  const [currentPagePort, setCurrentPagePort] = useState(1);
  const [pageLimitPort, setPageLimitPort] = useState(50);

  const [pieVisibleNetworkInfoInBytes, setPieVisibleNetworkInfoInBytes] =
    useState(false);
  const [pieVisibleNetworkInfoInPackets, setPieVisibleNetworkInfoInPackets] =
    useState(false);

  const [show, setShow] = useState(false);
  const [showHash, setShowHash] = useState(false);

  const themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  const themeColorRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme-rgb")
    .trim();

  //socket work and apis call
  const [allData, setAllData] = useState();
  const [agentMetadata, setAgentMetadata] = useState();
  const [agentPortdataMod, setAgentPortdataMod] = useState();
  const [agentPortdataModAll, setAgentPortdataModAll] = useState();
  const [agentProcessModified, setAgentProcessModified] = useState([]);
  const [agentProcessModifiedAll, setAgentProcessModifiedAll] = useState([]);
  const [agentBGProcessdata, setAgentBGProcessdata] = useState();
  const [agentAllBGProcessdata, setAgentAllBGProcessdata] = useState();
  const [ipAddressData, setIPAddressData] = useState([]);
  const [hashData, setHashData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [maliciousData, setMaliciousData] = useState({});

  //date selection
  const [hostIp, setHostIp] = useState(null);
  const [publicIp, setPublicIp] = useState(null);

  const handleCancelFilter = () => {
    if (tableName === "bgProcess") {
      agentAllBgProcessDetails("");
    } else if (tableName === "port") {
      agentAllPortDetails("");
    } else if (tableName === "agentProcess") {
      getStatusWiseDataRequestAll("");
    }else if(tableName === "eventlog"){
      eventLogsApiCallAll(hostIp, eventlogState,'');
    }

  };

  const handleChangeAlias = async (id) => {
    setAliasLoader(true);
    orgAxios
      .put(`update_agent_name/${id}`, {
        agent_alias: updateAgentAlias.toLowerCase(),
      })
      .then((response) => {
        if (response.status === 200) {
          setAgentAliesName(response?.data?.data?.alias_name);
          toast.success(response?.data?.message);
          setAliasLoader(false);
          setAliasModal(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const handleBlockWebsite = async () => {
    setBlockLoader(true);

    function convertToWWWLink(link) {
      // Remove "https://" or "http://"
      link = link.replace(/^(https?:\/\/)?/i, "");

      // Add "www." if it doesn't exist
      if (!link.startsWith("www.")) {
        link = "www." + link;
      }

      return link;
    }
    var modifiedBlockWebsite = convertToWWWLink(blockWebsite);

    instructAxios
      .post(`agent-instructions`, {
        hostip: agentHostIp,
        instruction: "block-domain",
        service_name: modifiedBlockWebsite,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setBlockWebsite(response?.data?.data?.alias_name);
          toast.success(
            response?.data?.message + " to block " + modifiedBlockWebsite
          );
          setBlockLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setBlockLoader(false);
      });
  };
  async function getStatusWiseDataRequest(page, limit, status) {
    await orgAxios
      .get(
        `api/v2/agent-process-data/${id}?status=${status}&page=${page}&limit=${limit}`
      )
      .then((res) => {
        if (res.status === 200) {
          setAgentProcessModified(processDataFormatter(res?.data?.data));
          setTotalPagesAgentProcess(res?.data?.total_pages);
          if (page === 1) {
            setCanNextPageAgentProcess(true);
            setCanPreviousPageAgentProcess(false);
          }
          if (res?.data?.next_page !== -1) {
            setCurrentPageAgentProcess(res?.data?.next_page - 1);
          } else {
            if (res?.data?.total_pages === 1) {
              setCanNextPageAgentProcess(false);
              setCanPreviousPageAgentProcess(false);
              setCurrentPageAgentProcess(res?.data?.total_pages);
            } else {
              setCanNextPageAgentProcess(false);
              setCanPreviousPageAgentProcess(true);
              setCurrentPageAgentProcess(res?.data?.total_pages);
            }
          }
        }
      });
  }
  async function getStatusWiseDataRequestAll(filters) {
    let res = await orgAxios.get(
      `api/v2/agent-process-data/${id}?status=all&show_all=true&filters=${filters}`
    );
    setAgentProcessModifiedAll(processDataFormatter(res?.data?.data));
  }

  async function getAlertsAgentsApi(start, end) {
    const d = id;
    let res = await orgAxios.get(`agent/alerts/?agent_id=${id}`);
    setAlertAgentData(res?.data?.data);
    setAlertsAgentTotal(res?.data?.data?.length);
  }
  function fncDDLSelection(e) {
    let data = ddlOptions;
    var newData = data.map((da) => {
      if (da.value == e.target.id) {
        da.isSelected = true;
      } else {
        da.isSelected = false;
      }
      return da;
    });

    setDDLOptions(newData);
    setDdlSelectedOption(e.target.textContent);
    getStatusWiseDataRequest(1, e.target.id);
    setProcessState(e.target.id);
  }

  function fncDDLEventLogSelection(e) {
    let data = ddlEventLogOptions;
    var newData = data.map((da) => {
      if (da.value == e.target.id) {
        da.isSelected = true;
      } else {
        da.isSelected = false;
      }
      return da;
    });

    setDDLEventLogOptions(newData);
    setDdlEventLogSelectedOption(e.target.textContent);
    setEventlogState(e.target.id);
    eventLogsApiCall(hostIp, 1, pageLimitEventlog, e.target.id);
    eventLogsApiCallAll(hostIp, e.target.id,'');
  }

  function fncShowIpOrHash(type) {
    if (type?.row?.original?.type.toUpperCase() == "ip".toUpperCase()) {
      let ip = type?.row?.original?.value;
      fncShowWorldMapModel(ip);
    } else {
      let hash = type?.row?.original?.value;
      fncShowHashData(hash);
    }
  }

  const agentMetaDetails = async () => {
    let res = await orgAxios.get(`api/v2/agent-metadata-detail/${id}`);
    setAgentMetadata(
      res.data.data.cpuInfo[0] == null ? undefined : res.data.data.cpuInfo[0]
    );
    setAgentAliesName(res?.data?.data?.alias_name);
    setAgentHostIp(res?.data?.data?.hostIP);
    setHostIp(res?.data?.data?.hostIP);
    setPublicIp(res?.data?.data?.address?.public_ip);

    setLoaderShow(false);
  };
  const agentBgProcessDetails = async (page, limit) => {
    await orgAxios
      .get(
        `api/v2/agent-background-process-data/${id}?page=${page}&limit=${limit}`
      )
      .then((res) => {
        if (res.status === 200) {
          setAgentBGProcessdata(
            res?.data?.data == null ? undefined : res?.data?.data
          );
          setTotalPagesBGprocess(res?.data?.total_pages);
          if (page === 1) {
            setCanNextPageBGprocess(true);
            setCanPreviousPageBGprocess(false);
          }
          if (res?.data?.next_page !== -1) {
            setCurrentPageBGprocess(res?.data?.next_page - 1);
          } else {
            if (res?.data?.total_pages === 1) {
              setCanNextPageBGprocess(false);
              setCanPreviousPageBGprocess(false);
              setCurrentPageBGprocess(res?.data?.total_pages);
            } else {
              setCanNextPageBGprocess(false);
              setCanPreviousPageBGprocess(true);
              setCurrentPageBGprocess(res?.data?.total_pages);
            }
          }
        }
      });
  };
  const agentAllBgProcessDetails = async (filters) => {
    let res = await orgAxios.get(
      `api/v2/agent-background-process-data/${id}?show_all=true&filters=${filters}`
    );
    setAgentAllBGProcessdata(
      res?.data?.data == null ? undefined : res?.data?.data
    );
  };

  function dateFormatter(d) {
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${ye}-${mo}-${da}`;
  }

  function handleEvent(event, { startDate, endDate }) {
    setFrom_date(startDate.format("YYYY-MM-DD"));
    setTo_date(endDate.format("YYYY-MM-DD"));
    agentPortDetails(
      1,
      pageLimitPort,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    );
  }
  async function agentPortDetails(page, limit, start, end) {
    await orgAxios
      .get(
        `api/v2/agent-port-data/${id}?page=${page}&limit=${limit}&from_date=${
          start == undefined ? from_date : start
        }&to_date=${end == undefined ? to_date : end}`
      )
      .then((res) => {
        if (res.status === 200) {
          setAgentPortdataMod(
            agentPortDataFormatter(res?.data?.data?.sys_ports)
          );
          setTotalPagesPort(res?.data?.total_pages);
          if (page === 1) {
            setCanNextPagePort(true);
            setCanPreviousPagePort(false);
          }
          if (res?.data?.next_page !== -1) {
            setCurrentPagePort(res?.data?.next_page - 1);
          } else {
            if (res?.data?.total_pages === 1) {
              setCanNextPagePort(false);
              setCanPreviousPagePort(false);
              setCurrentPagePort(res?.data?.total_pages);
            } else {
              setCanNextPagePort(false);
              setCanPreviousPagePort(true);
              setCurrentPagePort(res?.data?.total_pages);
            }
          }
        }
      });

    // let portRes = await orgAxios.get(`api/v2/agent-port-data/${hostIp}?date=${dateFormatter(e)}`)
  }
  const agentAllPortDetails = async (filters) => {
    let res = await orgAxios.get(
      `api/v2/agent-port-data/${id}?show_all=true&filters=${filters}&from_date=${from_date}&to_date=${to_date}`
    );
    setAgentPortdataModAll(
      agentPortDataFormatter(
        res?.data?.data?.sys_ports ? res?.data?.data?.sys_ports : []
      )
    );
  };

  const networkInfoCall = async () => {
    await orgAxios.get(`get_sys_network_info/${id}`).then((networkInfo) => {
      if (networkInfo.status === 200) {
        setLevel(
          networkInfo?.data?.data?.date.map(function (element) {
            return element;
          })
        );
        setPctSent(
          networkInfo?.data?.data?.packetsSent.map(function (element) {
            return (element / 1048576).toFixed(2);
          })
        );
        setPctRev(
          networkInfo?.data?.data?.packetsRecv.map(function (element) {
            return (element / 1048576).toFixed(2);
          })
        );
        setBytePctSent(
          networkInfo?.data?.data?.bytesSent.map(function (element) {
            return (element / 1048576).toFixed(2);
          })
        );
        setBytePctRev(
          networkInfo?.data?.data?.bytesRecv.map(function (element) {
            return (element / 1048576).toFixed(2);
          })
        );
      }
    });
  };

  const maliciousAgent = async () => {
    await orgAxios
      .get(`malicious_suspicious_agent?agent_id=${id}`)
      .then((resMalicious) => {
        if (resMalicious.status === 200) {
          setMaliciousData(resMalicious?.data?.data);
        }
      });
  };

  async function doGetRequest() {
    let res = await orgAxios.get(`api/v2/agent-metadata-detail/${id}`);
    setAllData(res?.data?.data);
    setLoaderShow(false);
    maliciousAgent();
    networkInfoCall();
  }
  async function refreshAgent(instruction, service_name) {
    let res = await instructAxios
      .post(`agent-instructions`, {
        hostip: allData?.hostIP,
        instruction: instruction,
        service_name: service_name,
      })
      .then(
        (response) => {
          if (response.status === 201) {
            toast.success(response.data.message);
          }
        },
        (error) => {
          toast.error(error);
        }
      );
  }
  async function instructionValidate(socketResponse) {
    // console.log(" instructionValidate", socketResponse);
    const orgId = JSON.parse(getOrgToken());
    if (socketResponse.org_id === orgId) {
      if (socketResponse.status === "passed") {
        toast.success(`${socketResponse.service_name}  ${socketResponse.msg}`);
      } else if (socketResponse.status === "failed") {
        toast.warn(`${socketResponse.service_name}  ${socketResponse.msg}`);
      }
    }
  }
  async function getIpAddressRequest(ip) {
    let res = await orgAxios.get(`get_ip_analytical_data/${ip}`);

    setIpAddressDataPort([
      res?.data?.data?.details?.last_analysis_stats?.harmless,
      res?.data?.data?.details?.last_analysis_stats?.malicious,
      res?.data?.data?.details?.last_analysis_stats?.suspicious,
      res?.data?.data?.details?.last_analysis_stats?.timeout,
      res?.data?.data?.details?.last_analysis_stats?.undetected,
    ]);
    setIPAddressData(res?.data?.data);
    setIpAddressPie(true);
  }

  async function getHashAnalyticsRequest(ips) {
    var ip = "3dd7cb17c03d5859bd46354b60f11837baf422fb3ca8f2d21d67558f34007a67";
    let res = await orgAxios.get(`get_hash_analytical_data/${ip}`);
    setHashData(res?.data?.data);
  }

  const agentGeoLocation = async (ip) => {
    await orgAxios.get(`agent/geo-location/${ip}`).then((geoData) => {
      let singleGeoArry = [];
      let singleGeoArryLines = [];

      const agents = geoData?.data?.data.filter(
        (item) => item.is_agent === true
      );

      geoData?.data?.data.map((data, i) => {
        singleGeoArry.push({
          name: `${data.country},${data.city}`,
          coords: [data.lat, data.lon],
          city: data.city,
          country: data.country,
          ip: data.ip,
          vulnerable: data.vulnerable,
          is_agent: data.is_agent,
          style: {
            fill: data.is_agent
              ? "yellow"
              : data.vulnerable == "clean"
              ? "green"
              : "red", // Fill color of the marker
            "stroke-width": 2, // Stroke width of the marker
            r: 8, // Radius of the marker
          },
        });
        singleGeoArryLines.push({
          from: `${agents[0].country},${agents[0].city}`,
          to: `${data.country},${data.city}`,
          style: {
            stroke: data.vulnerable == "clean" ? "green" : "red",
          },
        });
      });

      setMapMarkerLines(singleGeoArryLines);
      setMapMarker(singleGeoArry);
    });
  };
  async function fncShowWorldMapModel(e) {
    setLoader(true);
    setIpTracking(e);
    let res = await getIpAddressRequest(e);
    setShow(true);
    setLoader(false);
  }

  async function fncShowHashData(e) {
    setLoader(true);

    let res = await getHashAnalyticsRequest(e);
    setShowHash(true);
    setLoader(false);
  }

  async function fncShowWorldMapModelMalicious(ip) {
    setLoader(true);
    setIpTracking(ip);
    let res = await getIpAddressRequest(ip);
    setShow(true);
    setLoader(false);
  }

  async function eventLogsApiCall(e, page, limit, status) {
    await orgAxios
      .get(
        `get_sys_events_logs/${e}?log_type=${status}&limit=${limit}&page=${page}`
      )
      .then((eventLog) => {
        if (eventLog.status === 200) {
          setEventLogs(eventLog?.data?.data);
          setTotalPagesEventlog(eventLog?.data?.total_pages);
          if (page === 1) {
            setCanNextPageEventlog(true);
            setCanPreviousPageEventlog(false);
          }
          if (eventLog?.data?.next_page !== -1) {
            setCurrentPageEventlog(eventLog?.data?.next_page - 1);
          } else {
            if (eventLog?.data?.total_pages === 1) {
              setCanNextPageEventlog(false);
              setCanPreviousPageEventlog(false);
              setCurrentPageEventlog(eventLog?.data?.total_pages);
            } else {
              setCanNextPageEventlog(false);
              setCanPreviousPageEventlog(true);
              setCurrentPageEventlog(eventLog?.data?.total_pages);
            }
          }
        }
      });
  }

  async function eventLogsApiCallAll(e, status, filters) {
    let eventLog = await orgAxios.get(
      `get_sys_events_logs/${e}?log_type=${status}&filters=${filters}`
    );

    setEventLogsAll(eventLog?.data?.data);
  }

  useEffect(() => {
    doGetRequest();
    agentMetaDetails();
    agentBgProcessDetails(1, pageLimitBGprocess);
    agentAllBgProcessDetails("");
    agentAllPortDetails("");
    getStatusWiseDataRequest(1, pageLimitAgentProcess, "all");
    getStatusWiseDataRequestAll("");
    agentPortDetails(1, pageLimitPort);
    // Set interval to fetch data every 5 minutes (300,000 milliseconds)
    const interval = setInterval(networkInfoCall, 300000);
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (hostIp != null) {
      getAlertsAgentsApi();
      eventLogsApiCall(hostIp, 1, pageLimitEventlog, eventlogState);
      eventLogsApiCallAll(hostIp, eventlogState,'');
      agentGeoLocation(hostIp);
    }
  }, [hostIp]);

  useEffect(() => {
    setSeriesPort([]);
    setPieVisiblePORT(false);
    setPieVisibleDiskInfo(false);
    setPieVisibleMemoryInfo(false);
    // setPieVisibleNetworkInfoInBytes(false);
    // setPieVisibleNetworkInfoInPackets(false);
    if (agentPortdataMod) {
      setSeriesPort(getPorts(agentPortdataMod));
      setPieVisiblePORT(true);
    }
    if (allData?.diskInfo) {
      setPieVisibleDiskInfo(true);
    }
    if (allData?.memoryInfo) {
      setPieVisibleMemoryInfo(true);
    }
    if (allData?.networkInfo[0]) {
      setPieVisibleNetworkInfoInBytes(true);
      setPieVisibleDiskInfo(true);
    }
  }, [
    pieVisibleNetworkInfoInPackets,
    pieVisibleNetworkInfoInBytes,
    agentMetadata,
    agentPortdataMod,
    agentProcessModified,
    pieVisibleDiskInfo,
    pieVisibleMemoryInfo,
    allData,
  ]);

  useEffect(() => {
    function onConnect(data) {}

    function onDisconnect(data) {}
    socket.on("connect", onConnect());
    socket.on("disconnect", onDisconnect());
    socket.on("agent_added", doGetRequest());
    socket.on("agent_action", (data) => {
      // doGetRequest();
      instructionValidate(data);
    });

    return () => {
      socket.off("connect", onConnect());
      socket.off("disconnect", onDisconnect());
      socket.off("agent_added", doGetRequest());
      socket.off("agent_action", (data) => {
        // doGetRequest();
        instructionValidate(data);
      });
    };
  }, []);

  // function for getting process chat data
  const getState = (processData) => {
    let states = {
      LISTEN: 0,
      ESTABLISHED: 0,
      CLOSE: 0,
      TIME_WAIT: 0,
    };
    processData.map((data) => {
      if (data.State === "LISTEN") {
        states.LISTEN = states.LISTEN + 1;
      } else if (data.State === "ESTABLISHED") {
        states.ESTABLISHED = states.ESTABLISHED + 1;
      } else if (data.State === "CLOSE") {
        states.CLOSE = states.CLOSE + 1;
      } else if (data.State === "TIME_WAIT") {
        states.TIME_WAIT = states.TIME_WAIT + 1;
      }
    });
    return Object.values(states);
  };
  const getPorts = (portsData) => {
    let portsState = {
      OPEN: 0,
      CLOSE: 0,
      FILTERED: 0,
    };
    portsData.map((port) => {
      if (port.State === "open") {
        portsState.OPEN = portsState.OPEN + 1;
      } else if (port.State === "close") {
        portsState.CLOSE = portsState.CLOSE + 1;
      } else if (port.State === "filtered") {
        portsState.FILTERED = portsState.FILTERED + 1;
      }
    });
    return Object.values(portsState);
  };

  let pieOptionsProcessNetworkInfoInPackets = {
    chart: {
      width: 300,
      type: "donut",
      foreColor: "#dddddd",
    },
    labels: ["Received", "Rev", "DroRev", "Drop Out"],
    colors: ["#8BF965", "#E83A0F", "#1CDBD5", "#0A0000"],
    fill: {
      colors: ["#8BF965", "#E83A0F", "#1CDBD5", "#0A0000"],
    },
    stroke: { show: false },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 720,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };
  let pieOptionsProcessNetworkInfoInBytes = {
    chart: {
      width: 300,
      type: "donut",
      foreColor: "#dddddd",
    },
    labels: ["Received", "Rev", "DroRev", "Drop Out"],
    colors: ["#8BF965", "#E83A0F", "#1CDBD5", "#0A0000"],
    fill: {
      colors: ["#8BF965", "#E83A0F", "#1CDBD5", "#0A0000"],
    },
    stroke: { show: false },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 720,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1800,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };
  let lineOptionsNetworkInfoPackets = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  let pieOptionsProcessMemoryInfo = {
    chart: {
      width: 300,
      type: "donut",
      foreColor:
        localStorage.getItem("appMode") === "dark" ? "#fffff" : "#000000",
    },
    labels: ["FREE", "USED"],
    colors: ["#8BF965", "#E83A0F"],
    fill: {
      colors: ["#8BF965", "#E83A0F"],
    },
    stroke: { show: false },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 720,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };
  let pieOptionsProcessDiskInfo = {
    chart: {
      width: 300,
      type: "donut",
      foreColor:
        localStorage.getItem("appMode") === "dark" ? "#fffff" : "#000000",
    },
    labels: ["FREE", "USED"],
    colors: ["#8BF965", "#E83A0F"],
    fill: {
      colors: ["#8BF965", "#E83A0F"],
    },
    stroke: { show: false },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 720,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 250,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };
  let pieOptionsPort = {
    chart: {
      width: 300,
      type: "donut",
      foreColor: "#dddddd",
      events: {
        selection: function (chart, e) {
          console.log(new Date(e.xaxis.min));
        },
      },
    },
    labels: ["OPEN", "CLOSE", "FILTERED"],
    colors: ["#8BF965", "#E83A0F", "#1CDBD5"],
    fill: {
      colors: ["#8BF965", "#E83A0F", "#1CDBD5"],
    },
    stroke: { show: false },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };

  let optionsLinePacket = {
    chart: {
      type: "area",
      height: 309,
      foreColor:
        localStorage.getItem("appMode") === "dark" ? "#fffff" : "#000000",
      stacked: false,
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#8BF965", "#E83A0F"],
    fill: {
      type: "solid",
      colors: ["#8BF965", "#E83A0F"],
      opacity: 0.2,
    },
    stroke: { curve: "straight", width: pckRev.length === 1 ? 3 : 1 },
    title: {
      text: "Packet Network Info (MB) ",
      align: "left",
    },
    labels: levels,
    xaxis: {
      type: "datetime",
      categories: levels,
    },
    yaxis: {
      min: 0,
      type: "number",
      opposite: true,
    },
    legend: {
      horizontalAlign: "right",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 720,
        options: {
          chart: {
            height: 255,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            height: 255,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 255,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            height: 255,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1800,
        options: {
          chart: {
            height: 256,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            height: 305,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };

  let optionsLineByte = {
    chart: {
      type: "area",
      height: 309,
      foreColor:
        localStorage.getItem("appMode") === "dark" ? "#fffff" : "#000000",
      stacked: false,
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#8BF965", "#E83A0F"],
    fill: {
      type: "solid",
      colors: ["#8BF965", "#E83A0F"],
      opacity: 0.2,
    },
    stroke: { curve: "straight", width: byteRev.length === 1 ? 3 : 1 },
    title: {
      text: "Byte Network Info (MB) ",
      align: "left",
    },
    labels: levels,
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      min: 0,
      type: "number",
      opposite: true,
    },
    legend: {
      horizontalAlign: "right",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 720,
        options: {
          chart: {
            height: 255,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            height: 255,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 255,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            height: 255,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 1800,
        options: {
          chart: {
            height: 256,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            height: 305,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };

  let ipAddressOptionsPort = {
    chart: {
      width: 300,
      type: "donut",
      foreColor: "#dddddd",
    },
    labels: ["HARMLESS", "MALICIOUS", "SUSPECIOUS", "TIMEOUT", "UNDETECTED"],
    colors: ["#8BF965", "#E83A0F", "#1CDBD5", "#568DEF", "#DB56EE"],
    fill: {
      colors: ["#8BF965", "#E83A0F", "#1CDBD5", "#568DEF", "#DB56EE"],
    },
    stroke: { show: false },
    tooltip: {
      x: { show: false },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            marginBottom: 10,
          },
          legend: {
            position: "bottom",
            verticalAlign: "center",
          },
          labels: {
            position: "bottom",
            verticalAlign: "center",
          },
        },
      },
    ],
  };
  let newStr;

  if (loaderShow) {
    return <Loader />;
  }
  if (loader) {
    return <Loader />;
  }
  return (
    <>
      {agentMetadata && (
        <CardBody>
          <CardHeader className="mb-3 mt-3">
            {/* Agent Meta - {id} */}
            <Row>
              <CardHeader className="d-flex justify-content-end align-items-center">
                <a
                  className="px-2 text-decoration-none "
                  href={`/${JSON.parse(
                    getOrgToken()
                  )}/agentactions/${agentHostIp}`}
                >
                  <button className="btn btn-outline-theme btn-lg d-block fw-500 me-3 mb-3">
                    Agent Action
                  </button>
                </a>
                <div className="h6 text-end mb-3">
                  Updated Date:{" "}
                  {new Date(allData?.updated_at).toLocaleString("en-US", {
                    timeZone: "UTC",
                  })}
                </div>
              </CardHeader>
            </Row>
            <Row>
              <Col md={3}>
                <Card className="text-center">
                  <CardHeader className="h6">Host Name </CardHeader>
                  <CardBody>{allData?.hostInfo?.hostname}</CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="text-center">
                  <CardHeader className="h6">Host IP </CardHeader>
                  <CardBody>{agentHostIp}</CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="text-center">
                  <CardHeader className="h6">Public IP </CardHeader>
                  <CardBody>{publicIp}</CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="text-center">
                  <CardHeader className="h6 d-flex justify-content-evenly">
                    <div>Agent Alias</div>
                    <i
                      onClick={() => setAliasModal(true)}
                      style={{ cursor: "pointer" }}
                      className="bi bi-pen-fill aliasEditButton"
                    ></i>
                  </CardHeader>
                  <CardBody>
                    <div className="text-capitalize">
                      {agentAliesName ? agentAliesName : "-"}
                    </div>
                    <Modal
                      show={aliasModal}
                      onHide={() => setAliasModal(false)}
                    >
                      <Modal.Header>
                        <Modal.Title>Update Agent Alias Name</Modal.Title>
                        <i
                          onClick={() => setAliasModal(false)}
                          className="bi bi-x-circle-fill aliasCancelButton"
                        ></i>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <input
                            className="form-control form-control-lg bg-white bg-opacity-5"
                            onChange={(e) =>
                              setUpdateAgentAlias(e.target.value)
                            }
                            type="text"
                            placeholder="Desire Alias Name"
                          />
                          <button
                            onClick={() => handleChangeAlias(id)}
                            className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-3 mb-3"
                          >
                            <div className="d-flex gap-2 justify-content-center align-item-center">
                              {" "}
                              {aliasLoader ? (
                                <LineWaveLoader />
                              ) : (
                                <span>update</span>
                              )}
                            </div>
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardHeader>

          <Card>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Family</th>
                    <th scope="col">Model</th>
                    <th scope="col">Stepping</th>
                    <th scope="col">Physical Id</th>
                    <th scope="col">Core Id</th>
                    <th scope="col">Cores</th>
                    <th scope="col">Model Name</th>
                    <th scope="col">MHZ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="noBorder">{agentMetadata.vendorId}</td>
                    <td class="noBorder">{agentMetadata.family}</td>
                    <td class="noBorder">{agentMetadata.model}</td>
                    <td class="noBorder">{agentMetadata.stepping}</td>
                    <td class="noBorder">
                      {agentMetadata.physicalId !== ""
                        ? agentMetadata.physicalId
                        : "-"}
                    </td>
                    <td class="noBorder">{agentMetadata.coreId}</td>
                    <td class="noBorder">{agentMetadata.cores}</td>
                    <td class="noBorder">{agentMetadata.modelName}</td>
                    <td class="noBorder">{agentMetadata.mhz}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </CardBody>
      )}
      {agentMetadata && (
        <CardBody>
          <CardHeader className="mb-3 mt-3">
            <Row>
              <Col md={4}>
                <Card style={{ height: "100px" }} className="text-center">
                  <CardHeader className="h6">
                    Malicious IPs - {maliciousData?.total_malicious_ips}
                  </CardHeader>
                  {maliciousData?.malicious_ips?.length > 0 ? (
                    <CardBody className="overflow-scroll">
                      {maliciousData?.malicious_ips?.map((mips) => {
                        return (
                          <h6
                            className="maliciousIp"
                            onClick={() => fncShowWorldMapModelMalicious(mips)}
                          >
                            {mips}
                          </h6>
                        );
                      })}
                    </CardBody>
                  ) : (
                    <CardBody style={{ minHeight: "85px", overflow: "auto" }}>
                      <h6>No Ips Found</h6>
                    </CardBody>
                  )}
                </Card>
              </Col>
              <Col md={4}>
                <Card style={{ height: "100px" }} className="text-center">
                  <CardHeader className="h6">
                    Suspicious IPs - {maliciousData?.total_suspicious_ips}
                  </CardHeader>
                  {maliciousData?.suspicious_ips?.length > 0 ? (
                    <CardBody className="overflow-scroll">
                      {maliciousData?.suspicious_ips?.map((sips) => {
                        return (
                          <h6
                            className="maliciousIp"
                            onClick={() => fncShowWorldMapModelMalicious(sips)}
                          >
                            {sips}
                          </h6>
                        );
                      })}
                    </CardBody>
                  ) : (
                    <CardBody style={{ minHeight: "85px", overflow: "auto" }}>
                      <h6>No Ips Found</h6>
                    </CardBody>
                  )}
                </Card>
              </Col>
              <Col md={4}>
                <Card style={{ height: "100px" }} className="text-center">
                  <CardHeader className="h6">
                    Whitelisted IPs -{" "}
                    {maliciousData?.total_whitelisted_ips_abuseipdb}
                  </CardHeader>
                  {maliciousData?.whitelisted_ips?.length > 0 ? (
                    <CardBody className="overflow-scroll">
                      {maliciousData?.whitelisted_ips?.map((wips) => {
                        return (
                          <h6
                            className="maliciousIp"
                            onClick={() => fncShowWorldMapModelMalicious(wips)}
                          >
                            {wips}
                          </h6>
                        );
                      })}
                    </CardBody>
                  ) : (
                    <CardBody style={{ minHeight: "85px", overflow: "auto" }}>
                      <h6>No Ips Found</h6>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </CardHeader>
        </CardBody>
      )}
      <Card className="mt-5">
        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="row">
              <CardBody className="mb-3">
                <div className="row mb-3">
                  <div className="col-xl-6 col-lg-6">
                    <CardHeader className="mb-3">Disk Info</CardHeader>
                    <div className="row mb-3 text-justify text-center">
                      <span>
                        Free Space :{" "}
                        {(allData?.diskInfo?.free / 1073741824).toFixed(2)} GB
                      </span>
                      <br />
                      <span>
                        Used Space :{" "}
                        {(allData?.diskInfo?.used / 1073741824).toFixed(2)} GB
                      </span>
                      <br />
                      <span>
                        Total Space :{" "}
                        {(allData?.diskInfo?.total / 1073741824).toFixed(2)} GB
                      </span>
                    </div>
                    <div className="row">
                      {pieVisibleDiskInfo ? (
                        <>
                          <Chart
                            type="donut"
                            options={pieOptionsProcessDiskInfo}
                            series={[
                              allData?.diskInfo?.free,
                              allData?.diskInfo?.used,
                            ]}
                          />
                        </>
                      ) : (
                        <> No data avilable </>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <CardHeader className="mb-3">Memory Info</CardHeader>
                    <div className="row mb-3 text-justify text-center">
                      <span>
                        Free Space :{" "}
                        {(allData?.memoryInfo?.free / 1073741824).toFixed(2)} GB
                      </span>
                      <br />
                      <span>
                        Used Space :{" "}
                        {(allData?.memoryInfo?.used / 1073741824).toFixed(2)} GB
                      </span>
                      <br />
                      <span>
                        Total Space :{" "}
                        {(allData?.memoryInfo?.total / 1073741824).toFixed(2)}{" "}
                        GB
                      </span>
                    </div>
                    <div className="row">
                      {pieVisibleMemoryInfo ? (
                        <>
                          <Chart
                            type="donut"
                            options={pieOptionsProcessMemoryInfo}
                            series={[
                              allData?.memoryInfo?.free,
                              allData?.memoryInfo?.used,
                            ]}
                          />
                        </>
                      ) : (
                        <> No data avilable </>
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </div>

          <div className="col-xl-6 col-lg-12">
            <div className="row">
              <CardBody className="mb-3">
                <Tabs
                  defaultActiveKey="table"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  activeKey={key1}
                  onSelect={(k) => setKey1(k)}
                >
                  <Tab
                    style={{
                      zIndex: 0,
                      position: "relative",
                    }}
                    eventKey="pct"
                    title="Packet Data"
                  >
                    <Chart
                      type="area"
                      options={optionsLinePacket}
                      series={[
                        {
                          name: "packet Rev",
                          data: pckRev,
                        },
                        {
                          name: "packet Send",
                          data: pckSent,
                        },
                      ]}
                    />
                  </Tab>
                  <Tab
                    style={{
                      zIndex: 0,
                      position: "relative",
                    }}
                    eventKey="byt"
                    title="Byte Data"
                  >
                    <Chart
                      type="area"
                      options={optionsLineByte}
                      series={[
                        {
                          name: "Byte Rev",
                          data: byteRev,
                        },
                        {
                          name: "Byte Send",
                          data: byteSent,
                        },
                      ]}
                    />
                  </Tab>
                </Tabs>
              </CardBody>
            </div>
          </div>
        </div>
      </Card>
      <div className="row mt-3 mb-3">
        <div className="col-xl-12 col-lg-12">
          <Card className="mt-auto mb-auto">
            <CardHeader className="mb-3 mt-3 d-flex justify-content-between">
              <div>IP TRACKING MAP</div>
              <div className="d-flex">
                Agent{" "}
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    marginLeft: "5px",
                    marginRight: "30px",
                    background: "yellow",
                    marginTop: "auto",
                    marginBottom: "auto",
                    borderRadius: "10px",
                  }}
                ></div>{" "}
                Clean
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    marginLeft: "5px",
                    marginRight: "30px",
                    background: "green",
                    marginTop: "auto",
                    marginBottom: "auto",
                    borderRadius: "10px",
                  }}
                ></div>{" "}
                Vulnerable
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    marginLeft: "5px",
                    background: "red",
                    marginTop: "auto",
                    marginBottom: "auto",
                    borderRadius: "10px",
                  }}
                ></div>
              </div>
            </CardHeader>
            <CardBody>
              {mapMarker.length > 0 && (
                <Map
                  markers={mapMarker}
                  markerLines={mapMarkerLines}
                  from="logdetails"
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      <div className=" w-100 mb-3 ">
        <Card>
          <CardHeader>Block Website</CardHeader>
          <CardBody>
            <div className="d-flex justify-content-end align-items-center gap-5">
              <input
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="text"
                placeholder="enter the web address (www.google.com)"
                value={blockWebsite}
                onChange={(e) => setBlockWebsite(e.target.value)}
              ></input>
              <button
                onClick={() => handleBlockWebsite()}
                className="btn btn-outline-theme btn-lg d-block w-25 fw-500 mt-3 mb-3"
              >
                <div className="d-flex gap-2 justify-content-center align-item-center">
                  {" "}
                  {blockLoader ? <LineWaveLoader /> : <span>block</span>}
                </div>
              </button>
            </div>
          </CardBody>
        </Card>
      </div>
      <CardBody className="mb-3">
        <div className="row">
          <div className="col-md-12">
            <Card className="mb-3">
              <CardBody>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">
                    ALERTS : {alertsAgentTotal}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFullViewTable(true);
                      setTableName("alert");
                      setRowData([]);
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                </div>
                <div style={{ maxHeight: "450px", overflow: "scroll" }}>
                  <TableShorting
                    tData={alertAgentData}
                    COLUMNS={ALERTS_COLUMNS_LOGDETAILS}
                    handleShow={fncShowIpOrHash}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-md-12 mt-5">
            <Card>
              <CardHeader className="mb-3 mt-3">
                <div className="row">
                  <div className="col-xl-12 d-flex justify-content-between align-items-center">
                    <div>Agent Port Details</div>
                    <div className="d-flex justify-content-between align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        style={{
                          width: "30px",
                          height: "20px",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          setFullViewTable(true);
                          setTableName("port");
                          setRowData([]);
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                        />
                      </svg>
                      <button
                        onClick={() => refreshAgent("scan", "nmap")}
                        style={{
                          width: "100px",
                        }}
                        className="btn btn-outline-theme btn-sm d-block  me-2"
                      >
                        Refresh
                      </button>

                      <Card
                        style={{ width: "100%" }}
                        className="custom-datepicker agentPortDatePicker"
                      >
                        <DateRangePicker
                          onApply={handleEvent}
                          initialSettings={{
                            startDate: new Date(),
                            endDate: new Date(),
                          }}
                        >
                          <input
                            type="text"
                            className=" datePickerInput form-control"
                          />
                        </DateRangePicker>
                      </Card>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Tabs
                  defaultActiveKey="table"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab
                    style={{
                      zIndex: 0,
                      position: "relative",
                    }}
                    eventKey="table"
                    title="Table"
                  >
                    <div style={{ maxHeight: "390px", overflow: "scroll" }}>
                      <div>
                        {agentPortdataMod && agentPortdataMod.length > 0 ? (
                          <TableShorting
                            tData={agentPortdataMod}
                            COLUMNS={AGENT_PORT_COLOUM}
                          />
                        ) : (
                          <span>Nothing Running In Background</span>
                        )}
                      </div>
                    </div>
                    <div className="me-3">
                      <PaginationPort
                        apiCall={agentPortDetails}
                        dateRange={{
                          start: from_date,
                          end: to_date,
                        }}
                        canNextPage={canNextPagePort}
                        setCanNextPage={setCanNextPagePort}
                        canPreviousPage={canPreviousPagePort}
                        setCanPreviousPage={setCanPreviousPagePort}
                        totalPages={totalPagesPort}
                        currentPage={currentPagePort}
                        limit={pageLimitPort}
                        setLimit={setPageLimitPort}
                      />
                    </div>
                  </Tab>
                  <Tab eventKey="chart" title="Chart">
                    <div>
                      <Card
                        className="mb-3"
                        style={{ height: "390px", overflow: "auto" }}
                      >
                        {pieVisiblePORT ? (
                          <Chart
                            type="donut"
                            options={pieOptionsPort}
                            series={seriesDataPort}
                            style={{
                              height: "386px",
                              width: "450px",
                              marginTop: "60px",
                            }}
                          />
                        ) : (
                          <> No data available </>
                        )}
                      </Card>
                    </div>
                  </Tab>
                </Tabs>
              </CardBody>
            </Card>
          </div>
        </div>
      </CardBody>

      {agentProcessModified && (
        <>
          <div
            style={{
              textAlign: "right",
              margin: "50px 0px 20px 0px",
              zIndex: 10,
            }}
          >
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  minWidth: "100px",
                  backgroundColor: "transparent",
                  border: "1px solid gray",
                }}
              >
                {ddlSelectedOption}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {ddlOptions.map((data, i) => (
                  <Dropdown.Item
                    onClick={fncDDLSelection}
                    active={data.isSelected}
                    id={data.value}
                    key={i}
                  >
                    {data.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="row mt-1">
            <div className="col-xl-12 col-lg-12">
              <Card className="mb-3">
                <CardHeader className="mb-3 d-flex justify-content-between align-items-center">
                  <div>Agent Process Table</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setFullViewTable(true);
                        setTableName("agentProcess");
                        setRowData([]);
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                      />
                    </svg>
                    <button
                      onClick={() => refreshAgent("scan", "netstat")}
                      style={{
                        width: "100px",
                      }}
                      className="btn btn-outline-theme btn-sm d-block  me-2"
                    >
                      Refresh
                    </button>
                  </div>
                </CardHeader>
                <div
                  style={{
                    maxHeight: "450px",
                    overflow: "scroll",
                    padding: "30px",
                  }}
                >
                  <TableShorting
                    tData={agentProcessModified}
                    COLUMNS={PROCESS_INFO_COLUMNS}
                    handleShow={fncShowWorldMapModel}
                  />
                </div>
                <div className="me-3">
                  <Pagination
                    apiCall={getStatusWiseDataRequest}
                    canNextPage={canNextPageAgentProcess}
                    setCanNextPage={setCanNextPageAgentProcess}
                    canPreviousPage={canPreviousPageAgentProcess}
                    setCanPreviousPage={setCanPreviousPageAgentProcess}
                    totalPages={totalPagesAgentProcess}
                    currentPage={currentPageAgentProcess}
                    processState={processState}
                    limit={pageLimitAgentProcess}
                    setLimit={setPageLimitAgentProcess}
                  />
                </div>
              </Card>
            </div>

            <div className="col-xl-12 col-lg-12 mt-5">
              {agentBGProcessdata && (
                <Card className="mb-3">
                  <CardHeader className="mb-3 d-flex justify-content-between align-items-center">
                    <div className="">Background Processes</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setFullViewTable(true);
                        setTableName("bgProcess");
                        setRowData([]);
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                      />
                    </svg>
                  </CardHeader>
                  <div
                    style={{
                      maxHeight: "450px",
                      overflow: "scroll",
                      padding: "30px",
                    }}
                  >
                    {agentBGProcessdata.length > 0 ? (
                      <TableShorting
                        tData={agentBGProcessdata}
                        COLUMNS={BACKGROUND_PROCESS_COLUMNS}
                        handleShow={fncShowHashData}
                      />
                    ) : (
                      <span>Nothing Running In Background</span>
                    )}
                  </div>
                  <div className="me-3">
                    <Pagination
                      apiCall={agentBgProcessDetails}
                      canNextPage={canNextPageBGprocess}
                      setCanNextPage={setCanNextPageBGprocess}
                      canPreviousPage={canPreviousPageBGprocess}
                      setCanPreviousPage={setCanPreviousPageBGprocess}
                      totalPages={totalPagesBGprocess}
                      currentPage={currentPageBGprocess}
                      limit={pageLimitBGprocess}
                      setLimit={setPageLimitBGprocess}
                    />
                  </div>
                </Card>
              )}
            </div>
          </div>
        </>
      )}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        fullscreen={true}
        aria-labelledby="example-custom-modal-styling-title"
        style={{
          zIndex: 1056,
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            IP Tracking ({ipTracking})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <p className="">
                  <b>Autonomous System Label:</b>{" "}
                  {ipAddressData?.details?.autonomous_system_label == null
                    ? "--"
                    : ipAddressData?.details?.autonomous_system_label}
                </p>
                <p className="text-end">
                  <b> Country:</b>{" "}
                  {ipAddressData?.details?.country == null ? (
                    "--"
                  ) : (
                    <>
                      <ReactCountryFlag
                        countryCode={ipAddressData?.details?.country}
                        svg
                      />{" "}
                      {ipAddressData?.details?.country}
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="col-xl-4">
              <Card>
                <Row>
                  <Col md={6}>
                    <div>
                      <div className="mb-3 m-3 h6">Data Safety Ratio</div>
                      <ui>
                        <ul>
                          HARMLESS :{" "}
                          {
                            ipAddressData?.details?.last_analysis_stats
                              ?.harmless
                          }
                        </ul>
                        <ul>
                          MALICIOUS :{" "}
                          {
                            ipAddressData?.details?.last_analysis_stats
                              ?.malicious
                          }
                        </ul>
                        <ul>
                          SUSPECIOUS :{" "}
                          {
                            ipAddressData?.details?.last_analysis_stats
                              ?.suspicious
                          }
                        </ul>
                        <ul>
                          TIMEOUT :{" "}
                          {ipAddressData?.details?.last_analysis_stats?.timeout}
                        </ul>
                        <ul>
                          UNDETECTED :{" "}
                          {
                            ipAddressData?.details?.last_analysis_stats
                              ?.undetected
                          }
                        </ul>
                      </ui>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3 m-3 h6">Is Clean</div>
                    <div className="">
                      {ipAddressData?.details?.is_clean == true ? (
                        <>
                          <div className="text-center ">
                            <img
                              alt=""
                              width="150px"
                              height="100px"
                              className=""
                              src="/assets/img/RightTick.webp"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-center ">
                            <img
                              alt=""
                              width="150px"
                              height="100px"
                              className=""
                              src="/assets/img/WrongTick.png"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>

            <div className="col-xl-3">
              {ipAddresPie ? (
                <>
                  <Card>
                    <Chart
                      type="donut"
                      options={ipAddressOptionsPort}
                      series={ipAddressDataPort}
                      style={{
                        height: "195px",
                        width: "310px",
                        marginTop: "40px",
                      }}
                    />
                  </Card>
                </>
              ) : (
                <> No data avilable </>
              )}
            </div>

            <div className="col-xl-5">
              <Card>
                <Row style={{ height: "235px" }} className="overflow-scroll">
                  <Col md={12}>
                    <div>
                      <ui>
                        <ul>Organization : {ipAddressData?.ip_info?.org}</ul>
                        <ul>IP : {ipAddressData?.ip_info?.ip}</ul>

                        <ul>Time/Zone : {ipAddressData?.ip_info?.timezone}</ul>
                        <ul>Location : {ipAddressData?.ip_info?.loc}</ul>
                        <ul>City : {ipAddressData?.ip_info?.city}</ul>
                        <ul>Region : {ipAddressData?.ip_info?.region}</ul>
                        <ul>Country : {ipAddressData?.ip_info?.country}</ul>
                        <ul>Postal : {ipAddressData?.ip_info?.postal}</ul>
                      </ui>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>

          <Tabs
            defaultActiveKey="table"
            id="uncontrolled-tab-example"
            className="mb-3 mt-5"
          >
            <Tab eventKey="table" title="Detection">
              <Card className="w-75">
                <div class="table-responsive">
                  {ipAddressData?.detection != undefined && (
                    <TableShorting
                      tData={ipAddressData?.detection}
                      COLUMNS={IP_TRACKING_COLUMNS}
                    />
                  )}
                </div>
              </Card>
            </Tab>
            <Tab eventKey="chart" title="Details">
              <div>
                <div className="row">
                  <div className="col-xk-12">
                    <table>
                      <tr>
                        <td
                          style={{
                            textTransform: "capitalize",
                            width: "200px",
                          }}
                        >
                          Autonomous System Label
                        </td>
                        <td>
                          : {ipAddressData?.details?.autonomous_system_label}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          Autonomous System Number
                        </td>
                        <td>
                          : {ipAddressData?.details?.autonomous_system_number}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          Continent
                        </td>
                        <td>: {ipAddressData?.details?.continent}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>Country</td>
                        <td>: {ipAddressData?.details?.country}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>Network</td>
                        <td>: {ipAddressData?.details?.network}</td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textTransform: "capitalize",
                            verticalAlign: "top",
                          }}
                        >
                          Whois
                        </td>
                        <td>: {ipAddressData?.details?.whois}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          Reginoal Internet Registry
                        </td>
                        <td>
                          : {ipAddressData?.details?.regional_internet_registry}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="abuseData" title="Abuse IP Data">
              <div className="row">
                {ipAddressData?.abuse_ip_data?.data ? (
                  <div>
                    <table>
                      <tr>
                        <th>Name</th>
                        <th>Details</th>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          abuse ConfidenceScore
                        </td>
                        <td>
                          :{" "}
                          {
                            ipAddressData?.abuse_ip_data?.data
                              .abuseConfidenceScore
                          }
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          country Code
                        </td>
                        <td>
                          :{" "}
                          {ipAddressData?.abuse_ip_data?.data.countryCode ==
                          null
                            ? "-"
                            : ipAddressData?.abuse_ip_data?.data.countryCode}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>domain</td>
                        <td>
                          :{" "}
                          {ipAddressData?.abuse_ip_data?.data.domain == null
                            ? "-"
                            : ipAddressData?.abuse_ip_data?.data.domain}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          hostnames
                        </td>
                        <td>
                          :{" "}
                          {ipAddressData?.abuse_ip_data?.data.hostnames.length >
                          0
                            ? ipAddressData?.abuse_ip_data?.data.hostnames.toString()
                            : "-"}
                        </td>
                      </tr>
                      <tr style={{ textTransform: "capitalize" }}>
                        <td>ip Address</td>
                        <td>
                          : {ipAddressData?.abuse_ip_data?.data.ipAddress}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          ip Version
                        </td>
                        <td>
                          : {ipAddressData?.abuse_ip_data?.data.ipVersion}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          is Public
                        </td>
                        <td>
                          :{" "}
                          {ipAddressData?.abuse_ip_data?.data.isPublic === false
                            ? "false"
                            : "true"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>is Tor</td>
                        <td>
                          :{" "}
                          {ipAddressData?.abuse_ip_data?.data.isTor === false
                            ? "false"
                            : "true"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          is White listed
                        </td>
                        <td>
                          :{" "}
                          {ipAddressData?.abuse_ip_data?.data.isWhitelisted ===
                          false
                            ? "false"
                            : "true"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>isp</td>
                        <td>: {ipAddressData?.abuse_ip_data?.data.isp}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          last Reported at
                        </td>
                        <td>
                          : {ipAddressData?.abuse_ip_data?.data.lastReportedAt}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          num distinct users
                        </td>
                        <td>
                          :{" "}
                          {ipAddressData?.abuse_ip_data?.data.numDistinctUsers}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          total Reports
                        </td>
                        <td>
                          : {ipAddressData?.abuse_ip_data?.data.lastReportedAt}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          usage type
                        </td>
                        <td>
                          : {ipAddressData?.abuse_ip_data?.data.usageType}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      {/* ------------------------------------------------------   Hash Modal --------------------------------- */}
      <Modal
        show={showHash}
        onHide={() => setShowHash(false)}
        fullscreen={true}
        aria-labelledby="example-custom-modal-styling-title"
        style={{
          zIndex: 1056,
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Hash Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-xl-6">
              <Card>
                <Row>
                  <Col md={8}>
                    <div>
                      <div className="mb-3 m-3 h6">Data Safety Ratio</div>
                      <ui>
                        <ul>Name : {hashData?.main_data?.name}</ul>
                        <ul>
                          File Extension :{hashData?.main_data?.file_extension}
                        </ul>
                        <ul>Message : {hashData?.main_data?.message}</ul>

                        <ul>
                          Sandbox Verdict :{" "}
                          {hashData?.main_data?.sandbox_verdicts_count}
                        </ul>
                        <ul>Sha256 : {hashData?.main_data?.sha256}</ul>
                        <ul>
                          Threat Category :{" "}
                          {hashData?.main_data?.threat_classification
                            ?.threat_categories.length > 0 &&
                            hashData?.main_data?.threat_classification?.threat_categories?.join(
                              " , "
                            )}
                        </ul>
                        <ul>
                          Threat Label :{" "}
                          {
                            hashData?.main_data?.threat_classification
                              ?.threat_label
                          }
                        </ul>
                        <ul>
                          Threat Names :{" "}
                          {hashData?.main_data?.threat_classification
                            ?.threat_names.length > 0 &&
                            hashData?.main_data?.threat_classification?.threat_names?.join(
                              " , "
                            )}
                        </ul>

                        <ul>
                          Tags :{" "}
                          {hashData?.main_data?.tags?.map((d) => (
                            <span
                              style={{ padding: "10px", margin: "2px" }}
                              className="badge bg-dark"
                            >
                              {d}
                            </span>
                          ))}
                        </ul>
                      </ui>
                    </div>
                  </Col>
                  <Col md={4}>
                    {/* <div className="mb-3 m-3 h6">Is Clean</div> */}
                    <div className="m3">
                      {hashData?.main_data?.is_clean == true ? (
                        <>
                          <div className="text-center ">
                            <img
                              alt=""
                              width="150px"
                              height="100px"
                              className=""
                              src="/assets/img/RightTick.webp"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-center ">
                            <img
                              alt=""
                              width="150px"
                              height="100px"
                              className=""
                              src="/assets/img/WrongTick.png"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>

            <div className="col-xl-6">
              <Card>
                <Row>
                  <Col md={6}>
                    <div style={{ margin: "10px" }}>
                      <h6> Analytics Stats:</h6>
                    </div>
                    <div>
                      <ui>
                        {/* {console.log(hashData?.main_data?.analysis_stats)} */}
                        <ul>
                          Confirmed Timeout :{" "}
                          {
                            hashData?.main_data?.analysis_stats?.[
                              "confirmed-timeout"
                            ]
                          }
                        </ul>
                        <ul>
                          Failure :{" "}
                          {hashData?.main_data?.analysis_stats?.failure}
                        </ul>
                        <ul>
                          Harmless :{" "}
                          {hashData?.main_data?.analysis_stats?.harmless}
                        </ul>
                        <ul>
                          Malicious :{" "}
                          {hashData?.main_data?.analysis_stats?.malicious}
                        </ul>
                        <ul>
                          Suspicious :{" "}
                          {hashData?.main_data?.analysis_stats?.suspicious}
                        </ul>
                        <ul>
                          Timeout :{" "}
                          {hashData?.main_data?.analysis_stats?.timeout}
                        </ul>
                        <ul>
                          Country :{" "}
                          {
                            hashData?.main_data?.analysis_stats?.[
                              "type-unsupported"
                            ]
                          }
                        </ul>
                        <ul>
                          Undetected :{" "}
                          {hashData?.main_data?.analysis_stats?.undetected}
                        </ul>
                      </ui>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ margin: "10px" }}>
                      <h6> Community Score :</h6>
                    </div>
                    <div>
                      <ui>
                        <ul>
                          Malicious Count :{" "}
                          {
                            hashData?.main_data?.community_score
                              ?.malicious_count
                          }
                        </ul>
                        <ul>
                          Total Count :{" "}
                          {hashData?.main_data?.community_score?.total_count}
                        </ul>
                      </ui>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>

          <Tabs
            defaultActiveKey="table"
            id="uncontrolled-tab-example"
            className="mb-3 mt-5"
          >
            <Tab eventKey="table" title="Detection">
              <Card className="w-75">
                <div class="table-responsive">
                  {hashData?.detection != undefined && (
                    <TableShorting
                      tData={hashData?.detection}
                      COLUMNS={HASH_DETECTION_COLUMNS}
                    />
                  )}
                </div>
              </Card>
            </Tab>
            <Tab eventKey="chart" title="Details">
              <div>
                <div className="row">
                  <div className="col-xk-12">
                    <table>
                      <tr>
                        <td
                          style={{
                            textTransform: "capitalize",
                            width: "200px",
                          }}
                        >
                          Authentication Hash
                        </td>
                        <td>: {hashData?.details?.authentihash}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          File Extension
                        </td>
                        <td>: {hashData?.details?.file_extension}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          File Size
                        </td>
                        <td>: {hashData?.details?.file_size}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>magic</td>
                        <td>: {hashData?.details?.magic}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>md5</td>
                        <td>: {hashData?.details?.md5}</td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textTransform: "capitalize",
                            verticalAlign: "top",
                          }}
                        >
                          Meaningful Name
                        </td>
                        <td>: {hashData?.details?.meaningful_name}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>Names</td>
                        <td>: {hashData?.details?.names}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>sha1</td>
                        <td>: {hashData?.details?.sha1}</td>
                      </tr>

                      <tr>
                        <td style={{ textTransform: "capitalize" }}>sha256</td>
                        <td>: {hashData?.details?.sha256}</td>
                      </tr>

                      <tr>
                        <td style={{ textTransform: "capitalize" }}>ssdeep</td>
                        <td>: {hashData?.details?.ssdeep}</td>
                      </tr>

                      <tr>
                        <td style={{ textTransform: "capitalize" }}>tags</td>
                        <td>: {hashData?.details?.tags}</td>
                      </tr>

                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          Type Description
                        </td>
                        <td>: {hashData?.details?.type_description}</td>
                      </tr>

                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          Type Tag
                        </td>
                        <td>: {hashData?.details?.type_tag}</td>
                      </tr>

                      <tr>
                        <td style={{ textTransform: "capitalize" }}>
                          Type Tags
                        </td>
                        <td>: {hashData?.details?.type_tags}</td>
                      </tr>

                      <tr>
                        <td style={{ textTransform: "capitalize" }}>VHash</td>
                        <td>: {hashData?.details?.vhash}</td>
                      </tr>
                      <tr>
                        <td style={{ textTransform: "capitalize" }}>Trid</td>
                        <td>: {hashData?.details?.trid}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="abuseData" title="Sandbox Verdicts">
              <div class="table-responsive">
                {hashData?.detection != undefined && (
                  <TableShorting
                    tData={hashData?.sandbox_verdicts}
                    COLUMNS={SANDBOX_DETECTION_COLUMNS}
                  />
                )}
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      {eventLogs && (
        <>
          <div
            style={{
              textAlign: "right",
              margin: "50px 0px 20px 0px",
              zIndex: 10,
            }}
          >
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  minWidth: "100px",
                  backgroundColor: "transparent",
                  border: "1px solid gray",
                }}
              >
                {ddlEventLogSelectedOption}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {ddlEventLogOptions.map((data, i) => (
                  <Dropdown.Item
                    onClick={fncDDLEventLogSelection}
                    active={data.isSelected}
                    id={data.value}
                    key={i}
                  >
                    {data.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="row mt-3">
            <div className="col-xl-12 col-lg-12">
              <Card className="mb-3">
                <CardHeader className="mb-3 d-flex justify-content-between align-items-center">
                  <div>Event Logs Table</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setFullViewTable(true);
                        setTableName("eventlog");
                        setRowData([]);
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                      />
                    </svg>
                    <button
                      onClick={() => refreshAgent("scan", "system-log")}
                      style={{
                        width: "100px",
                      }}
                      className="btn btn-outline-theme btn-sm d-block  me-2"
                    >
                      Refresh
                    </button>
                  </div>
                </CardHeader>
                <div
                  style={{
                    maxHeight: "450px",
                    overflow: "scroll",
                    padding: "30px",
                  }}
                >
                  {eventLogs?.logs?.length > 0 ? (
                    <TableShorting
                      tData={eventLogs?.logs}
                      COLUMNS={SYS_LOG_COLOUMNS}
                    />
                  ) : (
                    <p>No event logs present</p>
                  )}
                </div>
                <div className="me-3">
                  <PaginationEventLog
                    apiCall={eventLogsApiCall}
                    canNextPage={canNextPageEventlog}
                    setCanNextPage={setCanNextPageEventlog}
                    canPreviousPage={canPreviousPageEventlog}
                    setCanPreviousPage={setCanPreviousPageEventlog}
                    totalPages={totalPagesEventlog}
                    currentPage={currentPageEventlog}
                    processState={eventlogState}
                    limit={pageLimitEventlog}
                    setLimit={setPageLimitEventlog}
                    hostIp={hostIp}
                  />
                </div>
              </Card>
            </div>
          </div>
          {/* table bigger view modal */}
          <Modal
            show={fullViewTable}
            onHide={() => setFullViewTable(false)}
            fullscreen={true}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
            className={localStorage.getItem('appMode') === 'dark' ? 'detailsTableModal' : 'detailsTableModalLight'}
          >
            <Modal.Header className="d-flex justify-content-between align-items-center">
              <span>
                {tableName === "alert" ? (
                  ` ALERTS : ${alertsAgentTotal}`
                ) : tableName === "agentProcess" ? (
                  ` AGENT PROCESS TABLE`
                ) : tableName === "bgProcess" ? (
                  ` BACKGROUND PROCESS`
                ) : tableName === "port" ? (
                  ` AGENT PORT DETAILS`
                ) : tableName === "eventlog" ? (
                  ` EVENT LOG TABLE`
                ) : (
                  <></>
                )}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => setFullViewTable(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                />
              </svg>
            </Modal.Header>
            <ModalBody>
              <div className="row">
                <div className="col-2">
                  <div
                    style={{
                      padding: "8px 12px",
                      color: "gray",
                      margin: "0 0 15px 0",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                    className="d-flex justify-content-between"
                  >
                    <div>Filter options</div>
                    <i
                      onClick={() => handleCancelFilter()}
                      style={{
                        color: "red",
                      }}
                      className="bi bi-x-circle iocAction"
                    ></i>
                  </div>
                  {tableName === "bgProcess" ? (
                    agentAllBGProcessdata &&
                    agentAllBGProcessdata.length > 0 ? (
                      <div className="mt-2">
                        <FilterComponentsBGProcess
                          tableName={tableName}
                          agentAllBgProcessDetails={agentAllBgProcessDetails}
                          agentAllBGProcessdata={agentAllBGProcessdata}
                        />
                      </div>
                    ) : null
                  ) : tableName === "port" ? (
                    agentPortdataModAll && agentPortdataModAll.length > 0 ? (
                      <div className="mt-2">
                        <FilterComponentsAgentPort
                          tableName={tableName}
                          agentPortdataModAll={agentPortdataModAll}
                          agentAllPortDetails={agentAllPortDetails}
                        />
                      </div>
                    ) : null
                  ) : tableName === "agentProcess" ? (
                    agentProcessModifiedAll &&
                    agentProcessModifiedAll.length > 0 ? (
                      <div className="mt-2">
                        <FilterComponentsAgentProcess
                          tableName={tableName}
                          agentPortdataModAll={agentProcessModifiedAll}
                          agentAllPortDetails={getStatusWiseDataRequestAll}
                        />
                      </div>
                    ) : null
                  ) : tableName === "eventlog" ? (
                    agentProcessModifiedAll &&
                    agentProcessModifiedAll.length > 0 ? (
                      <div className="mt-2">
                        <FilterComponentsEventLog
                          tableName={tableName}
                          agentAllPortDetails={eventLogsApiCallAll}
                          agentPortdataModAll={eventLogsAll?.logs}
                          hostIp={hostIp}
                          eventlogState={eventlogState}
                        />
                      </div>
                    ) : null
                  ) : (
                    <>filter option in not available</>
                  )}
                </div>
                <div
                  className={`${
                    rowData.length > 0 ? "col-7 overflow-scroll" : "col-10"
                  }`}
                >
                  {tableName === "alert" ? (
                    alertAgentData && alertAgentData.length > 0 ? (
                      <TableShortingWithoutPagination
                        tData={alertAgentData}
                        COLUMNS={ALERTS_COLUMNS_LOGDETAILS}
                        handleShow={fncShowIpOrHash}
                      />
                    ) : (
                      <div
                        style={{
                          padding: "8px 12px",
                          margin: "0 0 15px 0",
                          color: "gray",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        No data found
                      </div>
                    )
                  ) : tableName === "agentProcess" ? (
                    agentProcessModifiedAll &&
                    agentProcessModifiedAll.length > 0 ? (
                      <TableShortingWithoutPagination
                        tData={agentProcessModifiedAll}
                        COLUMNS={PROCESS_INFO_COLUMNS}
                        handleShow={fncShowWorldMapModel}
                      />
                    ) : (
                      <div
                        style={{
                          padding: "8px 12px",
                          margin: "0 0 15px 0",
                          color: "gray",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        No data found
                      </div>
                    )
                  ) : tableName === "bgProcess" ? (
                    agentAllBGProcessdata &&
                    agentAllBGProcessdata.length > 0 ? (
                      <TableShortingWithoutPagination
                        tData={agentAllBGProcessdata}
                        COLUMNS={BACKGROUND_PROCESS_COLUMNS}
                        handleShow={fncShowHashData}
                      />
                    ) : (
                      <div
                        style={{
                          padding: "8px 12px",
                          margin: "0 0 15px 0",
                          color: "gray",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        No data found
                      </div>
                    )
                  ) : tableName === "port" ? (
                    agentPortdataModAll && agentPortdataModAll.length > 0 ? (
                      <TableShortingWithoutPagination
                        tData={agentPortdataModAll}
                        COLUMNS={AGENT_PORT_COLOUM}
                      />
                    ) : (
                      <div
                        style={{
                          padding: "8px 12px",
                          margin: "0 0 15px 0",
                          color: "gray",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        No data found
                      </div>
                    )
                  ) : tableName === "eventlog" ? (
                    eventLogs &&
                    eventLogs?.logs &&
                    eventLogs?.logs.length > 0 ? (
                      <TableShortingWithoutPagination
                        tData={eventLogsAll?.logs}
                        COLUMNS={SYS_LOG_COLOUMNS}
                      />
                    ) : (
                      <div
                        style={{
                          padding: "8px 12px",
                          margin: "0 0 15px 0",
                          color: "gray",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        No data found
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                {rowData.length > 0 ? (
                  <div className="col-3">
                    <div
                      style={{
                        padding: "8px 12px",
                        margin: "0 0 15px 0",
                        color: "gray",
                        fontSize: "1rem",
                        fontWeight: 500,
                      }}
                    >
                      Details
                    </div>
                    <div
                      style={{
                        position: "sticky",
                        top: "0px",
                      }}
                    >
                      <div
                        style={{
                          height: "90vh",
                          overflow: "scroll",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          className="accordion detailTableDetails"
                          id="accordionPanelsStayOpenExample"
                        >
                          {rowData.length > 0 &&
                            rowData.map((data, idx) => {
                              return data.column.Header !== "View" &&
                                data.column.Header !== "Action" ? (
                                <div
                                  className={`${
                                    localStorage.getItem("appMode") === "dark"
                                      ? "mb-2 text-white accordion-item detailTableDetailsItems"
                                      : "detailTableDetailsItemsLight"
                                  }`}
                                  key={idx}
                                >
                                  <h2
                                    className="accordion-header"
                                    id={`panelsStayOpen-heading${idx}`}
                                  >
                                    <button
                                      className="accordion-button collapsed" // Add "collapsed" class
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#panelsStayOpen-collapse${idx}`}
                                      aria-expanded="false" // Set aria-expanded to false
                                      aria-controls={`panelsStayOpen-collapse${idx}`}
                                    >
                                      {data.column.Header}
                                    </button>
                                  </h2>
                                  <div
                                    id={`panelsStayOpen-collapse${idx}`}
                                    className="accordion-collapse collapse" // Remove "show" class
                                    aria-labelledby={`panelsStayOpen-heading${idx}`}
                                  >
                                    <div className="accordion-body">
                                      {data.value}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
}

export default LogDetails;
