import React, { useEffect, useMemo, useState } from "react";

import { useTable, useGlobalFilter,useSortBy } from "react-table";

import { COLUMNS } from "./Columns";
import { Checkbox } from "./Checkbox";
import { Card } from "../card/card";
import Modal from "react-bootstrap/Modal";
// import { MdOutlineArrowDropDown } from "react-icons/md";
import _ from "lodash" 

const datastyle = {
  padding: "0.938rem 1.5rem",
  "border-radius": "0.75rem",
  " font-weight": 500,
  "font-size": "1rem",
  background: "transparent",
  "border-color": "#f4f4f4",
  border: "1px solid #adb5bd",

  color: "gray",
};

var ddlOptions = [
  {
    name: "all",
    label: "All",
    isSelected: false,
  },
  {
    name: "pending",
    label: "Pending",
    isSelected: false,
  },
  {
    name: "active",
    label: "Active",
    isSelected: false,
  },
  {
    name: "disconnected",
    label: "Disconnected",
    isSelected: false,
  },
  {
    name: "never_connected",
    label: "Never Connected",
    isSelected: false,
  },
];

let obj1;
let obj2;

export const TestTable = ({ tData, agentID, status }) => {
  const columns = useMemo(() => COLUMNS, []);
  const [ddlData, setDdlData] = useState([]);
  const [ddlSelectedOption, setDdlSelectedOption] = useState(status);
  const data = useMemo(() => tData, [tData]);
  const [showDrop, setshowDrop] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [show, setShow] = useState(false);


  function fncNavigate(e) {}

  function ddlChange(e, name) {
    setDdlSelectedOption(name?.label);

    let data = ddlOptions.map((data) => {
      return data.name == name?.name
        ? { ...data, isSelected: true }
        : { ...data, isSelected: false };
    });
    setDdlData(data);
  }

  useEffect(() => {
    let data = ddlOptions.map((data) => {
      return data.name == status
        ? { ...data, isSelected: true }
        : { ...data, isSelected: false };
    });

    setDdlData(data);
  }, [status]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
    state,
    getToggleHideAllColumnsProps,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy

  );
  const [selectedHeader, setSelectedHeader] = useState([]);
  const [selectRows, setSelectRows] = useState([])

    const resetAllTabel =  () => {
      // const collection = document.getElementsByClassName("allSelectionCheckbox");
      var collection = document.querySelectorAll('.allSelectionCheckbox');
      for (var i = 0; i < collection.length; i++) {
        collection[i].checked = true;
       }
      console.log("element",collection);
    }
    useEffect(() => {
      obj1 = _.cloneDeep(headerGroups)
      obj2 = _.cloneDeep(rows)
    //  localStorage.setItem('agentShortHead',headerGroups)
    //  localStorage.setItem('agentShortRow',rows)
      setSelectedHeader(obj1)
      setSelectRows(obj2)
    //   console.log('hello')
    },[tData])

    // console.log('header',headerGroups)
    // console.log("selectheader",selectedHeader)
    // console.log('rows',rows)
    // console.log('selectRows',selectRows)

    
  const { globalFilter } = state;
  return (
    <>
      <Card className="mb-3 ">
        <div className="card-body">
          <div style={{ textAlign: "end" }}>
            <button data-backdrop="static" data-keyboard="false"
            onClick={() => setShow(true)}
            style={datastyle}>
  
              Filter Columns
            </button>
          </div>
        </div>
      </Card>

      <Card className="mb-3">
        <div style={{ overflowX: "auto", padding: "20px 30px" }}>
          <table {...getTableProps()} className="table table-bordered ">
            <thead>
            {/* {headerGroups.map((headerGroup) => ( */}
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}
               
                >
                  {headerGroup.headers.map((column) => (

                    <th {...column.getHeaderProps(
                      column.getSortByToggleProps(), {
                        style: { minWidth: column.minWidth, width: column.width },
                      }
                    )}>
                      {column.render("Header")}
                       <span style={{ marginLeft: "10px", fontSize: 14 }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <i className="fas fa-caret-down"></i>
                            : <i className="fas fa-caret-up"></i>
                          :   <i className="fas fa-sort"></i> }
                      </span>

                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows?.map((row) => {
                prepareRow(row);
                return (
                
                  <tr style={{fontSize:"12px"}} {...row.getRowProps()} onClick={fncNavigate}>
                    {row.cells.map((cell) => {
                      return (
                        <td  {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          }})}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>

      <Modal
        show={show}
        // onHide={() => window.location.href = `/analytics/${agentID}`}
        onHide={() => setShow(false)}
        fullscreen={false}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          Please select and unselect columns of table
          </Modal.Title>
        </Modal.Header> 
        <Modal.Body>
       
            <div style={{ padding: "10px" }}>
              <Card className="mb-3 ">
                <div
                  className="card-body"
                  style={{ maxHeight: "600px", overflow: "scroll" }}
                >
                  <div style={{ float: "right" }}>
                    <Checkbox {...getToggleHideAllColumnsProps()} />{" "}
                    Select/Unselect All
                  </div>
                  {allColumns.map((column) => (
                    <div key={column.id}>
                      <label>
                        <input
                          type="checkbox"
                          className="allSelectionCheckbox"
                          {...column.getToggleHiddenProps()}
                        />{" "}
                        {column.Header}
                      </label>
                    </div>
                  ))}
                  <br />
                </div>
              </Card>
            </div>
            
            <div className="row justify-content-end">
              <div className=" col-xl-1 text-end pe-4 mb-3">
                <button
                  type="button"
                  className="btn btn-theme"
                  style={{
                    backgroundColor:'red',
                    border:'1px solid red'
                  }}
                  // onClick={() => window.location.href = `/analytics/${agentID}`}
                  onClick={() => setShow(false)}
                >
                  Close
                </button>
              </div>
              <div style={{ marginRight:25}} className=" col-xl-1 text-end pe-4 mb-3">
                <button
                    className="btn btn-theme"
                onClick={()=> {
                  setShow(false)
                  setSelectedHeader(headerGroups)
                  setSelectRows(rows)
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
         
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TestTable;
