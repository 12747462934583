import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { AppSettings } from "./../../config/app-settings.js";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { orgAxios } from "../../config/org.axios";
import { LineWaveLoader } from "../loader.js";

const Organization = () => {
  const context = useContext(AppSettings);
  const [orgProfileLoader, setOrgProfileLoader] = useState(false);
  const validationSchema = Yup.object().shape({
    org_name: Yup.string()
      .required("Organization name is required.")
      .min(3, "Organization name must be at least 3 characters."),
    registered_name: Yup.string()
      .required("Registered name is required.")
      .min(3, "Registered name must be at least 3 characters."),
    website: Yup.string()
      .url("Please enter a valid website URL.")
      .required("Website is required."),
    address: Yup.string().required("Address is required."),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    country: Yup.string().required("Country is required."),
    pin_code: Yup.number()
      .integer()
      .typeError("PIN code must be a number.")
      .min(100000, "PIN code must be a 6-digit number.")
      .max(999999, "PIN code must be a 6-digit number.")
      .required("PIN code is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: context.clientProfileData?.org_name || "",
      registered_name: context.clientProfileData?.registered_name || "",
      website: context.clientProfileData?.website || "",
      cover_pic: context.clientProfileData?.cover_pic || "",
      logo: context.clientProfileData?.logo || "",
      address: context.clientProfileData?.address || "",
      city: context.clientProfileData?.city || "",
      state: context.clientProfileData?.state || "",
      country: context.clientProfileData?.country || "",
      pin_code: context.clientProfileData?.pin_code || "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(event) {
    setOrgProfileLoader(true)
    await orgAxios.put(`update/org/`, { data: { ...event } }).then(
      (response) => {
        if (response?.data?.status === 1) {
          context.setClientProfileData(response?.data?.data);
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.message);
        }
        setOrgProfileLoader(false)
      },
      (error) => {
        toast.error(error);
        setOrgProfileLoader(false)
      }
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        <label className="form-label">Organization Name</label>
        <input
          id="org_name"
          name="org_name"
          onChange={formik.handleChange}
          value={formik.values.org_name}
          type="text"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="organization name"
        />
        {formik.touched.org_name && formik.errors.org_name && (
          <div className="text-danger">{formik.errors.org_name}</div>
        )}
      </div>

      <div className="mb-3">
        <label className="form-label">Registered Name</label>
        <input
          id="registered_name"
          name="registered_name"
          onChange={formik.handleChange}
          value={formik.values.registered_name}
          type="text"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="register name"
        />
        {formik.touched.registered_name && formik.errors.registered_name && (
          <div className="text-danger">{formik.errors.registered_name}</div>
        )}
      </div>

      <div className="mb-3">
        <label className="form-label">Website</label>
        <input
          id="website"
          name="website"
          onChange={formik.handleChange}
          value={formik.values.website}
          type="text"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="website"
        />
        {formik.touched.website && formik.errors.website && (
          <div className="text-danger">{formik.errors.website}</div>
        )}
      </div>
      {/* <div className="mb-3">
        <label className="form-label">
          Cover Pic 
        </label>
        <input
          id="cover_pic"
          name="cover_pic"
          onChange={formik.handleChange}
          value={formik.values.cover_pic}
          type="file"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="cover pic"
        />
        {formik.touched.cover_pic && formik.errors.cover_pic && (
          <div className="text-danger">{formik.errors.cover_pic}</div>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">
          Logo 
        </label>
        <input
          id="logo"
          name="logo"
          onChange={formik.handleChange}
          value={formik.values.logo}
          type="file"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="logo"
        />
        {formik.touched.logo && formik.errors.logo && (
          <div className="text-danger">{formik.errors.logo}</div>
        )}
      </div> */}

      <label className="form-label">Address</label>
      <div className="" style={{ margin: "0px 0px 0px 20px" }}>
        <div className="mb-3">
          <label className="form-label">Line 1</label>
          <input
            id="address"
            name="address"
            onChange={formik.handleChange}
            value={formik.values.address}
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="address"
          />
          {formik.touched.address && formik.errors.address && (
            <div className="text-danger">{formik.errors.address}</div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">City</label>
          <input
            id="city"
            name="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="city"
          />
          {formik.touched.city && formik.errors.city && (
            <div className="text-danger">{formik.errors.city}</div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">State</label>
          <input
            id="state"
            name="state"
            onChange={formik.handleChange}
            value={formik.values.state}
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="state"
          />
          {formik.touched.state && formik.errors.state && (
            <div className="text-danger">{formik.errors.state}</div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Country</label>
          <input
            id="country"
            name="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="country"
          />
          {formik.touched.country && formik.errors.country && (
            <div className="text-danger">{formik.errors.country}</div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Pin Code</label>
          <input
            id="pin_code"
            name="pin_code"
            onChange={formik.handleChange}
            value={formik.values.pin_code}
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="pin code"
          />
          {formik.touched.pin_code && formik.errors.pin_code && (
            <div className="text-danger">{formik.errors.pin_code}</div>
          )}
        </div>
      </div>

      <div className="mb-3">
        {orgProfileLoader ? (
          <div className="d-flex justify-content-center btn btn-outline-theme btn-lg d-block w-100">
            <LineWaveLoader />
          </div>
        ) : (
          <button
            type="submit"
            className="btn btn-outline-theme btn-lg d-block w-100"
          >
            Update Organization{" "}
          </button>
        )}
      </div>
    </form>
  );
};

export default Organization;
