import React, { useState, useEffect, useContext } from "react";
import { orgAxios } from "../../config/org.axios";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { formatDate } from "@fullcalendar/core";
import { AppSettings } from "../../config/app-settings";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LineWaveLoader } from "../loader";

const UpDateIoc = ({
  setUploadModal,
  _id,
  customIocDataApiCall,
  searchApiCall,
}) => {
  const { customIocCurrentPage, customIocSearchQuery,customIocLimit } =
    useContext(AppSettings);
  const [currentIocData, setCurrentIocData] = useState({});
  const [updateLoader, setUpdateLoader] = useState(true);

  const getIocById = async (id) => {
    setUpdateLoader(true);
    orgAxios.get(`ioc/${id}`).then(
      (response) => {
        if (response.status === 200) {
          setCurrentIocData(response?.data?.data);
        }
        setUpdateLoader(false);
      },
      (error) => {
        toast.error(error);
        setUpdateLoader(false);
      }
    );
  };

  useEffect(() => {
    getIocById(_id);
  }, []);

  const handleFormUpload = (event) => {
    setUpdateLoader(true);
    orgAxios
      .put(`ioc/${_id}`, { data: event })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Your new ioc data is updated");
          setUploadModal(false);
          if (customIocSearchQuery !== "") {
            searchApiCall(customIocCurrentPage,customIocLimit, customIocSearchQuery);
          } else {
            customIocDataApiCall(customIocCurrentPage,customIocLimit);
          }
        }
        setUpdateLoader(false);
      })
      .catch((error) => {
        toast.error(error);
        setUpdateLoader(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      indicator_type: currentIocData.indicator_type || "",
      data: currentIocData.data || "",
      note: currentIocData.note || "",
      source: currentIocData.source || "",
      associated_malware: currentIocData.associated_malware || "",
      date_discovered: currentIocData.date_discovered || "",
      last_seen: currentIocData.last_seen || "",
      confidence: currentIocData.confidence || "",
      impact: currentIocData.impact || "",
      countermeasures: currentIocData.countermeasures || "",
      tags: currentIocData.tags || "",
    },
    onSubmit: handleFormUpload,
  });

  return (
    <Card style={{ marginBottom: "20px" }}>
      {/* <CardHeader className="d-flex justify-content-between">
        <div>Upload Form IOC</div>
      </CardHeader> */}
      <CardBody>
        <Row className="flex justify-content-center w-100 m-auto">
          <form onSubmit={formik.handleSubmit}>
            <label className="mt-3">Indicator Type</label>
            <input
              id="indicator_type"
              name="indicator_type"
              value={formik.values.indicator_type}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Indicator Type"
            />

            <label className="mt-3">Data</label>
            <input
              id="data"
              name="data"
              value={formik.values.data}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Data"
            />

            <label className="mt-3">Note</label>
            <input
              id="note"
              name="note"
              value={formik.values.note}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Note"
            />

            <label className="mt-3">Source</label>
            <input
              id="source"
              name="source"
              value={formik.values.source}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Source"
            />

            <label className="mt-3">Associate Malware</label>
            <input
              id="associated_malware"
              name="associated_malware"
              value={formik.values.associated_malware}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Associate Malware"
            />
            <label className="mt-3">Date Discover</label>
            <input
              id="date_discovered"
              name="date_discovered"
              value={formik.values.date_discovered}
              onChange={formik.handleChange}
              type="date"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Date Discover"
            />
            <label className="mt-3">Last Seen</label>
            <input
              id="last_seen"
              name="last_seen"
              value={formik.values.last_seen}
              onChange={formik.handleChange}
              type="date"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Last Seen"
            />
            <label className="mt-3">Confidence</label>
            <input
              id="confidence"
              name="confidence"
              value={formik.values.confidence}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Confidence"
            />
            <label className="mt-3">Impact</label>
            <input
              id="impact"
              name="impact"
              value={formik.values.impact}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Impact"
            />
            <label className="mt-3">Counter Measure</label>
            <input
              id="countermeasures"
              name="countermeasures"
              value={formik.values.countermeasures}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-1 mb-3"
              placeholder="Counter Measure"
            />
            <label className="mt-3">Tags</label>
            <input
              id="tags"
              name="tags"
              value={formik.values.tags}
              onChange={formik.handleChange}
              type="text"
              className="form-control form-control-lg d-block w-100 fw-500 mt-3 mb-3"
              placeholder="Tags"
            />
            {updateLoader ? (
              <div className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-3 mb-3">
                <div className="d-flex justify-content-center">
                  <LineWaveLoader />
                </div>
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-3 mb-3"
              >
                update current ioc
              </button>
            )}
          </form>
        </Row>
      </CardBody>
    </Card>
  );
};

export default UpDateIoc;
