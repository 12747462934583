import React, { useState } from "react";

const Pagination = ({
  apiCall,
  canNextPage,
  setCanNextPage,
  canPreviousPage,
  setCanPreviousPage,
  totalPages,
  currentPage,
  processState,
  dateRange,
  limit,
  setLimit,
}) => {
  const gotoPage = (page) => {
    if (page === 1) {
      apiCall(page, limit, processState, dateRange?.start, dateRange?.end);
      setCanPreviousPage(false);
      setCanNextPage(true);
    } else if (page === totalPages) {
      apiCall(page, limit, processState, dateRange?.start, dateRange?.end);
      setCanNextPage(false);
      setCanPreviousPage(true);
    }
  };
  const previousPage = () => {
    apiCall(
      currentPage - 1,
      limit,
      processState,
      dateRange?.start,
      dateRange?.end
    );
    setCanNextPage(true);
  };
  const nextPage = () => {
    apiCall(
      currentPage + 1,
      limit,
      processState,
      dateRange?.start,
      dateRange?.end
    );
    setCanPreviousPage(true);
  };
  return (
    <>
      <div
        className="mt-3 mb-3"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <div className="paginationButton">
          <label className="me-1" htmlFor="dropdown">Select Limit:</label>
          <select
            id="dropdown"
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value)
              apiCall(1, e.target.value, processState, dateRange?.start, dateRange?.end);
            }}
            className="block bg-transparent rounded"
            style={{
              width:'40px'
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
        <button
          className="paginationButton"
          onClick={() => gotoPage(1)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className="paginationButton"
          onClick={() => gotoPage(totalPages)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
        <span>
          Page{" "}
          <strong>
            {currentPage} of {totalPages}
          </strong>{" "}
        </span>
      </div>
    </>
  );
};
const PaginationPort = ({
  apiCall,
  canNextPage,
  setCanNextPage,
  canPreviousPage,
  setCanPreviousPage,
  totalPages,
  currentPage,
  dateRange,
  limit,
  setLimit,
}) => {
  const gotoPage = (page) => {
    if (page === 1) {
      apiCall(page, limit, dateRange?.start, dateRange?.end);
      setCanPreviousPage(false);
      setCanNextPage(true);
    } else if (page === totalPages) {
      apiCall(page, limit, dateRange?.start, dateRange?.end);
      setCanNextPage(false);
      setCanPreviousPage(true);
    }
  };
  const previousPage = () => {
    apiCall(currentPage - 1, limit, dateRange?.start, dateRange?.end);
    setCanNextPage(true);
  };
  const nextPage = () => {
    apiCall(currentPage + 1, limit, dateRange?.start, dateRange?.end);
    setCanPreviousPage(true);
  };
  return (
    <>
      <div
        className="mt-3 mb-3"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <div className="paginationButton">
          <label className="me-2" htmlFor="dropdown">Select limit:</label>
          <select
            id="dropdown"
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value)
              apiCall(1, e.target.value, dateRange?.start, dateRange?.end)
            }}
            className="block bg-transparent rounded"
            style={{
              width:'40px'
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
        <button
          className="paginationButton"
          onClick={() => gotoPage(1)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className="paginationButton"
          onClick={() => gotoPage(totalPages)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
        <span>
          Page{" "}
          <strong>
            {currentPage} of {totalPages}
          </strong>{" "}
        </span>
      </div>
    </>
  );
};
const PaginationEventLog = ({
  apiCall,
  canNextPage,
  setCanNextPage,
  canPreviousPage,
  setCanPreviousPage,
  totalPages,
  currentPage,
  processState,
  limit,
  setLimit,
  hostIp,
}) => {
  const gotoPage = (page) => {
    if (page === 1) {
      apiCall(hostIp,page, limit, processState);
      setCanPreviousPage(false);
      setCanNextPage(true);
    } else if (page === totalPages) {
      apiCall(hostIp,page, limit, processState);
      setCanNextPage(false);
      setCanPreviousPage(true);
    }
  };
  const previousPage = () => {
    apiCall(
      hostIp,
      currentPage - 1,
      limit,
      processState,
    );
    setCanNextPage(true);
  };
  const nextPage = () => {
    apiCall(
      hostIp,
      currentPage + 1,
      limit,
      processState,
    );
    setCanPreviousPage(true);
  };
  return (
    <>
      <div
        className="mt-3 mb-3"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <div className="paginationButton">
          <label className="me-1" htmlFor="dropdown">Select Limit:</label>
          <select
            id="dropdown"
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value)
              apiCall(hostIp,1, e.target.value, processState);
            }}
            className="block bg-transparent rounded"
            style={{
              width:'40px'
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
        <button
          className="paginationButton"
          onClick={() => gotoPage(1)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className="paginationButton"
          onClick={() => gotoPage(totalPages)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
        <span>
          Page{" "}
          <strong>
            {currentPage} of {totalPages}
          </strong>{" "}
        </span>
      </div>
    </>
  );
};

export { Pagination, PaginationPort,PaginationEventLog };
