import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { error } from "jquery";
import { userAxios } from "../../config/user.axios.js";
import { defaultAxios } from "../../config/default.axios.js";
import { orgAxios } from "../../config/org.axios.js";
import {
  storeOrgToken,
  storeAccessToken,
  getAccessToken,
  getOrgToken,
  storeUser,
} from "../../config/global.js";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

function PagesLogin() {
  // const { orgID } = useParams();

  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isUsernameError, setIsUsernameError] = useState("");
  const [isPassowordError, setIsPassowordError] = useState("");
  const [resetPasswordModal, setResetPasswordModal] = useState("none");
  const [loginForm, setLogingForm] = useState(true);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordEmailError, setResetPasswordEmailError] = useState("");
  const [passwordShow, setPasswordShow] = useState("password");

  useEffect(() => {
    if (getAccessToken()) {
      const org_id = JSON.parse(getOrgToken());

      window.location.href = `/${org_id}/dashboard`;
    } else {
      setRedirect(false);
      // <Navigate to={`/${orgID}/pages/login`}/>
    }
  }, [redirect]);
  function checkValidation() {
    if (userName === "") {
      setIsUsernameError("Username is Mandatory");
      return false;
    } else {
      setIsUsernameError("");
    }
    if (password === "") {
      setIsPassowordError("Password is Mandatory");
      return false;
    } else {
      setIsPassowordError("");
    }
    return true;
  }

  const firstAllUnreadNotificationsCall = async () => {
    let res = await orgAxios.get(`get_notifications`);
    toast.success("Welcome to Dashboard");
    setRedirect(true);
    localStorage.setItem("xdr_notification", JSON.stringify(res?.data?.data));
  };

  function checkResetPasswordEmailValidation() {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (resetPasswordEmail == "") {
      setResetPasswordEmailError("Reset Password Email is Mandatory");
      return false;
    } else if (!pattern.test(resetPasswordEmail)) {
      setResetPasswordEmailError("Reset Password Email is Invalid");
      return false;
    } else {
      setResetPasswordEmailError("");
    }
    return true;
  }

  const sendResetPasswordEmail = async () => {
    if (checkResetPasswordEmailValidation()) {
      await defaultAxios
        .post(`user/forgot-password`, { email: resetPasswordEmail })
        .then(
          (response) => {
            console.log("resetting password", response.msg);
            if (response?.status === 1) {
              toast.success(response.msg);
              setLogingForm(true);
            }
          },
          (error) => {
            toast.error(error);
          }
        );
    }
  };

  const signUpWithGoogle = async (loginCred) => {
    await defaultAxios.post(`social_login`, loginCred).then(
      (response) => {
        if (response?.data?.org_id) {
          storeOrgToken(JSON.stringify(response?.data?.org_id));
          storeAccessToken(JSON.stringify(response?.data?.access_token));
          storeUser(JSON.stringify(response?.data));
          firstAllUnreadNotificationsCall();
        } else if (response?.data?.status === 0) {
        }
      },
      (error) => {
        toast.error(error);
        setRedirect(false);
      }
    );
  };
  const googleAuth = async (response) => {
    var userCred = jwt_decode(response.credential);
    const loginCred = {
      access_token: response.credential,
      email: userCred.email,
      social_type: "google",
    };
    signUpWithGoogle(loginCred);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    var isValid = checkValidation();
    if (isValid) {
      const loginCred = {
        email: userName,
        password: password,
      };
      await defaultAxios.post(`client/user/login/`, loginCred).then(
        (response) => {
          if (response?.data?.org_id) {
            storeOrgToken(JSON.stringify(response?.data?.org_id));
            storeAccessToken(JSON.stringify(response?.data?.access_token));
            storeUser(JSON.stringify(response?.data));
            firstAllUnreadNotificationsCall();
          } else if (response?.data?.status === 0) {
          }
        },
        (error) => {
          toast.error(error);
          setRedirect(false);
        }
      );
    }
  }

  if (redirect) {
    const org_id = JSON.parse(getOrgToken());

    window.location.href = `/${org_id}/dashboard`;
  }

  const togglePasswordView = () => {
    if (passwordShow === "text") {
      setPasswordShow("password");
    } else if (passwordShow === "password") {
      setPasswordShow("text");
    }
  };

  return (
    <div className="login">
      <div className="login-content">
        <div className="text-center mb-5">
          <img alt="SHIELD XDR" src="/assets/img/xdrLogoSmall.png" />
        </div>
        {loginForm ? (
          <form onSubmit={handleSubmit}>
            <h1 className="text-center">Sign In</h1>
            <div className="text-inverse text-opacity-50 text-center mb-4">
              For your protection, please verify your identity.
            </div>
            <div className="mb-3">
              <label className="form-label">
                Email Id <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg bg-white bg-opacity-5"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder=""
              />

              <span style={{ color: "red", fontSize: 14 }}>
                {isUsernameError}{" "}
              </span>
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <label className="form-label">
                  Password <span className="text-danger">*</span>
                </label>
                {/* <a href="#/" className="ms-auto text-inverse text-decoration-none text-opacity-50">Forgot password?</a> */}
                {passwordShow === "text" ? (
                  <i
                    onClick={() => togglePasswordView()}
                    className="bi bi-eye passwordEyeText me-2"
                  ></i>
                ) : (
                  <i
                    onClick={() => togglePasswordView()}
                    className="bi bi-eye-slash passwordEyePassword me-2"
                  ></i>
                )}
              </div>
              <input
                type={passwordShow}
                className="form-control form-control-lg bg-white bg-opacity-5"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder=""
              />
              <span style={{ color: "red", fontSize: 14 }}>
                {isPassowordError}{" "}
              </span>
            </div>
            <div className="text-center text-inverse text-opacity-50 mb-3">
              Don't know password?{" "}
              <Link
                onClick={() => {
                  setResetPasswordModal("block");
                  setLogingForm(false);
                }}
              >
                Reset Password
              </Link>
              .
            </div>
            {/* <div className="mb-3">
						<div className="form-check">
							<input className="form-check-input" type="checkbox" id="customCheck1" />
							<label className="form-check-label" htmlFor="customCheck1">Remember me</label>
						</div>
					</div> */}
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
            >
              Sign In
            </button>
            <h6 className="text-center  ">Or</h6>
            <div className="socialLogin text-center text-inverse text-opacity-50 mb-5 p-3 rounded d-flex justify-content-center">
              <GoogleLogin
                onSuccess={googleAuth}
                onError={() =>
                  toast.error("not able to login, Please try again")
                }
              />
            </div>
            <div className="text-center text-inverse text-opacity-50">
              Don't have an account yet?{" "}
              <Link to="/pages/register">Sign up</Link>.
            </div>
          </form>
        ) : (
          <div style={{ display: `${resetPasswordModal}` }} className="mb-3">
            <h1 className="text-center">
              {" "}
              <span
                onClick={() => {
                  setLogingForm(true);
                  setResetPasswordEmail("");
                }}
                style={{ fontSize: "1.5rem" }}
              >
                <Link>
                  <i class="fas fa-lg fa-fw me-2 fa-arrow-left"></i>
                </Link>
              </span>
              Reset Password
            </h1>
            <div className="text-inverse text-opacity-50 text-center mb-4">
              Enter your register email and send reset password link in your
              register email address
            </div>
            <label className="form-label">
              Email Id <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={resetPasswordEmail}
              onChange={(e) => setResetPasswordEmail(e.target.value)}
              placeholder=""
            />

            <span style={{ color: "red", fontSize: 14 }}>
              {resetPasswordEmailError}{" "}
            </span>
            <button
              onClick={() => sendResetPasswordEmail()}
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-3 mb-3"
            >
              Send Reset Password Email
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PagesLogin;
