import React, { useContext, useState } from "react";
import { orgAxios } from "../../config/org.axios";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { AppSettings } from "../../config/app-settings";
import { LineWaveLoader } from "../loader";

const UploadForm = ({ setUploadModal, customIocDataApiCall }) => {
  const { totalPageCustomIoc, customIocData,customIocLimit } = useContext(AppSettings);
  const [indicator_type, setIndicator_type] = useState("");
  const [data, setdata] = useState("");
  const [note, setNote] = useState("");
  const [source, setSource] = useState("");
  const [associated_malware, setAssociated_malware] = useState("");
  const [date_discovered, setDate_discovered] = useState("");
  const [last_seen, setLast_seen] = useState("");
  const [confidence, setConfidence] = useState("");
  const [impact, setImpact] = useState("");
  const [countermeasures, setCountermeasures] = useState("");
  const [tags, setTags] = useState("");
  const [isIndicatorError, setIsIndicatorError] = useState("");
  const [isSouceError, setIsSouceError] = useState("");
  const [isNoteError, setIsNoteError] = useState("");
  const [isDataError, setIsDataError] = useState("");
  const [uploadFormLoader, setUploadFormLoader] = useState(false);

  function checkValidationForm() {
    if (indicator_type === "") {
      setIsIndicatorError("Indicator Type is Mandatory");
      return false;
    } else {
      setIsIndicatorError("");
    }
    if (source === "") {
      setIsSouceError("Source is Mandatory");
      return false;
    } else {
      setIsSouceError("");
    }
    if (note === "") {
      setIsNoteError("Note is Mandatory");
      return false;
    } else {
      setIsNoteError("");
    }
    if (data === "") {
      setIsDataError("Data is Mandatory");
      return false;
    } else {
      setIsDataError("");
    }
    return true;
  }

  const handleFormUpload = () => {
    var isValid = checkValidationForm();
    if (isValid) {
      setUploadFormLoader(true);
      const formData = {
        indicator_type: indicator_type,
        data: data,
        note: note,
        source: source,
        associated_malware: associated_malware,
        date_discovered: date_discovered,
        last_seen: last_seen,
        confidence: confidence,
        impact: impact,
        countermeasures: countermeasures,
        tags: tags,
      };

      orgAxios
        .post("upload-ioc-data", formData)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Your new ioc data is updated");
            setUploadModal(false);
            if (customIocData.length < 50) {
              customIocDataApiCall(totalPageCustomIoc,customIocLimit);
            } else {
              customIocDataApiCall(totalPageCustomIoc + 1,customIocLimit);
            }
          }
          setUploadFormLoader(false);
        })
        .catch((error) => {
          toast.error(error);
          setUploadFormLoader(false);
        });
    }
  };
  return (
    <Card style={{ marginBottom: "20px" }}>
      {/* <CardHeader className="d-flex justify-content-between">
        <div>Upload Form IOC</div>
      </CardHeader> */}
      <CardBody>
        <Row className="flex justify-content-center w-100 m-auto">
          <label className="mt-3">Indicator Type</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Indicator Type"
            onChange={(e) => setIndicator_type(e.target.value)}
          />
          <span
            style={{
              color: "red",
              fontSize: 12,
              margin: "-10px 0px 10px 0px",
            }}
          >
            {isIndicatorError}{" "}
          </span>
          <label className="mt-3">Data</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Data"
            onChange={(e) => setdata(e.target.value)}
          />
          <span
            style={{
              color: "red",
              fontSize: 12,
              margin: "0px 0px 10px 0px",
            }}
          >
            {isDataError}{" "}
          </span>
          <label className="mt-3">Note</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Note"
            onChange={(e) => setNote(e.target.value)}
          />
          {isNoteError} <label className="mt-3">Source</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Source"
            onChange={(e) => setSource(e.target.value)}
          />
          <span
            style={{
              color: "red",
              fontSize: 12,
              margin: "0px 0px 10px 0px",
            }}
          >
            {isSouceError}{" "}
          </span>
          <label className="mt-3">Associate Malware</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Associate Malware"
            onChange={(e) => setAssociated_malware(e.target.value)}
          />
          <label className="mt-3">Date Discover</label>
          <input
            type="date"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Date Discover"
            onChange={(e) => setDate_discovered(e.target.value)}
          />
          <label className="mt-3">Last Seen</label>
          <input
            type="date"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Last Seen"
            onChange={(e) => setLast_seen(e.target.value)}
          />
          <label className="mt-3">Confidence</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Confidence"
            onChange={(e) => setConfidence(e.target.value)}
          />
          <label className="mt-3">Impact</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Impact"
            onChange={(e) => setImpact(e.target.value)}
          />
          <label className="mt-3">Counter Measure</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Counter Measure"
            onChange={(e) => setCountermeasures(e.target.value)}
          />
          <label className="mt-3">Tags</label>
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            placeholder="Tags"
            onChange={(e) => setTags(e.target.value)}
          />
          {uploadFormLoader ? (
            <div className="btn btn-outline-theme btn-lg">
              <div className="d-flex justify-content-center">
                <LineWaveLoader />
              </div>
            </div>
          ) : (
            <button
              onClick={() => handleFormUpload()}
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-3 mb-3"
            >
              Upload Ioc Data
            </button>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default UploadForm;
