import React, { useEffect, useState } from "react";
import IocTable from "../table/IocTable";
import { IOC_COLUMNS, CUSTOM_IOC_COLUMNS } from "../table/Columns";
import { orgAxios } from "../../config/org.axios";
import { debounce } from "lodash";

const AllIoc = ({ setGlobalIocCount }) => {
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageAllIoc, setTotalPageAll] = useState(0);
  const [allIocData, setAllIocData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [allIocLimit,setAllIocLimit] = useState(50); 

  const allIocDataApiCall = async (page,limit) => {
    await orgAxios.get(`get-iocs?page=${page}&limit=${limit}`).then((response) => {
      if (response.status === 200) {
        setAllIocData(response?.data?.data);
        setTotalPageAll(response?.data?.total);
        setGlobalIocCount(response?.data?.total_iocs);
        if(page === 1){
          setCanNextPage(true);
          setCanPreviousPage(false);
        }
        if (response?.data?.next_page !== -1) {
          setCurrentPage(response?.data?.next_page - 1);
        } else {
          if (response?.data?.total === 1) {
            setCanNextPage(false);
            setCanPreviousPage(false);
          } else {
            setCanNextPage(false);
            setCanPreviousPage(true);
            setCurrentPage(response?.data?.total)
          }
        }
      }
    });
  };

  const searchApiCall = async (page, query,limit) => {
    await orgAxios
      .get(`search-iocs?type=global&query=${query}&page=${page}&limit=${limit}`)
      .then((response) => {
        if (response.status === 200) {
          setAllIocData(response?.data?.data);
          setTotalPageAll(response?.data?.total);
          if(page === 1){
            setCanNextPage(true);
            setCanPreviousPage(false);
          }
          if (response?.data?.next_page !== -1) {
            setCurrentPage(response?.data?.next_page - 1);
          } else {
            if (response?.data?.total === 1) {
              setCanNextPage(false);
              setCanPreviousPage(false);
            } else {
              setCanNextPage(false);
              setCanPreviousPage(true);
              setCurrentPage(response?.data?.total)
            }
          }
        }
      });
  };

  const handleSearch = debounce((e) => {
    setSearchQuery(e.target.value);
  }, 300);

  useEffect(() => {
    if (searchQuery !== "") {
      searchApiCall(currentPage, searchQuery,allIocLimit);
    } else {
      allIocDataApiCall(currentPage,allIocLimit);
    }
  }, [searchQuery]);

  //all functions for pagination
  const gotoPage = (page) => {
    if (searchQuery !== "") {
      if (page === 1) {
        searchApiCall(page, searchQuery,allIocLimit);
        setCanPreviousPage(false);
        setCanNextPage(true);
      } else if (page === totalPageAllIoc) {
        searchApiCall(page, searchQuery,allIocLimit);
        setCanNextPage(false);
        setCanPreviousPage(true);
      }
    } else {
      if (page === 1) {
        allIocDataApiCall(page,allIocLimit);
        setCanPreviousPage(false);
        setCanNextPage(true);
      } else if (page === totalPageAllIoc) {
        allIocDataApiCall(page,allIocLimit);
        setCanNextPage(false);
        setCanPreviousPage(true);
      }
    }
  };
  const previousPage = () => {
    if (searchQuery !== "") {
      searchApiCall(currentPage - 1, searchQuery,allIocLimit);
    } else {
      allIocDataApiCall(currentPage - 1,allIocLimit);
    }
    setCanNextPage(true);
  };
  const nextPage = () => {
    if (searchQuery !== "") {
      searchApiCall(currentPage + 1, searchQuery,allIocLimit);
    } else {
      allIocDataApiCall(currentPage + 1,allIocLimit);
    }
    setCanPreviousPage(true);
  };
  return (
    <>
      <div className="w-25">
        <form className="mb-3 mt-1">
          {/* <label className="form-label me-3 fs-3 fw-bold">Search</label> */}
          <input
            type="text"
            className="form-control form-control-lg bg-white bg-opacity-5"
            placeholder="Search IOC Data"
            onChange={handleSearch}
          ></input>
        </form>
      </div>
      {allIocData.length > 0 ? (
        <>
          <div style={{ maxHeight: "450px", overflow: "scroll" }}>
            <IocTable tData={allIocData} COLUMNS={IOC_COLUMNS} />
          </div>
          <div
            className="mt-3 mb-3"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div className="paginationButton">
          <label className="me-1" htmlFor="dropdown">Select Limit:</label>
          <select
            id="dropdown"
            value={allIocLimit}
            onChange={(e) => {
              setAllIocLimit(e.target.value)
              allIocDataApiCall(1, e.target.value);
            }}
            className="block bg-transparent rounded"
            style={{
              width:'40px'
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
            <button
              className="paginationButton"
              onClick={() => gotoPage(1)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="paginationButton"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </button>
            <button
              className="paginationButton"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </button>
            <button
              className="paginationButton"
              onClick={() => gotoPage(totalPageAllIoc)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
            <span>
              Page{" "}
              <strong>
                {currentPage} of {totalPageAllIoc}
              </strong>{" "}
            </span>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center">No data found</div>
      )}
    </>
  );
};

export default AllIoc;
