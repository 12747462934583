import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";

const TableShorting = ({ tData, COLUMNS, handleShow }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => tData, [tData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="table table-bordered">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                column.Header !== "View" ? (
                  <th className="align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex align-items-center">
                      <div>{column.render("Header")}</div>

                      <div style={{ marginLeft: "10px", fontSize: 14 }}>
                        {!column?.disableSortBy ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fas fa-caret-down"></i>
                            ) : (
                              <i className="fas fa-caret-up"></i>
                            )
                          ) : (
                            <i className="fas fa-sort"></i>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </th>
                ) : (
                  <></>
                )
              )}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr style={{ fontSize: "12px" }} {...row.getRowProps()}>
                {row.cells.map((cell) =>
                  cell.column.Header !== "View" ? (
                    <td {...cell.getCellProps()}>
                      {cell.column.Header === "Remote Address IP" ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleShow(cell.value);
                          }}
                        >
                          {cell.render("Cell")}
                        </span>
                      ) : cell.column.Header === "Value of Hash/IP" ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleShow(cell);
                          }}
                        >
                          {cell.render("Cell")}
                        </span>
                      ) : cell.column.Header === "Hash" ? (
                        <span
                          className="customLink"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleShow(cell.value);
                          }}
                        >
                          {cell.render("Cell")}
                        </span>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  ) : (
                    <></>
                  )
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* <div style={{ display: "flex", justifyContent: "end" }}>
        <button
          className="paginationButton"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className="paginationButton"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div> */}
    </>
  );
};

export default TableShorting;
