import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { orgAxios } from "../../config/org.axios";
import { defaultAxios } from "../../config/default.axios";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import { Theme } from "@fullcalendar/core/internal";
import { Row } from "react-bootstrap";
import { error } from "jquery";
import { getAccessToken, getOrgToken } from "../../config/global";

const VerifyEmail = () => {
  const { orgID, emailVerificationToken } = useParams();
  const [emailVerifyed, setEmailVerified] = useState("");

  function handleBackBtnVerify() {
    if (JSON.parse(getOrgToken())) {
      window.location.href = `/${JSON.parse(getOrgToken())}/dashboard`;
    } else {
      window.location.href = `/login`;
    }
  }

  function handleBackBtnResend() {
    if (JSON.parse(getOrgToken())) {
      window.location.href = `/${JSON.parse(getOrgToken())}/dashboard`;
    } else {
      window.location.href = `/login`;
    }
  }
  useEffect(() => {
    emailVerify();
  }, []);

  const emailVerify = () => {
    defaultAxios.put(`user/verify-email/${emailVerificationToken}`).then(
      (response) => {
        if (response.status === 1) {
          setEmailVerified(true);
        }
      },
      (error) => {
        setEmailVerified(false);
      }
    );
  };

  console.log("skfbakfakjsfhk", emailVerifyed);
  return emailVerifyed === true ? (
    <VerifyEmailSuccess handleBackBtnVerify={handleBackBtnVerify} />
  ) : emailVerifyed === false ? (
    <VerifyEmailfailure handleBackBtnResend={handleBackBtnResend} />
  ) : (
    <div></div>
  );
};

const VerifyEmailSuccess = ({ handleBackBtnVerify }) => {
  return (
    <div className="login">
      <div className="d-flex justify-content-center align-items-center my-5 mx-auto">
        <Row className="w-50 text-center">
          <h1 style={{ margin: "20px 20px 20px 0px" }} className="">
            You Email is Verified
          </h1>
          <img
            className="verify-email mb-5"
            src="/assets/img/check.png"
            alt="verify"
          />
          <button
            onClick={handleBackBtnVerify}
            className="btn btn-outline-theme px-3 mb-5 rounded-pill"
          >
            <i className="fa fa-arrow-right me-1 ms-n1"></i> Continue
          </button>
        </Row>
      </div>
    </div>
  );
};

const VerifyEmailfailure = ({ handleBackBtnResend }) => {
  return (
    <div className="login">
      <div className="d-flex justify-content-center align-items-center my-5 mx-auto">
        <Row className="w-50 text-center">
          <h1 style={{ margin: "20px 20px 20px 0px" }} className="">
            The confirmation link is invalid or has expired
          </h1>
          <img
            className="verify-email mb-5"
            src="/assets/img/close.png"
            alt="verify"
          />
          <button
            onClick={handleBackBtnResend}
            className="btn btn-outline-theme px-3 rounded-pill"
          >
            <i className="fa fa-arrow-left me-1 ms-n1"></i> Go Back
          </button>
        </Row>
      </div>
    </div>
  );
};
export default VerifyEmail;
