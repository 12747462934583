import React from "react";
import { Bars,ThreeDots } from "react-loader-spinner";

function Loader() {
  return (
    <div className="loader">
      <Bars
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}

function LineWaveLoader({color}) {
  return (
    <ThreeDots 
    height="20" 
    width="20" 
    radius="9"
    color = {color ? color : "#4fa94d" }
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={true}
     />
  );
}

export { Loader, LineWaveLoader };
