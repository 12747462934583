import React, { useContext, useEffect } from "react";
import axios from "axios";
import { orgAxios } from "../../config/org.axios";
import { toast } from "react-toastify";
import { socket } from "../../socket/SocketConnection";
import { AppSettings } from "../../config/app-settings";

function Notification() {
  const context = useContext(AppSettings);

  const getNotificationsData = async (isNotificationShown, data) => {
    let res = await orgAxios.get(`get_notifications`);
    if (res.data.data.notifications[0].data) {
      context.setNotification(res?.data?.data);
      if (isNotificationShown === "no") {
      } else {
        if (res?.data?.data?.notifications[0]?.data?.type === "activate") {
          console.log("ActivateAgent");
          toast.info(res?.data?.data?.notifications[0]?.data?.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (
          res?.data?.data?.notifications[0]?.data?.type === "deactivate"
        ) {
          console.log("DeactivateAgent");
          toast.warn(res?.data?.data?.notifications[0]?.data?.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    }
  };

  // useEffect(() => {
  //     getNotificationsData("1")
  // },[])

  useEffect(() => {
    getNotificationsData("no");
    function onConnect(data) {
      // console.log("socketData", data);
    }

    function onDisconnect(data) {}
    socket.on("connect", (data) => {
      onConnect(data);
    });
    socket.on("disconnect", () => {
      onDisconnect();
    });
    socket.on("deactivate_agent", (data) => {
      getNotificationsData("yes", data);
    });
    socket.on("activate_agent", (data) => {
      getNotificationsData("yes", data);
    });

    return () => {
      socket.off("connect", () => {
        onConnect();
      });
      socket.off("disconnect", () => {
        onDisconnect();
      });
      socket.off("activate_agent", (data) => {
        getNotificationsData("yes", data);
      });
      socket.off("deactivate_agent", (data) => {
        getNotificationsData("yes", data);
      });
    };
  }, []);

  return <></>;
}

export default Notification;
