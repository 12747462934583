import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { defaultAxios } from "../../config/default.axios.js";
import * as Yup from "yup";

function PagesRegister() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [org_id, setOrgID] = useState(null);
  const [passwordShow, setPasswordShow] = useState("password");

  const validationSchema = Yup.object().shape({
    // org_name: Yup.string().required('Organization name is required.'),
    // registered_name: Yup.string().required('Registered name is required.'),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    // address: Yup.string().required('Address is required.'),
    // city: Yup.string().required('City is required.'),
    // state: Yup.string().required('State is required.'),
    // country: Yup.string().required('Country is required.'),
    // pin_code: Yup.number().required('PIN code is required.').positive('Please enter a valid PIN code.'),
    // website: Yup.string().url('Please enter a valid website URL.').required('Website is required.'),
    // first_name: Yup.string().required('First name is required.'),
    // last_name: Yup.string().required('Last name is required.'),
    full_name: Yup.string().required("Full name is required."),

    password: Yup.string().required("Password is required."),
    // country_code: Yup.string().required('Country code is required.'),
    // phone_number: Yup.string().required('Phone number is required.')
    //   .matches(/^\d{10}$/, 'Please enter a valid phone number.'),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with New Password"
      ),
  });

  // const formik = useFormik({
  //   initialValues: {
  //     org_name: '',
  //     registered_name: '',
  //     email: '',
  //     address: '',
  //     city: '',
  //     state: '',
  //     country: '',
  //     pin_code: '',
  //     website: '',
  //     first_name: '',
  //     last_name: '',
  //     password: '',
  //     country_code: '+91',
  //     phone_number: '',
  //     confirm_password:""
  //   },
  //   validationSchema,
  //   onSubmit: handleSubmit
  // });
  const formik = useFormik({
    initialValues: {
      email: "",
      full_name: "",
      password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
  }, []);

  async function handleSubmit(event) {
    console.log("event", event);
    await defaultAxios.post(`client/signup/`, { ...event }).then(
      (response) => {
        if (response?.org_id) {
          setOrgID(response?.org_id);
          toast.success(response?.message);
          setRedirect(true);
        } else if (response?.data?.status === 0) {
          toast.error(response?.message);
        }
      },
      (error) => {
        toast.error(error);
        setRedirect(false);
      }
    );
  }

  if (redirect) {
    return <Navigate to={`/pages/login`} />;
  }
  const togglePasswordView = () => {
    if (passwordShow === "text") {
      setPasswordShow("password");
    } else if (passwordShow === "password") {
      setPasswordShow("text");
    }
  };
  return (
    <div className="register">
      <div className="register-content">
        <form onSubmit={formik.handleSubmit}>
          <h1 className="text-center">
            <Link to="/login">
              <span style={{ fontSize: "1.5rem" }}>
                <i class="fas fa-lg fa-fw me-2 fa-arrow-left"></i>
              </span>
            </Link>{" "}
            Sign Up
          </h1>
          <p className="text-inverse text-opacity-50 text-center">
            One Admin ID is all you need to access all the Admin services.
          </p>
          {/* <div className="mb-3">
                        <label className="form-label">First Name <span className="text-danger">*</span></label>
                        <input 
                         id="first_name" name="first_name" onChange={formik.handleChange} value={formik.values.first_name}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="first name" />
                          {formik.touched.first_name && formik.errors.first_name && <div className='text-danger'>{formik.errors.first_name}</div>}
                    </div> */}
          <div className="mb-3">
            <label className="form-label">
              Full Name <span className="text-danger">*</span>
            </label>
            <input
              id="full_name"
              name="full_name"
              onChange={formik.handleChange}
              value={formik.values.full_name}
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="full name"
            />
            {formik.touched.full_name && formik.errors.full_name && (
              <div className="text-danger">{formik.errors.full_name}</div>
            )}
          </div>
          {/* <div className="mb-3">
                        <label className="form-label">Last Name <span className="text-danger">*</span></label>
                        <input 
                         id="last_name" name="last_name" onChange={formik.handleChange} value={formik.values.last_name}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="last name" />
                          {formik.touched.last_name && formik.errors.last_name && <div className='text-danger'>{formik.errors.last_name}</div>}
                    </div> */}
          <div className="mb-3">
            <label className="form-label">
              Email <span className="text-danger">*</span>
            </label>
            <input
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              type="email"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-danger">{formik.errors.email}</div>
            )}
          </div>
          {/* <div className="mb-3">
                        <label className="form-label">Contact Number <span className="text-danger">*</span></label>
                        <input 
                         id="phone_number" name="phone_number" onChange={formik.handleChange} value={formik.values.phone_number}
                    
                        type="number" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="phone number" />
                          {formik.touched.phone_number && formik.errors.phone_number && <div className='text-danger'>{formik.errors.phone_number}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Organization Name <span className="text-danger">*</span></label>
                        <input 
                         id="org_name" name="org_name" onChange={formik.handleChange} value={formik.values.org_name}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="organization name" />
                          {formik.touched.org_name && formik.errors.org_name && <div className='text-danger'>{formik.errors.org_name}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Registered Name <span className="text-danger">*</span></label>
                        <input 
                         id="registered_name" name="registered_name" onChange={formik.handleChange} value={formik.values.registered_name}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="register name" />
                          {formik.touched.registered_name && formik.errors.registered_name && <div className='text-danger'>{formik.errors.registered_name}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Website  <span className="text-danger">*</span></label>
                        <input 
                         id="website" name="website" onChange={formik.handleChange} value={formik.values.website}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="website" />
                          {formik.touched.website && formik.errors.website && <div className='text-danger'>{formik.errors.website}</div>}
                    </div>
                    
                    <div className="mb-3">
                        <label className="form-label">Address  <span className="text-danger">*</span></label>
                        <input 
                         id="address" name="address" onChange={formik.handleChange} value={formik.values.address}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="address" />
                          {formik.touched.address && formik.errors.address && <div className='text-danger'>{formik.errors.address}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">City  <span className="text-danger">*</span></label>
                        <input 
                         id="city" name="city" onChange={formik.handleChange} value={formik.values.city}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="city" />
                          {formik.touched.city && formik.errors.city && <div className='text-danger'>{formik.errors.city}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">State  <span className="text-danger">*</span></label>
                        <input 
                         id="state" name="state" onChange={formik.handleChange} value={formik.values.state}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="state" />
                          {formik.touched.state && formik.errors.state && <div className='text-danger'>{formik.errors.state}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Country  <span className="text-danger">*</span></label>
                        <input 
                         id="country" name="country" onChange={formik.handleChange} value={formik.values.country}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="country" />
                          {formik.touched.country && formik.errors.country && <div className='text-danger'>{formik.errors.country}</div>}
                    </div>
					          <div className="mb-3">
                        <label className="form-label">Pin Code  <span className="text-danger">*</span></label>
                        <input 
                         id="pin_code" name="pin_code" onChange={formik.handleChange} value={formik.values.pin_code}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="pin code" />
                          {formik.touched.pin_code && formik.errors.pin_code && <div className='text-danger'>{formik.errors.pin_code}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Country Code  <span className="text-danger">*</span></label>
                        <input 
                         id="country_code" name="country_code" onChange={formik.handleChange} value={formik.values.country_code}
                    
                        type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="country code" />
                          {formik.touched.country_code && formik.errors.country_code && <div className='text-danger'>{formik.errors.country_code}</div>}
                    </div> */}
          <div className="mb-3">
            <div className="d-flex justify-content-between">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              {passwordShow === "text" ? (
                <i
                  onClick={() => togglePasswordView()}
                  className="bi bi-eye passwordEyeText me-2"
                ></i>
              ) : (
                <i
                  onClick={() => togglePasswordView()}
                  className="bi bi-eye-slash passwordEyePassword me-2"
                ></i>
              )}
            </div>
            <input
              id="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              type={passwordShow}
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="password"
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-danger">{formik.errors.password}</div>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">
              Confirm Password <span className="text-danger">*</span>
            </label>
            <input
              id="confirm_password"
              name="confirm_password"
              onChange={formik.handleChange}
              value={formik.values.confirm_password}
              type={passwordShow}
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="confirm password"
            />
            {formik.touched.confirm_password &&
              formik.errors.confirm_password && (
                <div className="text-danger">
                  {formik.errors.confirm_password}
                </div>
              )}
          </div>

          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100"
            >
              Sign Up
            </button>
          </div>
          <div className="text-inverse text-opacity-50 text-center">
            Already have an Admin ID? <Link to="/login">Sign In</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PagesRegister;
