import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody } from "../../components/card/card";
import { instructAxios } from "../../config/instraction.axios";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { toast } from "react-toastify";
import { orgAxios } from "../../config/org.axios";
import { useParams } from "react-router-dom";
import { socket } from "../../socket/SocketConnection";
import { getOrgToken } from "../../config/global";

const AgentActions = () => {
  const { agentHostIp} = useParams();
  const [agentsStopped, setAgentsStopped] = useState([]);
  const [agentsBlocked, setAgentsBlocked] = useState([]);
  const [toolTipShow, setToolTipShow] = useState(false);
  const [toolTipData, setToolTipData] = useState("block");
  const [toolTipShow1, setToolTipShow1] = useState(false);
  const [toolTipData1, setToolTipData1] = useState("stop");
  const [index, setIndex] = useState();
  const [index1, setIndex1] = useState();

  const listBlockedStopped = async (ip) => {
    orgAxios.get(`api/v2/list-blocked-and-stopped-items/${ip}`).then((res) => {
      if (res.status === 200) {
        setAgentsBlocked(res?.data?.data?.blocked_items);
        setAgentsStopped(res?.data?.data?.stopped_items);
      }
    });
  };

  const actionCheck = async (ip,instruction,service_name) => {
    if(instruction === 'block-domain') {
      actionPerformed(ip,'unblock-domain',service_name)
    }else if(instruction === 'block-ip'){
      actionPerformed(ip,'unblock-ip',service_name)
    }
  }
  const actionPerformed = async (ip,instruction,service_name) => {
    instructAxios.post(`agent-instructions`,{
      hostip:ip,
      instruction:instruction,
      service_name:service_name
    }).then((res) => {
      if (res.status === 200 || res.status === 201) {
        toast.success(res.data.message);
      }
    },(error) => {
      toast.error(error);
    })
  }

  async function instructionValidate(socketResponse) {
    console.log(" instructionValidate", socketResponse);
    const orgId = JSON.parse(getOrgToken());
    if (socketResponse.org_id === orgId) {
      if (socketResponse.status === "success") {
        toast.success(`${socketResponse.service_name}  ${socketResponse.msg}`);
        listBlockedStopped(agentHostIp);
      } else if (socketResponse.status === "failed") {
        toast.warn(`${socketResponse.service_name}  ${socketResponse.msg}`);
        listBlockedStopped(agentHostIp);
      }
    }
  }

  useEffect(() => {
    socket.on("agent_action", (data) => {
      instructionValidate(data);
    });
    return () => {
      socket.off("agent_action", (data) => {
        instructionValidate(data);
      });
    };
  }, []);

  console.log("agentHostIp",agentHostIp)
  useEffect(() => {
    listBlockedStopped(agentHostIp);
  }, []);

  return (
    <>
      <div className="mb-3">
        <h3>Action Against Agent</h3>
        <h5>
          Perform Block, Unblock ip and domain, Start, Scan, Restart, Stop,
          Remove services{" "}
        </h5>
      </div>
      <Card>
        <CardBody>
          <CardHeader className="mb-2">Blocked Services</CardHeader>
          <div className="row">
            {agentsBlocked.length > 0 ? (
              agentsBlocked.map((data, idx) => {
                return (
                  <div key={idx} className="col-4 mb-2">
                    <div
                      style={{
                        backgroundColor:
                          localStorage.getItem("appMode") === "dark"
                            ? "#353535"
                            : "#c5c5c5",
                      }}
                      className="d-flex justify-content-between align-items-center px-3 py-2"
                    >
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0">{data.service_name}</h6>
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-2 position-relative">
                        {toolTipShow && index === idx ? (
                          <div
                            style={{
                              top: "-20px",
                              padding: "2px 4px 2px 4px",
                              backgroundColor: "gray",
                              borderRadius: "10px",
                              maxWidth: "70px",
                            }}
                            className="position-absolute"
                          >
                            {toolTipData}
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* <i
                          onMouseEnter={() => {
                            setToolTipShow(true);
                            setToolTipData("block");
                            setIndex(idx);
                          }}
                          onMouseLeave={() => setToolTipShow(false)}
                          className="bi bi-lock-fill fw-bolder iocAction p-1"
                        ></i> */}
                        <i
                          onMouseEnter={() => {
                            setToolTipShow(true);
                            setToolTipData("unblock");
                            setIndex(idx);
                          }}
                          onClick={() => actionCheck(data.host_ip,data.action,data.service_name)}
                          onMouseLeave={() => setToolTipShow(false)}
                          className="bi bi-unlock-fill fw-bolder iocAction p-1"
                        ></i>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="ms-2">all service are running</div>
            )}
          </div>
        </CardBody>
        <CardBody>
          <CardHeader className="mb-2">Stopped Services</CardHeader>
          <div className="row">
            {agentsStopped.length > 0 ? (
              agentsStopped.map((data, idx1) => {
                return (
                  <div key={idx1} className="col-4 mb-2">
                    <div
                      style={{
                        backgroundColor:
                          localStorage.getItem("appMode") === "dark"
                            ? "#353535"
                            : "#c5c5c5",
                      }}
                      className="d-flex justify-content-between align-items-center px-3 py-2"
                    >
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0">{data.service_name}</h6>
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-2 position-relative">
                        {toolTipShow1 && index1 === idx1 ? (
                          <div
                            style={{
                              top: "-20px",
                              padding: "2px 4px 2px 4px",
                              backgroundColor: "gray",
                              borderRadius: "10px",
                              maxWidth: "70px",
                            }}
                            className="position-absolute"
                          >
                            {toolTipData1}
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* <i
                          onMouseEnter={() => {
                            setToolTipShow1(true);
                            setToolTipData1("start");
                            setIndex1(idx1);
                          }}
                          onMouseLeave={() => setToolTipShow1(false)}
                          className="bi bi-lock-fill fw-bolder iocAction p-1"
                        ></i> */}
                        <i
                          onMouseEnter={() => {
                            setToolTipShow1(true);
                            setToolTipData1("stop");
                            setIndex1(idx1);
                          }}
                          onMouseLeave={() => setToolTipShow1(false)}
                          className="bi bi-unlock-fill fw-bolder iocAction p-1"
                        ></i>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="ms-2">all service are running</div>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AgentActions;
