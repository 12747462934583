import { useContext, useState } from "react";
import { orgAxios } from "../../config/org.axios";
import { toast } from "react-toastify";
import { error } from "jquery";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import UpDateIoc from "../ioc/updaateioc";
import { AppSettings } from "../../config/app-settings";
import { LineWaveLoader } from "../loader";
import { getOrgToken } from "../../config/global";
import { instructAxios } from "../../config/instraction.axios";

const orgToken = JSON.parse(getOrgToken());

export const COLUMNS = [
  {
    Header: "Agent Name",
    Footer: "Agent Name",
    accessor: "alias_name",
    Cell: ({ row }) => {
      return (
        <>
          <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
            {row.original.alias_name}
          </a>
        </>
      );
    },
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "HOSTNAME",
    Footer: "HOSTNAME",
    accessor: "hostname",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.hostname}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "UPTIME",
    Footer: "UPTIME",
    accessor: "uptime",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.uptime}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "PROCS",
    Footer: "PROCS",
    accessor: "procs",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.procs}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "OS",
    Footer: "OS",
    accessor: "os",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.os}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "PLATFORM",
    Footer: "PLATFORM",
    accessor: "platform",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.platform}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "PLATFORM FAMILY",
    Footer: "PLATFORM FAMILY",
    accessor: "platformFamily",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.platformFamily}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "PLATFORM VERSION",
    Footer: "PLATFORM VERSION",
    accessor: "platformVersion",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.platformVersion}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "KERNEL VERSION",
    Footer: "KERNEL VERSION",
    accessor: "kernelVersion",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.kernelVersion}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "KERNEL ARCH",
    Footer: "KERNEL ARCH",
    accessor: "kernelArch",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.kernelArch}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "CPU",
    Footer: "CPU",
    accessor: "cpu",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row.original.cpu}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "VENDOR ID",
    Footer: "VENDOR ID",
    accessor: "vendorId",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.vendorId}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "FAMILY",
    Footer: "family",
    accessor: "family",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.family}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "MODEL",
    Footer: "MODEL",
    accessor: "model",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.model}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "STEPPING",
    Footer: "STEPPING",
    accessor: "stepping",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.stepping}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "PHYSICAL ID",
    Footer: "PHYSICAL ID",
    accessor: "physicalId",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.physicalId}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "CORE ID",
    Footer: "CORE ID",
    accessor: "coreId",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.coreId}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "CORES",
    Footer: "CORES",
    accessor: "cores",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.cores}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "MODEL NAME",
    Footer: "MODEL NAME",
    accessor: "modelName",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.modelName}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MHZ",
    Footer: "MHZ",
    accessor: "mhz",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.mhz}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "CACHE SIZE",
    Footer: "CACHE SIZE",
    accessor: "cacheSize",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.cacheSize}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MICRO CODE",
    Footer: "MICRO CODE",
    accessor: "microcode",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.microcode}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "FREE",
    Footer: "FREE",
    accessor: "diskInfofree",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.free}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "FS TYPE",
    Footer: "FS TYPE",
    accessor: "fstype",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.fstype}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "INODESFREE",
    Footer: "INODESFREE",
    accessor: "inodesFree",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.inodesFree}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "INODESTOTAL",
    Footer: "INODESTOTAL",
    accessor: "inodesTotal",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.inodesTotal}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "INODESUSED",
    Footer: "INODESUSED",
    accessor: "inodesUsed",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.inodesUsed}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "INODESUSEDPERCENT",
    Footer: "INODESUSEDPERCENT",
    accessor: "inodesUsedPercent",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.inodesUsedPercent}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "PATH",
    Footer: "PATH",
    accessor: "path",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.path}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "USED",
    Footer: "USED",
    accessor: "used",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.used}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "USED PERCENT",
    Footer: "USED PERCENT",
    accessor: "usedPercent",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.usedPercent}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "TOTAL",
    Footer: "TOTAL",
    accessor: "diskInfototal",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.total}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "HOST IP",
    Footer: "HOST IP",
    accessor: "hostIP",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.hostIP}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "BOOT TIME",
    Footer: "BOOT TIME",
    accessor: "bootTime",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.bootTime}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "HOST ID",
    Footer: "HOST ID",
    accessor: "hostid",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.hostid}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "VIRTUALIZATION ROLE",
    Footer: "VIRTUALIZATION ROLE",
    accessor: "virtualizationRole",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.virtualizationRole}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "VIRTUALIZATION SYSTEM",
    Footer: "VIRTUALIZATION SYSTEM",
    accessor: "virtualizationSystem",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.virtualizationSystem}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY ACTIVE",
    Footer: "MEMORY ACTIVE",
    accessor: "active",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.active}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY AVAILABLE",
    Footer: "MEMORY AVAILABLE",
    accessor: "available",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.available}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY BUFFERS",
    Footer: "MEMORY BUFFERS",
    accessor: "buffers",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.buffers}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY CACHED",
    Footer: "MEMORY CACHED",
    accessor: "cached",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.cached}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY COMMIT LIMIT",
    Footer: "MEMORY COMMIT LIMIT",
    accessor: "commitlimit",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.commitlimit}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY COMMITED AS",
    Footer: "MEMORY COMMITED AS",
    accessor: "committedas",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.committedas}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY DIRTY",
    Footer: "MEMORY DIRTY",
    accessor: "dirty",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.dirty}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY FREE",
    Footer: "MEMORY FREE",
    accessor: "free",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.free}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY HIGH FREE",
    Footer: "MEMORY HIGH FREE",
    accessor: "highfree",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.highfree}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY HIGH TOTAL",
    Footer: "MEMORY HIGH TOTAL",
    accessor: "hightotal",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.hightotal}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY HUGE PAGES FREE",
    Footer: "MEMORY HUGE PAGES FREE",
    accessor: "hugepagesfree",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.hugepagesfree}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 440,
    width: 400,
  },
  {
    Header: "MEMORY HUGE PAGES SIZE",
    Footer: "MEMORY HUGE PAGES SIZE",
    accessor: "hugepagesize",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.hugepagesize}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 440,
    width: 400,
  },
  {
    Header: "MEMORY HUGE PAGES TOTAL",
    Footer: "MEMORY HUGE PAGES TOTAL",
    accessor: "hugepagestotal",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.hugepagestotal}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 400,
    width: 300,
  },
  {
    Header: "MEMORY  INACTIVE",
    Footer: "MEMORY  INACTIVE",
    accessor: "inactive",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.inactive}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY LAUNDRY",
    Footer: "MEMORY  LAUNDRY",
    accessor: "laundry",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.laundry}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY LOWFREE",
    Footer: "MEMORY LOWFREE",
    accessor: "lowfree",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.lowfree}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY LOWTOTAL",
    Footer: "MEMORY LOWTOTAL",
    accessor: "lowtotal",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.lowtotal}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY MAPPED",
    Footer: "MEMORY MAPPED",
    accessor: "mapped",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.mapped}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY PAGETABLES",
    Footer: "MEMORY PAGETABLES",
    accessor: "pagetables",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.pagetables}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY SHARED",
    Footer: "MEMORY SHARED",
    accessor: "shared",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.shared}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY SLAB",
    Footer: "MEMORY SLAB",
    accessor: "slab",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.slab}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY SRECLAIMABLE",
    Footer: "MEMORY SRECLAIMABLE",
    accessor: "sreclaimable",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.sreclaimable}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY SUNRECLAIM",
    Footer: "MEMORY SUNRECLAIM",
    accessor: "sunreclaim",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.sunreclaim}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY SWAPCACHED",
    Footer: "MEMORY SWAPCACHED",
    accessor: "swapcached",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.swapcached}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY SWAPFREE",
    Footer: "MEMORY SWAPFREE",
    accessor: "swapfree",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.swapfree}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY SWAPTOTAL",
    Footer: "MEMORY SWAPTOTAL",
    accessor: "swaptotal",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.swaptotal}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY TOTAL",
    Footer: "MEMORY TOTAL",
    accessor: "total",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.total}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY USED",
    Footer: "MEMORY USED",
    accessor: "memoryInfoused",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.used}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY USEDPERCENT",
    Footer: "MEMORY USEDPERCENT",
    accessor: "memoryInfousedPercent",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.usedPercent}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY VMALLOCCHUNK",
    Footer: "MEMORY VMALLOCCHUNK",
    accessor: "vmallocchunk",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.vmallocchunk}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 300,
    width: 400,
  },
  {
    Header: "MEMORY VMALLOCTOTAL",
    Footer: "MEMORY VMALLOCTOTAL",
    accessor: "vmalloctotal",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.vmalloctotal}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY VMALLOCUSED",
    Footer: "MEMORY VMALLOCUSED",
    accessor: "vmallocused",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.vmallocused}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY WIRED",
    Footer: "MEMORY WIRED",
    accessor: "wired",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.wired}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY WRITEBACK",
    Footer: "MEMORY WRITEBACK",
    accessor: "writeback",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.writeback}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "MEMORY WRITEBACKTMP",
    Footer: "MEMORY WRITEBACKTMP",
    accessor: "writebacktmp",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.writebacktmp}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
  {
    Header: "STATUS",
    Footer: "STATUS",
    accessor: "status",
    Cell: ({ row }) => (
      <>
        <a href={`/${orgToken}/analytics/all/${row.original._id}`}>
          {row?.original?.status}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 240,
    width: 300,
  },
];

export const AGENT_INFO_COLUMNS = [
  {
    Header: "Agent Name",
    accessor: "alias_name",

    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?._id}`}>
          {row?.original?.alias_name
            ? row?.original?.alias_name !== "-"
              ? row?.original?.alias_name
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
  },
  {
    Header: "Hostname",
    accessor: "hostname",
    Cell: ({ row }) => (
      <>
        <a href={`analytics/${row?.original?.status}/${row?.original?._id}`}>
          {row?.original?.hostname
            ? row?.original?.hostname !== "-"
              ? row?.original?.hostname
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Physical Id",
    accessor: "physicalId",
    Cell: ({ row }) => (
      <>
        <a href={`analytics/${row?.original?.status}/${row?.original?._id}`}>
          {row?.original?.physicalId
            ? row?.original?.physicalId !== "-"
              ? row?.original?.physicalId
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Model Name",
    accessor: "modelName",
    Cell: ({ row }) => (
      <>
        <a href={`analytics/${row?.original?.status}/${row?.original?._id}`}>
          {row?.original?.modelName
            ? row?.original?.modelName !== "-"
              ? row?.original?.modelName
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Vendor Id",
    accessor: "vendorId",
    id: Math.random(),
    Cell: ({ row }) => (
      <>
        <a href={`analytics/${row?.original?.status}/${row?.original?._id}`}>
          {row?.original?.vendorId
            ? row?.original?.vendorId !== "-"
              ? row?.original?.vendorId
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "OS",
    accessor: "os",
    Cell: ({ row, column }) => (
      <>
        <a href={`analytics/${row?.original?.status}/${row?.original?._id}`}>
          {row?.original?.os
            ? row?.original?.os !== "-"
              ? row?.original?.os
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Platform",
    accessor: "platform",
    Cell: ({ row }) => (
      <>
        <a href={`analytics/${row?.original?.status}/${row?.original?._id}`}>
          {row?.original?.platform
            ? row?.original?.platform !== "-"
              ? row?.original?.platform
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Host IP",
    accessor: "hostIP",
    Cell: ({ row }) => (
      <>
        <a href={`analytics/${row?.original?.status}/${row?.original?._id}`}>
          {row?.original?.hostIP
            ? row?.original?.hostIP !== "-"
              ? row?.original?.hostIP
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) => (
      <>
        <a
          href={`analytics/${row?.original?.status}/${row?.original?._id}`}
          className="text-capitalize"
        >
          {row?.original?.status
            ? row?.original?.status !== "-"
              ? row?.original?.status
              : "N/A"
            : "N/A"}
        </a>
      </>
    ),
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "View",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { setRowData } = useContext(AppSettings);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <i
            onClick={() => setRowData(row.cells)}
            className="bi bi-eye-fill detailTableView"
          ></i>
        </div>
      );
    },
  },
];
export const PROCESS_INFO_COLUMNS = [
  {
    Header: "Action",
    accessor: "action",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { agentHostIp } = useContext(AppSettings);
      const [tooltipShow, seTtooltipShow] = useState(false);
      const [tooltipData, seTtooltipData] = useState("block");
      async function blockIp(instruction, service_name) {
        let res = await instructAxios
          .post(`agent-instructions`, {
            hostip: agentHostIp,
            instruction: "block-ip",
            service_name: row?.original?.remoteaddr_ip,
          })
          .then(
            (response) => {
              if (response.status === 201) {
                toast.success(response.data.message);
              }
            },
            (error) => {
              toast.error(error);
            }
          );
      }
      return (
        <>
          <div className="d-flex position-relative justify-content-evenly align-items-center gap-2">
            {tooltipShow ? (
              <div
                style={{
                  top: "-20px",
                  padding: "2px 4px 2px 4px",
                  backgroundColor: "gray",
                  borderRadius: "10px",
                 
                }}
                className="position-absolute"
              >
                {tooltipData}
              </div>
            ) : (
              <></>
            )}
            <i
              style={{
                color: "red",
                cursor: "pointer",
              }}
              onClick={() => blockIp()}
              onMouseEnter={() => {
                seTtooltipShow(true);
              }}
              onMouseLeave={() => seTtooltipShow(false)}
              className="bi bi-x-circle fs-4"
            ></i>
          </div>
        </>
      );
    },
  },
  {
    Header: "Pid",
    accessor: "pid",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Vulnerability",
    accessor: "vulnerable",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
    Cell: ({ row }) => (
      <>
        {row?.original?.vulnerable === "clean" ? (
          <img
            alt=""
            height="30px"
            className="mw-100 mh-100"
            src="/assets/img/green_tick.svg"
          />
        ) : row?.original?.vulnerable === "not clean" ? (
          <img
            alt=""
            height="30px"
            className="mw-100 mh-100"
            src="/assets/img/cross.svg"
          />
        ) : (
          <img
            alt=""
            height="30px"
            className="mw-100 mh-100"
            src="/assets/img/question.svg"
          />
        )}
      </>
    ),
  },
  {
    Header: "Remote Address IP",
    accessor: "remoteaddr_ip",
    Cell: ({ row }) => (
      <div className="customLink">
        {/* <button onClick={(e) => handleShow(e)}> */}
        {row?.original?.remoteaddr_ip}
        {/* </button> */}
      </div>
    ),
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Family",
    accessor: "family",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "LocalAddr IP",
    accessor: "localaddr_ip",
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "LocalAddr Port",
    accessor: "localaddr_port",
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Process Name",
    accessor: "processname",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Protocol",
    accessor: "protocol",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Remote Address Port",
    accessor: "remoteaddr_port",
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Type",
    accessor: "type",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "UID",
    accessor: "uids",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Status",
    accessor: "status",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "View",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { setRowData } = useContext(AppSettings);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <i
            onClick={() => setRowData(row.cells)}
            
            className="bi bi-eye-fill detailTableView"
          ></i>
        </div>
      );
    },
  },
];
export const IP_TRACKING_COLUMNS = [
  {
    Header: "Vendor Name",
    accessor: "vendor_name",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Category",
    accessor: "category",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Engine Name",
    accessor: "engine_name",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Method",
    accessor: "method",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Result",
    accessor: "result",
    Cell: ({ row }) => (
      <>
        {row?.original?.result == "clean" ? (
          <span>
            <img
              alt=""
              height="30px"
              className="mw-100 mh-100"
              src="/assets/img/green_tick.svg"
            />

            {row?.original?.result}
          </span>
        ) : row?.original?.result == "unrated" ? (
          <span>
            <img
              alt=""
              height="30px"
              className="mw-100 mh-100"
              src="/assets/img/orange_tick.svg"
            />

            {row?.original?.result}
          </span>
        ) : (
          <span>
            <img
              alt=""
              height="30px"
              className="mw-100 mh-100"
              src="/assets/img/cross.svg"
            />
            {row?.original?.result}
          </span>
        )}
      </>
    ),
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
];

export const SANDBOX_DETECTION_COLUMNS = [
  {
    Header: "Vendor Name",
    accessor: "vendor_name",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Category",
    accessor: "category",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Confidence",
    accessor: "confidence",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Sandbox Name",
    accessor: "sandbox_name",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
];

export const HASH_DETECTION_COLUMNS = [
  {
    Header: "Vendor Name",
    accessor: "vendor_name",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Category",
    accessor: "category",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Engine Name",
    accessor: "engine_name",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Method",
    accessor: "method",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Engine Update",
    accessor: "engine_update",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Engine Version",
    accessor: "engine_version",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Result",
    accessor: "result",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
];

export const BACKGROUND_PROCESS_COLUMNS = [
  {
    Header: "Action",
    accessor: "action",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { agentHostIp } = useContext(AppSettings);
      const [tooltipShow, seTtooltipShow] = useState(false);
      const [tooltipData, seTtooltipData] = useState();
      async function refreshAgent(instruction, service_name) {
        let res = await instructAxios
          .post(`agent-instructions`, {
            hostip: agentHostIp,
            instruction: instruction,
            service_name: row?.original?.ImageName,
          })
          .then(
            (response) => {
              if (response.status === 201) {
                toast.success(response.data.message);
              }
            },
            (error) => {
              toast.error(error);
            }
          );
      }
      return (
        <>
          <div className="d-flex position-relative justify-content-evenly align-items-center gap-2">
            {tooltipShow ? (
              <div
                style={{
                  top: "-20px",
                  padding: "2px 4px 2px 4px",
                  backgroundColor: "gray",
                  borderRadius: "10px",
                }}
                className="position-absolute"
              >
                {tooltipData}
              </div>
            ) : (
              <></>
            )}
            <i
              style={{
                color: "green",
                cursor: "pointer",
              }}
              onClick={() => refreshAgent("start")}
              onMouseEnter={() => {
                seTtooltipShow(true);
                seTtooltipData("start");
              }}
              onMouseLeave={() => seTtooltipShow(false)}
              className="bi bi-play-circle-fill fs-4"
            ></i>
            <i
              style={{
                color: "green",
                cursor: "pointer",
              }}
              onClick={() => refreshAgent("restart")}
              onMouseEnter={() => {
                seTtooltipShow(true);
                seTtooltipData("re-start");
              }}
              onMouseLeave={() => seTtooltipShow(false)}
              className="bi bi-arrow-clockwise fs-4"
            ></i>
            <i
              style={{
                color: "red",
                cursor: "pointer",
              }}
              onClick={() => refreshAgent("stop")}
              onMouseEnter={() => {
                seTtooltipShow(true);
                seTtooltipData("stop");
              }}
              onMouseLeave={() => seTtooltipShow(false)}
              className="bi bi-sign-stop-fill fs-4"
            ></i>
          </div>
        </>
      );
    },
  },

  {
    Header: "ImageName",
    accessor: "ImageName",
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Vulnerable",
    accessor: "vulnerable",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
    Cell: ({ row }) => (
      <>
        {row?.original?.vulnerable === "clean" ? (
          <img
            alt=""
            height="30px"
            className="mw-100 mh-100"
            src="/assets/img/green_tick.svg"
          />
        ) : row?.original?.vulnerable === "not clean" ? (
          <img
            alt=""
            height="30px"
            className="mw-100 mh-100"
            src="/assets/img/cross.svg"
          />
        ) : (
          <img
            alt=""
            height="30px"
            className="mw-100 mh-100"
            src="/assets/img/question.svg"
          />
        )}
      </>
    ),
  },
  {
    Header: "CPUTime",
    accessor: "CPUTime",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "MemUsage",
    accessor: "MemUsage",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "PID",
    accessor: "PID",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "SessionName",
    accessor: "SessionName",
    maxWidth: 400,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "SessionNum",
    accessor: "SessionNum",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "Status",
    accessor: "Status",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "UserName",
    accessor: "UserName",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "WindowTitle",
    accessor: "WindowTitle",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },

  {
    Header: "Hash",
    accessor: "Hash",
    maxWidth: 400,
    minWidth: 140,
    width: 200,
  },
  {
    Header: "View",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { setRowData } = useContext(AppSettings);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <i
            onClick={() => setRowData(row.cells)}
            className="bi bi-eye-fill detailTableView"
          ></i>
        </div>
      );
    },
  },
];

export const SUSPICIOUS_AGENTS_COLUMNS = [
  {
    Header: "Agent Name",
    accessor: "alias_name",
    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.alias_name ? row?.original?.alias_name : "N/A"}
        </a>
      </>
    ),
  },
  {
    Header: "HOSTNAME",
    accessor: "hostname",
    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.hostname}
        </a>
      </>
    ),
  },
  {
    Header: "TOTAL MALICIOUS IPS",
    accessor: "total_malicious_ips",

    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.total_malicious_ips}
        </a>
      </>
    ),
  },
  {
    Header: "TOTAL SUSPICIOUS AGENTS",
    accessor: "total_suspicious_ips",

    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.total_suspicious_ips}
        </a>
      </>
    ),
  },
  {
    Header: "TOTAL WHITELISTED IPS",
    accessor: "total_whitelisted_ips_abuseipdb",

    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.total_whitelisted_ips_abuseipdb}
        </a>
      </>
    ),
  },
  {
    Header: "View",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { setRowData } = useContext(AppSettings);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <i
            onClick={() => setRowData(row.cells)}
            className="bi bi-eye-fill detailTableView"
          ></i>
        </div>
      );
    },
  },
];

export const SYS_LOG_COLOUMNS = [
  {
    Header: "Process Id",
    accessor: "ProcessId",
  },
  {
    Header: "Record Id",
    accessor: "RecordId",
  },
  {
    Header: "Log Name",
    accessor: "LogName",
  },
  {
    Header: "Machine Name",
    accessor: "MachineName",
  },
  {
    Header: "Provider Name",
    accessor: "ProviderName",
  },
  {
    Header: "Task Display Name",
    accessor: "TaskDisplayName",
  },

  {
    Header: "Message",
    accessor: "Message",
    Cell: ({ row }) => {
      return (
        <div
          style={{
            maxWidth: "100px",
          }}
          className="text-truncate d-inline-block "
        >
          {row?.original?.Message}
        </div>
      );
    },
  },
  {
    Header: "Alias",
    accessor: "alias_name",
    Cell: ({ row }) => {
      const { agentHostIp } = useContext(AppSettings);
      const [showEventLogDetails, setShowEventLogDetails] = useState(false);
      const [eventLogData, setEventLogData] = useState({});
      const [aliasLoader, setAliasLoader] = useState(false);
      const [updateAgentAlias, setUpdateAgentAlias] = useState("");
      const [aliasName, setAliasName] = useState("");
      const eventLogDetails = async () => {
        setShowEventLogDetails(true);
        await orgAxios
          .get(
            `get_sys_events_logs_details/${agentHostIp}/${row?.original?.RecordId}?log_name=${row?.original?.LogName}`
          )
          .then((res) => {
            if (res.status === 200) {
              setEventLogData(res?.data?.data?.event_log);
              setAliasName(res?.data?.data?.event_log.alias_name);
            }
          });
      };
      const handleChangeAlias = async (name) => {
        setAliasLoader(true);
        await orgAxios
          .post(
            `set_sys_logs_alias/${agentHostIp}/${row?.original?.RecordId}`,
            {
              log_name: row?.original?.LogName,
              alias_name: name,
            }
          )
          .then(
            (res) => {
              if (res.status === 200) {
                toast.success(res.data?.message);
                eventLogDetails();
                setAliasLoader(false);
              }
            },
            (error) => {
              toast.error(error.message);
              setAliasLoader(false);
            }
          );
      };
      return (
        <>
          <div className="mapIpClick" onClick={() => eventLogDetails()}>
            {row?.original?.alias_name && row?.original?.alias_name !== ""
              ? row?.original?.alias_name
              : "add alias"}
          </div>
          <Modal
            className="deleteIocModal"
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showEventLogDetails}
          >
            <ModalHeader className="d-flex justify-content-between">
              <div>{aliasName !== "" ? aliasName : "alias"} Record </div>
              <i
                onClick={() => setShowEventLogDetails(false)}
                className="bi bi-x-circle modalHide"
              ></i>
            </ModalHeader>
            <ModalBody>
              <div className="">
                <div className="d-flex gap-2 justify-content-start align-items-center">
                  <input
                    className="form-control form-control-lg bg-white bg-opacity-5"
                    onChange={(e) => setUpdateAgentAlias(e.target.value)}
                    type="text"
                    placeholder="Desire Alias Name"
                  />
                  <button
                    onClick={() => handleChangeAlias(updateAgentAlias)}
                    className="btn btn-outline-theme btn-lg d-block w-25 fw-500 mt-3 mb-3"
                  >
                    <div className="d-flex gap-2 justify-content-center align-item-center">
                      {" "}
                      {aliasLoader ? <LineWaveLoader /> : <span>update</span>}
                    </div>
                  </button>
                </div>
                <div className="border-bottom border-dark pb-1 mb-2">
                  <span className="fw-bold">Machine Name: </span>
                  {eventLogData.MachineName}
                </div>
                <div className="border-bottom border-dark pb-1 mb-2">
                  <span className="fw-bold">Log Name: </span>
                  {eventLogData.LogName}
                </div>
                <div className="border-bottom border-dark pb-1 mb-2">
                  <span className="fw-bold">provide Name: </span>
                  {eventLogData.ProviderName}
                </div>
                <div className="pb-1 mb-2 text-justify">
                  <span className="fw-bold">Message: </span>
                  {eventLogData.Message}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      );
    },
  },
  {
    Header: "View",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { setRowData } = useContext(AppSettings);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <i
            onClick={() => setRowData(row.cells)}
            className="bi bi-eye-fill detailTableView"
          ></i>
        </div>
      );
    },
  },
];

export const ALERTS_COLUMNS = [
  {
    Header: "Agent Name",
    accessor: "agent_name",
    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.agent_name}
        </a>
      </>
    ),
  },
  {
    Header: "Alert Type",
    accessor: "alert_type",

    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.alert_type}
        </a>
      </>
    ),
  },
  {
    Header: "Severity",
    accessor: "severity",

    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.severity}
        </a>
      </>
    ),
  },
  {
    Header: "Source",
    accessor: "source",

    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.source}
        </a>
      </>
    ),
  },

  {
    Header: "Type",
    accessor: "type",

    Cell: ({ row }) => (
      <>
        <a href={`analytics/active/${row?.original?.agent_id}`}>
          {row?.original?.type}
        </a>
      </>
    ),
  },
  {
    Header: "Date",
    accessor: "date",

    Cell: ({ row }) => {
      var date = new Date(row?.original?.date);
      var formatedData = `${date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })} ${date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      })}`;

      return (
        <>
          <a href={`analytics/active/${row?.original?.agent_id}`}>
            {formatedData}
          </a>
        </>
      );
    },
  },
  {
    Header: "Value",
    accessor: "value",

    Cell: ({ row }) => {
      const [showTooltip, setShowTooltip] = useState(false);

      const handleMouseEnter = () => {
        setShowTooltip(true);
      };

      const handleMouseLeave = () => {
        setShowTooltip(false);
      };

      return (
        <div
          className="custom-tooltip"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <a href={`analytics/active/${row?.original?.agent_id}`}>
            {row?.original?.value.length > 30
              ? row?.original?.value.substring(0, 30)
              : row?.original?.value}
          </a>

          {showTooltip && (
            <span
              style={{
                zIndex: 10,
                position: "absolute",
                padding: "15px",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                alignItems: "center",
                maxWidth: "200px",
                overflow: "scroll",
                maxWidth: "250px",
              }}
              className="tooltip-text"
            >
              {row?.original?.value}
            </span>
          )}
        </div>
      );
    },
  },
  {
    Header: "View",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { setRowData } = useContext(AppSettings);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <i
            onClick={() => setRowData(row.cells)}
            className="bi bi-eye-fill detailTableView"
          ></i>
        </div>
      );
    },
  },
];

export const ALERTS_COLUMNS_LOGDETAILS = [
  {
    Header: "Alert Type",
    accessor: "alert_type",
  },
  {
    Header: "Severity",
    accessor: "severity",
  },
  {
    Header: "Source",
    accessor: "source",
    Cell: ({ row }) => {
      const [showAlertIoc, setShowAlertIoc] = useState(false);
      const [alertData, setAlertData] = useState({});
      const [customIocData, setCustomIocData] = useState({});
      const agentAlertDetails = async () => {
        orgAxios.get(`agent/alerts/${row.original._id}`).then((alerts) => {
          if (alerts.status === 200) {
            setAlertData(alerts?.data?.alert_data);
            setCustomIocData(alerts?.data?.custom_ioc);
            setShowAlertIoc(true);
          }
        });
      };
      return (
        <div>
          <div className="customLink" onClick={() => agentAlertDetails()}>
            {row?.original?.source}
          </div>
          <Modal
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showAlertIoc}
          >
            <ModalHeader className="d-flex justify-content-between">
              <div>agent alert resion - {alertData?.value} </div>
              <i
                onClick={() => setShowAlertIoc(false)}
                className="bi bi-x-circle modalHide"
              ></i>
            </ModalHeader>
            <ModalBody>
              <>
                <div>
                  <span className="fw-bold">Alert Type : </span>
                  {alertData?.alert_type}
                </div>
                <div>
                  <span className="fw-bold">Alert Date : </span>
                  {alertData?.date}
                </div>
                <div>
                  <span className="fw-bold">Alert Severity : </span>
                  {alertData?.severity}
                </div>
                <div>
                  <span className="fw-bold">Alert Source : </span>
                  {alertData?.source}
                </div>
                <div>
                  <span className="fw-bold">Alert Type : </span>
                  {alertData?.type}
                </div>
                <div>
                  <span className="fw-bold">Alert Update Date : </span>
                  {alertData?.update_date}
                </div>
                <div>
                  <span className="fw-bold">Alert Valid : </span>
                  {alertData?.valid ? "True" : "False"}
                </div>
              </>
              {row?.original?.source === "IP_TRACING" ? (
                <></>
              ) : (
                <div>
                  <div className="fw-bold fs-3"> IOC Report</div>
                  {customIocData && customIocData._id ? (
                    <>
                      <div>
                        <span className="fw-bold">Associated Malware : </span>
                        {customIocData?.associated_malware}
                      </div>
                      <div>
                        <span className="fw-bold">Confidence : </span>
                        {customIocData?.confidence}
                      </div>
                      <div>
                        <span className="fw-bold">Countermeasures : </span>
                        {customIocData?.countermeasures}
                      </div>
                      <div>
                        <span className="fw-bold">Data : </span>
                        {customIocData?.data}
                      </div>
                      <div>
                        <span className="fw-bold">Date Discovered : </span>
                        {customIocData?.date_discovered}
                      </div>
                      <div>
                        <span className="fw-bold">Impact : </span>
                        {customIocData?.impact}
                      </div>
                      <div>
                        <span className="fw-bold">Indicator Type : </span>
                        {customIocData?.indicator_type}
                      </div>
                      <div>
                        <span className="fw-bold">Last Seen : </span>
                        {customIocData?.last_seen}
                      </div>
                      <div>
                        <span className="fw-bold">Note : </span>
                        {customIocData?.note}
                      </div>

                      <div>
                        <span className="fw-bold">Source : </span>
                        {customIocData?.source}
                      </div>
                      <div>
                        <span className="fw-bold">Tags : </span>
                        {customIocData?.tags}
                      </div>
                      <div>
                        <span className="fw-bold">Valid : </span>
                        {customIocData?.valid ? "True" : "False"}
                      </div>
                    </>
                  ) : (
                    <>no ioc detected</>
                  )}
                </div>
              )}
            </ModalBody>
          </Modal>
        </div>
      );
    },
  },

  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Date",
    accessor: "date",

    Cell: ({ row }) => {
      var date = new Date(row?.original?.date);
      var formatedData = `${date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })} ${date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      })}`;
      return <>{formatedData}</>;
    },
  },
  {
    Header: "Value of Hash/IP",
    accessor: "value",

    Cell: ({ row }) => {
      const [showTooltip, setShowTooltip] = useState(false);

      const handleMouseEnter = () => {
        setShowTooltip(true);
      };

      const handleMouseLeave = () => {
        setShowTooltip(false);
      };
      return (
        <div
          className="custom-tooltip"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="customLink">
            {row?.original?.value.length > 30
              ? row?.original?.value.substring(0, 30)
              : row?.original?.value}
          </div>
          {showTooltip && (
            <span
              style={{
                zIndex: 10,
                position: "absolute",
                padding: "15px",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                alignItems: "center",
                maxWidth: "200px",
                overflow: "scroll",
                maxWidth: "250px",
              }}
              className="tooltip-text"
            >
              {row?.original?.value}
            </span>
          )}
        </div>
      );
    },
  },
  {
    Header: "View",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { setRowData } = useContext(AppSettings);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <i
            onClick={() => setRowData(row.cells)}
            className="bi bi-eye-fill detailTableView"
          ></i>
        </div>
      );
    },
  },
];

export const IOC_COLUMNS = [
  {
    Header: "Indicator Type",
    accessor: "indicator_type",
  },
  {
    Header: "Data",
    accessor: "data",
  },
  {
    Header: "Note",
    accessor: "note",
  },
  {
    Header: "Source",
    accessor: "source",
  },
  {
    Header: "Associated Malware",
    accessor: "associated_malware",
  },
  {
    Header: "Confidence",
    accessor: "confidence",
  },
  {
    Header: "Countermeasures",
    accessor: "countermeasures",
  },
  {
    Header: "Data Discovered",
    accessor: "date_discovered",
  },
  {
    Header: "Impact",
    accessor: "impact",
  },
  {
    Header: "Last Seen",
    accessor: "last_seen",
  },
  {
    Header: "Tags",
    accessor: "tags",
  },
];
export const CUSTOM_IOC_COLUMNS = [
  {
    Header: "Actions",
    accessor: "_id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const [uploadModal, setUploadModal] = useState(false);
      const [deleteModal, setDeleteModal] = useState(false);
      const {
        customIocCanPreviousPage,
        setCustomIocCanPreviousPage,
        customIocCanNextPage,
        setCustomIocCanNextPage,
        customIocCurrentPage,
        setCustomIocCurrentPage,
        totalPageCustomIoc,
        setTotalPageCustomIoc,
        customIocData,
        setCustomIocData,
        customIocSearchQuery,
        setCustomIocSearchQuery,
        customIocCount,
        setCustomIocCount,
        customIocLimit,
      } = useContext(AppSettings);
      const [deleatLoader, setDeleteLoader] = useState(false);
      const customIocDataApiCall = async (page,limit) => {
        await orgAxios.get(`get-custom-iocs?page=${page}&limit=${limit}`).then((response) => {
          if (response.status === 200) {
            setCustomIocData(response?.data?.data);
            setTotalPageCustomIoc(response?.data?.total);
            setCustomIocCount(response?.data?.total_iocs);
            if (page === 1) {
              setCustomIocCanNextPage(true);
              setCustomIocCanPreviousPage(false);
            }
            if (response?.data?.next_page !== -1) {
              setCustomIocCurrentPage(response?.data?.next_page - 1);
            } else {
              if (response?.data?.total === 1) {
                setCustomIocCanNextPage(false);
                setCustomIocCanPreviousPage(false);
                setCustomIocCurrentPage(response?.data?.total);
              } else {
                setCustomIocCanNextPage(false);
                setCustomIocCanPreviousPage(true);
                setCustomIocCurrentPage(response?.data?.total);
              }
            }
          }
        });
      };

      const searchApiCall = async (page,limit, query) => {
        await orgAxios
          .get(`search-iocs?type=custom&query=${query}&page=${page}&limit=${limit}`)
          .then((response) => {
            if (response.status === 200) {
              setCustomIocData(response?.data?.data);
              setTotalPageCustomIoc(response?.data?.total);
              if (page === 1) {
                setCustomIocCanNextPage(true);
                setCustomIocCanPreviousPage(false);
              }
              if (response?.data?.next_page !== -1) {
                setCustomIocCurrentPage(response?.data?.next_page - 1);
              } else {
                if (response?.data?.total === 1) {
                  setCustomIocCanNextPage(false);
                  setCustomIocCanPreviousPage(false);
                  setCustomIocCurrentPage(response?.data?.total);
                } else {
                  setCustomIocCanNextPage(false);
                  setCustomIocCanPreviousPage(true);
                  setCustomIocCurrentPage(response?.data?.total);
                }
              }
            }
          });
      };
      const deleteIoc = async () => {
        setDeleteLoader(true);
        await orgAxios.delete(`ioc/${row?.original?._id}`).then(
          (response) => {
            if (response.status === 200) {
              toast.warn(response?.data?.message);
              if (customIocSearchQuery !== "") {
                if (customIocData.length === 1) {
                  searchApiCall(customIocCurrentPage - 1,customIocLimit, customIocSearchQuery);
                } else {
                  searchApiCall(customIocCurrentPage, customIocLimit,customIocSearchQuery);
                }
              } else {
                if (customIocData.length === 1) {
                  customIocDataApiCall(customIocCurrentPage - 1,customIocLimit);
                } else {
                  customIocDataApiCall(customIocCurrentPage,customIocLimit);
                }
              }
              setDeleteModal(false);
              setDeleteLoader(false);
            }
          },
          (error) => {
            toast.error(error.message);
            setDeleteLoader(false);
          }
        );
      };
      const updateIoc = async () => {
        setUploadModal(true);
      };
      return (
        <div className="d-flex gap-3 justify-content-center">
          <i
            onClick={() => updateIoc()}
            className="bi bi-pencil-square text-success iocAction iocUpload align-self-center"
          ></i>
          <i
            onClick={() => setDeleteModal(true)}
            className="bi bi-archive-fill text-danger iocAction iocDelete align-self-center"
          ></i>
          <Modal show={uploadModal}>
            <ModalHeader className="d-flex justify-content-between">
              <div>update curent ioc</div>
              <i
                onClick={() => setUploadModal(false)}
                className="bi bi-x-circle modalHide"
              ></i>
            </ModalHeader>
            <ModalBody>
              <UpDateIoc
                setUploadModal={setUploadModal}
                _id={row?.original?._id}
                customIocDataApiCall={customIocDataApiCall}
                searchApiCall={searchApiCall}
              />
            </ModalBody>
          </Modal>
          <Modal
            className="deleteIocModal"
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={deleteModal}
          >
            <ModalHeader className="d-flex justify-content-between">
              <div>do you want to detele</div>
              <i
                onClick={() => setDeleteModal(false)}
                className="bi bi-x-circle modalHide"
              ></i>
            </ModalHeader>
            <ModalBody>
              <div className="">
                <div className="d-flex justify-content-center">
                  id: {row?.original?._id}
                </div>
                <i
                  style={{
                    fontSize: "100px",
                    color: "red",
                  }}
                  className="bi bi-x-circle-fill d-flex justify-content-center mb-5 mt-3"
                ></i>
                {deleatLoader ? (
                  <div className="btn btn-danger btn-lg d-block w-100 fw-500 mt-5 mb-3">
                    <div className="d-flex justify-content-center">
                      <LineWaveLoader color={"white"} />
                    </div>
                  </div>
                ) : (
                  <Button
                    onClick={() => deleteIoc()}
                    className="btn btn-danger btn-lg d-block w-100 fw-500 mt-5 mb-3"
                  >
                    confirm delete
                  </Button>
                )}
              </div>
            </ModalBody>
          </Modal>
        </div>
      );
    },
  },
  {
    Header: "Indicator Type",
    accessor: "indicator_type",
  },
  {
    Header: "Data",
    accessor: "data",
  },
  {
    Header: "Note",
    accessor: "note",
  },
  {
    Header: "Source",
    accessor: "source",
  },
  {
    Header: "Associated Malware",
    accessor: "associated_malware",
  },
  {
    Header: "Confidence",
    accessor: "confidence",
  },
  {
    Header: "Countermeasures",
    accessor: "countermeasures",
  },
  {
    Header: "Data Discovered",
    accessor: "date_discovered",
  },
  {
    Header: "Impact",
    accessor: "impact",
  },
  {
    Header: "Lasr Seen",
    accessor: "last_seen",
  },
  {
    Header: "Tags",
    accessor: "tags",
  },
];

export const AGENT_PORT_COLOUM = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Port",
    accessor: "Portid",
  },
  {
    Header: "Name",
    accessor: "Name",
  },
  {
    Header: "OS Type",
    accessor: "Ostype",
  },
  {
    Header: "Reason",
    accessor: "Reason",
  },
  {
    Header: "State",
    accessor: "State",
  },
  {
    Header: "Protocol",
    accessor: "Protocol",
  },
  {
    Header: "Version",
    accessor: "Version",
  },
  {
    Header: "View",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { setRowData } = useContext(AppSettings);
      return (
        <div className="d-flex justify-content-center align-items-center">
          <i
            onClick={() => setRowData(row.cells)}
            className="bi bi-eye-fill detailTableView"
          ></i>
        </div>
      );
    },
  },
];
