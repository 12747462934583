import axios from "axios";
import { storeOrgToken,getOrgToken } from "./global";
const BASE_URL = process.env.REACT_APP_API_URL;
export const userAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
userAxios.interceptors.request.use((request) => {
    const org_id = JSON.parse(getOrgToken());
    request.headers["org_id"] = org_id ? org_id : "";
  return request;
});
userAxios.interceptors.response.use(
  (response) => {
    if (response.data?.status === 1) return response.data;
    return Promise.reject(response.data?.err);
  },
  (error) => {
    const { response } = error;
    const message = error?.response?.data?.err ? error?.response?.data?.err : "Something went wrong"
    return Promise.reject(message);
  }
);





