import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { orgAxios } from "../../config/org.axios";
import { defaultAxios } from "../../config/default.axios";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import { Theme } from "@fullcalendar/core/internal";
import { Row } from "react-bootstrap";
import { error } from "jquery";
import { getAccessToken, getOrgToken } from "../../config/global";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const { orgID, resetPasswordToken } = useParams();
  const [passwordReseted, setPasswordReseted] = useState(true);

  function handleBackBtnResend() {
    window.location.href = `/login`;
  }

  useEffect(() => {
    verifyLink();
  }, []);

  const verifyLink = () => {
    defaultAxios
      .post(`user/verify-reset-password`, { token: resetPasswordToken })
      .then(
        (response) => {
          if (response.status === 1) {
            setPasswordReseted(true);
          }
        },
        (error) => {
          setPasswordReseted(false);
        }
      );
  };

  return passwordReseted === true ? (
    <PasswordResetSuccess resetPasswordToken={resetPasswordToken} />
  ) : passwordReseted === false ? (
    <PasswordResetedFailure handleBackBtnResend={handleBackBtnResend} />
  ) : (
    <div></div>
  );
};

const PasswordResetSuccess = ({ resetPasswordToken }) => {
  const handleSubmit = (event) => {
    defaultAxios.put(`user/reset-password`, { ...event }).then(
      (response) => {
        if (response.status === 1) {
          toast.success("Your Password Reset Successfully");
          window.location.href = "/login";
        }
      },
      (error) => {
        toast.warn("Failed to reset password", error);
      }
    );
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required."),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with New Password"
      ),
  });
  const formik = useFormik({
    initialValues: {
      password: "",
      token: resetPasswordToken,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const [passwordShow, setPasswordShow] = useState("password");
  const togglePasswordView = () => {
    if (passwordShow === "text") {
      setPasswordShow("password");
    } else if (passwordShow === "password") {
      setPasswordShow("text");
    }
  };


  return (
    <div className="login">
      <div className="d-flex justify-content-center align-items-center my-5 mx-auto login-content">
        <form style={{ width: "500px" }} onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <h1 className="text-center">
              <Link to="/login">
                <span style={{ fontSize: "1.5rem" }}>
                  <i class="fas fa-lg fa-fw me-2 fa-arrow-left"></i>
                </span>
              </Link>{" "}
              Sign In
            </h1>
            <div className="text-inverse text-opacity-50 text-center mb-4">
              Please enter your new password and reset your password
            </div>
            <div className="d-flex justify-content-between">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              {passwordShow === "text" ? (
                <i
                  onClick={() => togglePasswordView()}
                  className="bi bi-eye passwordEyeText me-2"
                ></i>
              ) : (
                <i
                  onClick={() => togglePasswordView()}
                  className="bi bi-eye-slash passwordEyePassword me-2"
                ></i>
              )}
            </div>
            <input
              id="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              type={passwordShow}
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="password"
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-danger">{formik.errors.password}</div>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">
              Confirm Password <span className="text-danger">*</span>
            </label>
            <input
              id="confirm_password"
              name="confirm_password"
              onChange={formik.handleChange}
              value={formik.values.confirm_password}
              type={passwordShow}
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="confirm password"
            />
            {formik.touched.confirm_password &&
              formik.errors.confirm_password && (
                <div className="text-danger">
                  {formik.errors.confirm_password}
                </div>
              )}
          </div>

          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const PasswordResetedFailure = ({ handleBackBtnResend }) => {
  return (
    <div className="login">
      <div className="d-flex justify-content-center align-items-center my-5 mx-auto">
        <Row className="w-50 text-center">
          <h1 style={{ margin: "20px 20px 20px 0px" }} className="">
            The confirmation link is invalid or has expired
          </h1>
          <img
            className="verify-email mb-5"
            src="/assets/img/close.png"
            alt="verify"
          />
          <button
            onClick={handleBackBtnResend}
            className="btn btn-outline-theme px-3 rounded-pill"
          >
            <i className="fa fa-arrow-left me-1 ms-n1"></i> Go Back
          </button>
        </Row>
      </div>
    </div>
  );
};
export default ForgetPassword;
