// import Chart from 'chart.js/auto';
import Chart from "react-apexcharts";
import {
  Card,
  CardBody,
  CardExpandToggler,
} from "./../../components/card/card.jsx";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useParams, useNavigate, useHistory } from "react-router-dom";
import { orgAxios } from "../../config/org.axios.js";
import { socket } from "../../socket/SocketConnection.js";
import React, { Fragment, useState, useEffect } from "react";
import TestTable from "../../components/table/SortingTable";
import { allAgentDataFormatter } from "../../config/tableDataFormator.js";
import  {getOrgToken} from "../../config/global.js";
function PublicIp() {
  const orgID = JSON.parse(getOrgToken())
  const { publicIp } = useParams();
  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [modifiedOriginalData,setModifiedOriginalData] = useState([]);
  const [isPieVisible, setIsPieVisible] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  const themeColorRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme-rgb")
    .trim();
  async function doGetRequest() {
    setIsPieVisible(false);
    setIsTableVisible(false);
    let res = await orgAxios.get(
      `agents_metadata?public_id=${publicIp}`
    );
    setTableData(res?.data);
  }

  useEffect(() => {
    if (tableData?.data?.agents_data?.length > 0) {
      setOriginalData(tableData?.data?.agents_data);
      setModifiedOriginalData(allAgentDataFormatter(tableData?.data?.agents_data))
      setIsTableVisible(true);
    }
    if (tableData?.data?.agents_info != undefined) {
      setIsPieVisible(true);
    }
  }, [tableData, tableData?.data?.agents_info]);

  useEffect(() => {
    doGetRequest();
  }, [publicIp]);

  function onConnect(data) {}

  function onDisconnect(data) {}

  useEffect(() => {
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("agent_added", doGetRequest);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("agent_added", doGetRequest);
    };
  }, []);

  let barOptions = {
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    title: {
      text: "Monthly Inflation in Argentina, 2002",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  };

  let barSeries = {
    name: "Inflation",
    data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
  };

  return (
    <Fragment>
      <Card className="mb-3">
        <div className="row">
          <div className="col-xl-8 col-xxl-8 col-lg-8 col-sm-12">
            <div
              style={{
                boxShadow: "0 4px 10px rgba(23,32,90,.15)",
                padding: "10px ",
              }}
            >
              {/* {isPieVisible && ( */}
              <Chart
                type="bar"
                height={300}
                series={[
                  {
                    name: "Count",
                    data: [
                      tableData?.data?.agents_info?.total_active_agents,
                      tableData?.data?.agents_info?.total_disconnected_agents,
                      tableData?.data?.agents_info?.total_pending_agents,
                      tableData?.data?.agents_info
                        ?.total_never_connected_agents,
                    ],
                    width: "5px",
                  },
                ]}
                options={{
                  plotOptions: {
                    bar: {
                      columnWidth: "20px",
                    },
                  },
                  chart: {
                    events: {
                      dataPointSelection: (event, chartContext, config) => {
                        if (config.dataPointIndex === 0) {
                          window.location.href = `/${orgID}/analytics/active`;
                        } else if (config.dataPointIndex === 1) {
                          window.location.href = `/${orgID}/analytics/disconnect`;
                        } else if (config.dataPointIndex === 2) {
                          window.location.href = `/${orgID}/analytics/pending`;
                        } else if (config.dataPointIndex === 3) {
                          window.location.href = `/${orgID}/analytics/never_connected`;
                        }
                      },
                    },
                  },
                  title: {
                    // text: "Last 7 days Active Nodes",
                    style: { fontSize: 10 },
                    style: {
                      color: [
                        "rgba(" + themeColorRgb + ", 1)",
                        "rgba(" + themeColorRgb + ", .75)",
                        "rgba(" + themeColorRgb + ", .5)",
                      ],
                      fontSize: 15,
                    },
                  },

                  subtitle: {
                    text: "",
                    style: { fontSize: 18 },
                  },
                  colors: [
                    function ({ value, seriesIndex, dataPointIndex, w }) {
                      if (dataPointIndex == 0) {
                        return "#0FE819";
                      }
                      if (dataPointIndex == 1) {
                        return "#E83A0F";
                      }
                      if (dataPointIndex == 2) {
                        return "#1CDBD5";
                      }
                      if (dataPointIndex == 3) {
                        return "#0A0000";
                      }
                    },
                  ],
                  xaxis: {
                    tickPlacement: "on",
                    categories: [
                      "Active",
                      "Disconnected",
                      "Pending",
                      "Never connected",
                    ],
                    labels: {
                      show: true,
                      rotate: -45,
                      rotateAlways: false,
                      hideOverlappingLabels: true,
                      showDuplicates: false,
                      trim: false,
                      minHeight: undefined,
                      maxHeight: 120,
                      style: {
                        colors: [
                          "rgba(" + themeColorRgb + ", 1)",
                          "rgba(" + themeColorRgb + ", 1)",
                          "rgba(" + themeColorRgb + ", 1)",
                          "rgba(" + themeColorRgb + ", 1)",
                        ],
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                        cssClass: "apexcharts-xaxis-label",
                      },
                      offsetX: 0,
                      offsetY: 0,
                      format: undefined,
                      formatter: undefined,
                      datetimeUTC: true,
                      datetimeFormatter: {
                        year: "yyyy",
                        month: "MMM 'yy",
                        day: "dd MMM",
                        hour: "HH:mm",
                      },
                    },
                    title: {
                      text: "Agents Status",
                      style: {
                        color: [
                          "rgba(" + themeColorRgb + ", 1)",
                          "rgba(" + themeColorRgb + ", .75)",
                          "rgba(" + themeColorRgb + ", .5)",
                        ],
                        fontSize: 12,
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      formatter: (val) => {
                        return `${val}`;
                      },
                      style: {
                        fontSize: "10",
                        colors: [
                          "rgba(" + themeColorRgb + ", 1)",
                          "rgba(" + themeColorRgb + ", .75)",
                          "rgba(" + themeColorRgb + ", .5)",
                        ],
                      },
                    },
                    title: {
                      text: "No of Agents",
                      style: {
                        color: [
                          "rgba(" + themeColorRgb + ", 1)",
                          "rgba(" + themeColorRgb + ", .75)",
                          "rgba(" + themeColorRgb + ", .5)",
                        ],
                        fontSize: 12,
                      },
                    },
                  },
                  legend: {
                    show: true,
                    position: "right",
                  },
                  dataLabels: {
                    formatter: (val) => {
                      return `${val}`;
                    },
                    style: {
                      colors: [
                        "rgba(" + themeColorRgb + ", 1)",
                        "rgba(" + themeColorRgb + ", .75)",
                        "rgba(" + themeColorRgb + ", .5)",
                      ],
                      fontSize: 10,
                    },
                  },
                }}
              ></Chart>
              {/* )} */}
            </div>
          </div>

          <div className="col-xl-4 col-xxl-4 col-lg-4 col-sm-12 align-self-center">
              <div className="m-3 logsGraphs">
                <CardBody>
                  <div style={{ boxShadow: "0 4px 10px rgba(23,32,90,.15)" }}>
                    <div
                      style={{
                        // display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        padding: "20px 40px",
                      }}
                    >
                      <div>
                        <a
                          className="text-decoration-none"
                          href={`/${orgID}/analytics/active`}
                        >
                          <div className="d-flex justify-content-between align-items-center justify-content-center align-items-center">
                            <p style={{ fontSize: "20px", margin: "0" }}>
                              Active
                            </p>
                            <p style={{ fontSize: "20px", margin: "0" }}>
                              {
                                tableData?.data?.agents_info
                                  ?.total_active_agents
                              }
                            </p>
                          </div>
                        </a>
                      </div>

                      <div>
                        <a
                          className="text-decoration-none"
                          href={`/${orgID}/analytics/disconnect`}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <p style={{ fontSize: "20px", margin: "0" }}>
                              Disconnected
                            </p>
                            <p style={{ fontSize: "20px", margin: "0" }}>
                              {
                                tableData?.data?.agents_info
                                  ?.total_disconnected_agents
                              }
                            </p>
                          </div>
                        </a>
                      </div>

                      <div>
                        <a
                          className="text-decoration-none"
                          href={`/${orgID}/analytics/pending`}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <p style={{ fontSize: "20px", margin: "0" }}>
                              Pending
                            </p>
                            <p style={{ fontSize: "20px", margin: "0" }}>
                              {
                                tableData?.data?.agents_info
                                  ?.total_pending_agents
                              }
                            </p>
                          </div>
                        </a>
                      </div>

                      <div>
                        <a
                          className="text-decoration-none"
                          href={`/${orgID}/analytics/never_connected`}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <p style={{ fontSize: "20px", margin: "0" }}>
                              Never connected
                            </p>
                            <p style={{ fontSize: "20px", margin: "0" }}>
                              {
                                tableData?.data?.agents_info
                                  ?.total_never_connected_agents
                              }
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </div>
            </div>
        </div>
      </Card>

      <div>
        {/* {isTableVisible && ( */}
        <TestTable tData={modifiedOriginalData} />
        {/* )} */}
      </div>
    </Fragment>
  );
}
export default PublicIp;
