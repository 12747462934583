import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";

const TableShorting = ({ tData, COLUMNS, handleShow }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => tData, [tData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <>
      <table {...getTableProps()} className="table table-bordered">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="align-middle iocTableHeader"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="d-flex align-items-center">
                    <div>{column.render("Header")}</div>
                    <div style={{ marginLeft: "10px", fontSize: 14 }}>
                      {!column?.disableSortBy ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-caret-down"></i>
                          ) : (
                            <i className="fas fa-caret-up"></i>
                          )
                        ) : (
                          <i className="fas fa-sort"></i>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr className="align-middle iocTableRow" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td className="iocTableCell" {...cell.getCellProps()}>
                    {cell.column.Header === "Remote Address IP" ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleShow(cell.value);
                        }}
                      >
                        {cell.render("Cell")}
                      </span>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TableShorting;
