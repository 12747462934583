const ACCESS_TOKEN = "098hsban23q";
const ORG_TOKEN = '098hsban23w';
const USER_DATA = '098huser23w'

export const storeAccessToken = (token) =>
  localStorage.setItem(ACCESS_TOKEN, token);
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);
export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export const storeOrgToken = (token) =>
  localStorage.setItem(ORG_TOKEN, token);
export const getOrgToken = () => localStorage.getItem(ORG_TOKEN);
export const removeOrgToken = () => {
  localStorage.removeItem(ORG_TOKEN);
};
 

export const storeUser = (token) =>
  localStorage.setItem(USER_DATA, token);
export const getUser = () => localStorage.getItem(USER_DATA);
export const removeUser = () => {
  localStorage.removeItem(USER_DATA);
};


