import React, { useContext, useState } from "react";
import { orgAxios } from "../../config/org.axios";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { AppSettings } from "../../config/app-settings";
import { LineWaveLoader } from "../loader";

const UploadCsv = ({ setUploadModal, customIocDataApiCall }) => {
  const { totalPageCustomIoc, customIocData,customIocLimit } = useContext(AppSettings);
  const [file, setFile] = useState("");
  const [source, setSource] = useState("");
  const [isSouceError, setIsSouceError] = useState("");
  const [isFileError, setIsFileError] = useState("");
  const [uploadCsvLoader, setUploadCsvLoader] = useState(false);

  const handleCSVUpload = () => {
    var isValid = checkValidationCsv();
    if (isValid) {
      setUploadCsvLoader(true);
      const csvData = new FormData();
      csvData.append("source", source);
      // csvData.append("files", file);
      for (let i = 0; i < file.length; i++) {
        csvData.append("files", file[i]);
      }
      orgAxios
        .post("upload-ioc-file", csvData)
        .then((response) => {
          console.log("uploadCSV", response);
          if (response.status === 200) {
            toast.success("Your file has been uploaded successfully");
            setUploadModal(false);
            if (customIocData.length < 50) {
              customIocDataApiCall(totalPageCustomIoc,customIocLimit);
            } else {
              customIocDataApiCall(totalPageCustomIoc + 1,customIocLimit);
            }
          }
          setUploadCsvLoader(false);
        })
        .catch((error) => {
          toast.error(error);
          setUploadCsvLoader(false);
        });
    }
  };

  const checkValidationCsv = () => {
    if (source === "") {
      setIsSouceError("Source is Mandatory");
      return false;
    } else {
      setIsSouceError("");
    }
    if (file === "") {
      setIsFileError("File is Mandatory");
      return false;
    } else {
      setIsFileError("");
    }
    return true;
  };

  return (
    <Card style={{ marginBottom: "20px" }}>
      {/* <CardHeader className="d-flex justify-content-between">
        <div>Upload CSV IOC</div>
      </CardHeader> */}
      <CardBody>
        <Row className="flex justify-content-center w-100 m-auto ">
          <label className="mt-3">Source</label>
          <input
            style={{
              margin: "0px 10px 0px 0px",
            }}
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            type="text"
            id="csv"
            placeholder="CSV file Source"
            onChange={(e) => setSource(e.target.value)}
          />
          <span
            style={{
              color: "red",
              fontSize: 12,
              margin: "0px 0px 10px 0px",
            }}
          >
            {isSouceError}{" "}
          </span>
          <label className="mt-3">Upload csv file</label>
          <input
            className="form-control form-control-lg bg-white bg-opacity-5 mt-1 mb-3"
            type="file"
            name="csv"
            id="getFile"
            multiple="multiple"
            onChange={(e) => setFile(e.target.files)}
          />
          <span
            style={{
              color: "red",
              fontSize: 12,
              margin: "0px 0px 10px 0px",
            }}
          >
            {isFileError}{" "}
          </span>
          {uploadCsvLoader ? (
            <div className="btn btn-outline-theme btn-lg">
              <div className="d-flex justify-content-center">
                <LineWaveLoader />
              </div>
            </div>
          ) : (
            <button
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-3 mb-3"
              onClick={() => {
                handleCSVUpload();
              }}
            >
              Upload
            </button>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default UploadCsv;
