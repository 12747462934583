import React, { useContext, useEffect, useState } from "react";
import { Card, CardHeader, CardBody } from "./../../components/card/card.jsx";
import "lity";
import "lity/dist/lity.min.css";
import { getUser } from "../../config/global.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import { orgAxios } from "../../config/org.axios.js";
import { toast } from "react-toastify";
import { AppSettings } from "../../config/app-settings.js";
import Organization from "../../components/profile/organization.js";
import User from "../../components/profile/user.js";
import { Tab, Tabs } from "react-bootstrap";
import { LineWaveLoader } from "../../components/loader.js";

function Profile() {
  const [updateProfile, setUpdateProfile] = useState("profile_edit");
  const context = useContext(AppSettings);
  const [key, setKey] = useState("profile_client");
  const [passwordShow, setPasswordShow] = useState("password");
  const [passwordLoader, setPasswordLoader] = useState(false);
  const [verifyEmailLoader, setVerifyEmailLoader] = useState(false);

  const validationSchemaChangePassword = Yup.object().shape({
    old_password: Yup.string().required("Old Password is required."),
    new_password: Yup.string().required("New Password is required."),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("new_password"), null],
        "Confirm password must match with New Password"
      ),
  });
  const formikChangePassword = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
    },
    validationSchema: validationSchemaChangePassword,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(event) {
    setPasswordLoader(true);
    await orgAxios.post(`user/change-password`, { ...event }).then(
      (response) => {
        if (response?.data?.status === 1) {
          console.log("success", response);
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
        setPasswordLoader(false);
      },
      (error) => {
        toast.error(error);
        setPasswordLoader(false);
      }
    );
  }

  // const getProfileDetails = async () => {
  //   console.log("getProfileDetails", context.profileData);
  //   await orgAxios.get(`user/`).then((response) => {
  //     console.log("profile", response?.data?.data);
  //     context.setClientProfileData(response?.data?.data?.client_data);
  //     context.setUserProfileData(response?.data?.data?.user_data);
  //   });
  // };
  // useEffect(() => {
  //   getProfileDetails();
  // }, []);

  const verifyEmail = () => {
    setVerifyEmailLoader(true);
    orgAxios.post(`user/verify-email`).then(
      (response) => {
        console.log("verifyEmail", response);
        if (response?.status === 200) {
          toast.success("verification email sent successfully");
        }
        setVerifyEmailLoader(false);
      },
      (error) => {
        toast.error(error);
        setVerifyEmailLoader(false);
      }
    );
  };

  const togglePasswordView = () => {
    if (passwordShow === "text") {
      setPasswordShow("password");
    } else if (passwordShow === "password") {
      setPasswordShow("text");
    }
  };

  return (
    <Card>
      <CardBody className="p-0">
        <div className="profile">
          <div className="profile-container">
            <div className="profile-sidebar">
              <div className="desktop-sticky-top">
                {/* <div className="profile-img">
                  <img src="/assets/img/user/profile.jpg" alt="" />
                </div> */}
                <div className="d-flex justify-content-between">
                  <h4>
                    {context.userprofileData?.full_name
                      ? context.userprofileData?.full_name
                      : "user name"}
                  </h4>
                  <i
                    onClick={() => setUpdateProfile("profile_edit")}
                    style={{ cursor: "pointer" }}
                    className="bi bi-pen-fill aliasEditButton"
                  ></i>
                </div>
                <div className="mb-3 text-inverse text-opacity-50 fw-bold mt-n2 d-flex ">
                  <div>
                    {context.userprofileData?.email
                      ? context.userprofileData?.email
                      : "user"}
                  </div>
                  {context.userprofileData?.email_verified ? (
                    <button
                      style={{ padding: "0px 2px 0px 2px" }}
                      className="btn btn-success mx-2 disabled"
                    >
                      verified
                    </button>
                  ) : verifyEmailLoader ? (
                    <div className="d-flex justify-content-center mx-2 bg-warning px-2 rounded">
                      <LineWaveLoader color='white' />
                    </div>
                  ) : (
                    <button
                      onClick={() => verifyEmail()}
                      style={{ padding: "0px 2px 0px 2px" }}
                      className="btn btn-danger mx-2"
                    >
                      verify
                    </button>
                  )}
                </div>
                <div className="mb-1 text-uppercase">
                  client Id: {context.clientProfileData?.client_id
                    ? context.clientProfileData?.client_id
                    : "not available"}
                </div>
                <div className="mb-1">
                  <i className="bi bi-buildings fa-fw text-inverse text-opacity-50"></i>{" "}
                  {context.clientProfileData?.org_id
                    ? context.clientProfileData?.org_id
                    : "not available"}
                </div>
                <div className="mb-1">
                  <i className="bi bi-person-square text-inverse text-opacity-50"></i>{" "}
                  {context.userprofileData?.user_type
                    ? context.userprofileData?.user_type
                    : "------"}
                </div>
                <div className="mb-3">
                  <i className="fbi bi-phone text-inverse text-opacity-50"></i>{" "}
                  {context.userprofileData?.phone_number
                    ? `${context.userprofileData?.country_code} - ${context.userprofileData?.phone_number}`
                    : "------"}
                </div>

                <hr className="mt-4 mb-4" />

                <div className="fw-bold mb-3 fs-16px">Settings</div>
                <button
                  onClick={() => setUpdateProfile("change_password")}
                  className={`btn btn-lg d-block w-50 ${
                    updateProfile === "change_password"
                      ? "btn-theme"
                      : "btn-outline-theme"
                  }`}
                >
                  Change Password
                </button>
              </div>
            </div>

            <div className="profile-content">
              <div className="profile-content-container">
                <div className="row gx-4">
                  <div className="col-xl-12 d-flex justify-content-center">
                    {updateProfile === "change_password" ? (
                      <div className="col-xl-8">
                        <form onSubmit={formikChangePassword.handleSubmit}>
                          <div className="mb-3">
                            <label className="form-label">
                              Old Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              id="old_password"
                              name="old_password"
                              onChange={formikChangePassword.handleChange}
                              value={formikChangePassword.values.old_password}
                              type="password"
                              className="form-control form-control-lg bg-white bg-opacity-5"
                              placeholder="old password"
                            />
                            {formikChangePassword.touched.old_password &&
                              formikChangePassword.errors.old_password && (
                                <div className="text-danger">
                                  {formikChangePassword.errors.old_password}
                                </div>
                              )}
                          </div>
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="form-label">
                                New Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                              {passwordShow === "text" ? (
                                <i
                                  onClick={() => togglePasswordView()}
                                  className="bi bi-eye passwordEyeText me-2"
                                ></i>
                              ) : (
                                <i
                                  onClick={() => togglePasswordView()}
                                  className="bi bi-eye-slash passwordEyePassword me-2"
                                ></i>
                              )}
                            </div>
                            <input
                              id="new_password"
                              name="new_password"
                              onChange={formikChangePassword.handleChange}
                              value={formikChangePassword.values.new_password}
                              type={passwordShow}
                              className="form-control form-control-lg bg-white bg-opacity-5"
                              placeholder="new password"
                            />
                            {formikChangePassword.touched.new_password &&
                              formikChangePassword.errors.new_password && (
                                <div className="text-danger">
                                  {formikChangePassword.errors.new_password}
                                </div>
                              )}
                          </div>

                          <div className="mb-3">
                            <label className="form-label">
                              Confirm New Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              id="confirm_password"
                              name="confirm_password"
                              onChange={formikChangePassword.handleChange}
                              value={
                                formikChangePassword.values.confirm_password
                              }
                              type={passwordShow}
                              className="form-control form-control-lg bg-white bg-opacity-5"
                              placeholder="confirm new password"
                            />
                            {formikChangePassword.touched.confirm_password &&
                              formikChangePassword.errors.confirm_password && (
                                <div className="text-danger">
                                  {formikChangePassword.errors.confirm_password}
                                </div>
                              )}
                          </div>

                          <div className="mb-3">
                            {passwordLoader ? (
                              <div className="d-flex justify-content-center btn btn-outline-theme btn-lg d-block w-100">
                                <LineWaveLoader />
                              </div>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-outline-theme btn-lg d-block w-100"
                              >
                                Change Password
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    ) : updateProfile === "profile_edit" ? (
                      <div className="col">
                        <Tabs
                          defaultActiveKey="table"
                          id="uncontrolled-tab-example"
                          className="mb-3"
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                        >
                          <Tab
                            style={{
                              zIndex: 0,
                              position: "relative",
                            }}
                            eventKey="profile_client"
                            title="User Profile"
                          >
                            <div className="">
                              <div className="d-flex">
                                <h4>User Details</h4>
                                <span className="ms-2">
                                  (all fields are mandatory)
                                  <span className="text-danger">*</span>
                                </span>
                              </div>
                              <User />
                            </div>
                          </Tab>
                          <Tab
                            style={{
                              zIndex: 0,
                              position: "relative",
                            }}
                            eventKey="profile_org"
                            title="Organization Profile"
                          >
                            <div className="">
                              <div className="d-flex">
                                <h4>Organization Details</h4>
                                <span className="ms-2">
                                  (all fields are mandatory)
                                  <span className="text-danger">*</span>
                                </span>
                              </div>
                              <Organization />
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default Profile;
