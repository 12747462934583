import React from "react";

const Welcome = () => {
  return (
    <div className="welcomePage">
      <div className="d-flex justify-content-center align-items-center mx-auto my-auto">
        <div className="text-center mb-5">
          <h4 className="text-uppercase mb-3">Welcome to</h4>
          <img alt="SHIELD XDR" src="/assets/img/xdrLogoSmall.png" />
          <div>
            <h5 className="mt-5">
              Please download the agent and follow the process to view agent
              activity
            </h5>
            <button onClick={() => window.location.href="agentDownload"} className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-5 mb-3">
              Download Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
