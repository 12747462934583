import React, { useMemo, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useTable, useSortBy, usePagination } from "react-table";
import { Card } from "../card/card";
import { Checkbox } from "./Checkbox";
import { AppSettings } from "../../config/app-settings";

const datastyle = {
  padding: "8px 12px",
  margin: "0 0 15px 0",
  "border-radius": "5px",
  " font-weight": 500,
  "font-size": "1rem",
  background: "transparent",
  "border-color": "#f4f4f4",
  border: "1px solid #adb5bd",
  color: "gray",
};

const TableShortingWithoutPagination = ({ tData, COLUMNS, handleShow }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => tData, [tData]);
  const [show, setShow] = useState(false);
  const { rowData } = useContext(AppSettings);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy
    // usePagination
  );
  return (
    <>
      <div className="card-body">
        <div style={{ textAlign: "end" }}>
          <button
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => setShow(true)}
            // style={datastyle}
            className="btn btn-outline-theme btn-lg  w-fit fw-500 mt-3 mb-3"
          >
            Filter Columns
          </button>
        </div>
      </div>

      <table {...getTableProps()} className="table table-bordered">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="align-middle"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="d-flex align-items-center">
                    <div>{column.render("Header")}</div>
                    <div style={{ marginLeft: "10px", fontSize: 14 }}>
                      {!column?.disableSortBy ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-caret-down"></i>
                          ) : (
                            <i className="fas fa-caret-up"></i>
                          )
                        ) : (
                          <i className="fas fa-sort"></i>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                style={{
                  fontSize: "12px",
                  background: `${row?.id === rowData?.[0]?.row?.id ? 
                   localStorage.getItem('appMode') === 'dark'?"#353535" :"#c5c5c5": ""}`,
                }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {cell.column.Header === "Remote Address IP" ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleShow(cell.value);
                        }}
                      >
                        {cell.render("Cell")}
                      </span>
                    ) : cell.column.Header === "Value of Hash/IP" ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleShow(cell);
                        }}
                      >
                        {cell.render("Cell")}
                      </span>
                    ) : cell.column.Header === "Hash" ? (
                      <span
                        className="customLink"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleShow(cell.value);
                        }}
                      >
                        {cell.render("Cell")}
                      </span>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal
        show={show}
        // onHide={() => window.location.href = `/analytics/${agentID}`}
        onHide={() => setShow(false)}
        fullscreen={false}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Please select and unselect columns of table
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "10px" }}>
            <Card className="mb-3 ">
              <div
                className="card-body"
                style={{ maxHeight: "600px", overflow: "scroll" }}
              >
                <div style={{ float: "right" }}>
                  <Checkbox {...getToggleHideAllColumnsProps()} />{" "}
                  Select/Unselect All
                </div>
                {allColumns.map((column) => (
                  <div key={column.id}>
                    <label>
                      <input
                        type="checkbox"
                        className="allSelectionCheckbox"
                        {...column.getToggleHiddenProps()}
                      />{" "}
                      {column.Header}
                    </label>
                  </div>
                ))}
                <br />
              </div>
            </Card>
          </div>
        </Modal.Body>
      </Modal>

      {/* <div style={{ display: "flex", justifyContent: "end" }}>
        <button
          className="paginationButton"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className="paginationButton"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className="paginationButton"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div> */}
    </>
  );
};

export default TableShortingWithoutPagination;
