import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { AppSettings } from "./../../config/app-settings.js";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { orgAxios } from "../../config/org.axios";
import { LineWaveLoader } from "../loader.js";

const User = () => {
  const context = useContext(AppSettings);
  const [userProfileLoader, setUserProfileLoader] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email address."),
    full_name: Yup.string()
      .required("Full name is required.")
      .min(3, "Full name must be at least 3 characters."),
    country_code: Yup.string()
      .required("Country code is required.")
      .matches(/^\+\d{1,3}$/, "Invalid country code."),
    phone_number: Yup.string()
      .required("Phone is required.")
      .matches(/^[2-9]\d{2}[2-9]\d{6}$/, "Enter a valid phone number.")
      .matches(/^\d{10}$/, "Enter a valid phone number."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: context.userprofileData?.email || "",
      full_name: context.userprofileData?.full_name || "",
      country_code: context.userprofileData?.country_code || "",
      phone_number: context.userprofileData?.phone_number || "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(event) {
    setUserProfileLoader(true)
    await orgAxios.put(`user/`, { data: { ...event } }).then(
      (response) => {
        if (response?.data?.status === 1) {
          context.setUserProfileData(response?.data?.data);
          toast.success(response?.data?.message);
        } else if (response?.data?.status === 0) {
          toast.error(response?.message);
        }
        setUserProfileLoader(false)
      },
      (error) => {
        toast.error(error);
        setUserProfileLoader(false)
      }
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        <label className="form-label">Email</label>
        <input
          id="email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          type="text"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="organization name"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="text-danger">{formik.errors.email}</div>
        )}
      </div>

      <div className="mb-3">
        <label className="form-label">Full Name</label>
        <input
          id="full_name"
          name="full_name"
          onChange={formik.handleChange}
          value={formik.values.full_name}
          type="text"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="register name"
        />
        {formik.touched.full_name && formik.errors.full_name && (
          <div className="text-danger">{formik.errors.full_name}</div>
        )}
      </div>

      <div className="mb-3">
        <label className="form-label">Country Code</label>
        <input
          id="country_code"
          name="country_code"
          onChange={formik.handleChange}
          value={formik.values.country_code}
          type="text"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="country_code"
        />
        {formik.touched.country_code && formik.errors.country_code && (
          <div className="text-danger">{formik.errors.country_code}</div>
        )}
      </div>

      <div className="mb-3">
        <label className="form-label">Phone Number</label>
        <input
          id="phone_number"
          name="phone_number"
          onChange={formik.handleChange}
          value={formik.values.phone_number}
          type="text"
          className="form-control form-control-lg bg-white bg-opacity-5"
          placeholder="phone_number"
        />
        {formik.touched.phone_number && formik.errors.phone_number && (
          <div className="text-danger">{formik.errors.phone_number}</div>
        )}
      </div>

      <div className="mb-3">
      {userProfileLoader ? (
          <div className="d-flex justify-content-center btn btn-outline-theme btn-lg d-block w-100">
            <LineWaveLoader />
          </div>
        ) : (
        <button
          type="submit"
          className="btn btn-outline-theme btn-lg d-block w-100"
        >
          Update User{" "}
        </button>)}
      </div>
    </form>
  );
};

export default User;
