import React, { useContext, useEffect, useState } from "react";
import IocTable from "../table/IocTable";
import { CUSTOM_IOC_COLUMNS } from "../table/Columns";
import { orgAxios } from "../../config/org.axios";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import UploadForm from "./uploadForm";
import UploadCsv from "./uploadCsv";
import { AppSettings } from "../../config/app-settings";
import { debounce } from "lodash";
import { getAccessToken, getOrgToken } from "../../config/global";
import axios from "axios";
import { toast } from "react-toastify";
import { LineWaveLoader } from "../loader";

const CustomIoc = ({ setCustomIocCount }) => {
  const {
    customIocCanPreviousPage,
    setCustomIocCanPreviousPage,
    customIocCanNextPage,
    setCustomIocCanNextPage,
    customIocCurrentPage,
    setCustomIocCurrentPage,
    totalPageCustomIoc,
    setTotalPageCustomIoc,
    customIocData,
    setCustomIocData,
    customIocSearchQuery,
    setCustomIocSearchQuery,
    customIocLimit,
  setCustomIocLimit,
  } = useContext(AppSettings);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [downloadCsvLoader, setDownloadLoader] = useState(false);

  const customIocDataApiCall = async (page,limit) => {
    await orgAxios.get(`get-custom-iocs?page=${page}&limit=${limit}`).then((response) => {
      if (response.status === 200) {
        setCustomIocData(response?.data?.data);
        setTotalPageCustomIoc(response?.data?.total);
        setCustomIocCount(response?.data?.total_iocs);
        if (page === 1) {
          setCustomIocCanNextPage(true);
          setCustomIocCanPreviousPage(false);
        }
        if (response?.data?.next_page !== -1) {
          setCustomIocCurrentPage(response?.data?.next_page - 1);
        } else {
          if (response?.data?.total === 1) {
            setCustomIocCanNextPage(false);
            setCustomIocCanPreviousPage(false);
            setCustomIocCurrentPage(response?.data?.total);
          } else {
            setCustomIocCanNextPage(false);
            setCustomIocCanPreviousPage(true);
            setCustomIocCurrentPage(response?.data?.total);
          }
        }
      }
    });
  };

  const searchApiCall = async (page,limit, query) => {
    await orgAxios
      .get(`search-iocs?type=custom&query=${query}&page=${page}&limit=${limit}`)
      .then((response) => {
        if (response.status === 200) {
          setCustomIocData(response?.data?.data);
          setTotalPageCustomIoc(response?.data?.total);
          if (page === 1) {
            setCustomIocCanNextPage(true);
            setCustomIocCanPreviousPage(false);
          }
          if (response?.data?.next_page !== -1) {
            setCustomIocCurrentPage(response?.data?.next_page - 1);
          } else {
            if (response?.data?.total === 1) {
              setCustomIocCanNextPage(false);
              setCustomIocCanPreviousPage(false);
              setCustomIocCurrentPage(response?.data?.total);
            } else {
              setCustomIocCanNextPage(false);
              setCustomIocCanPreviousPage(true);
              setCustomIocCurrentPage(response?.data?.total);
            }
          }
        }
      });
  };

  const handleSearch = debounce((e) => {
    setCustomIocSearchQuery(e.target.value);
  }, 300);

  useEffect(() => {
    if (customIocSearchQuery !== "") {
      searchApiCall(customIocCurrentPage,customIocLimit, customIocSearchQuery);
    } else {
      customIocDataApiCall(customIocCurrentPage,customIocLimit);
    }
  }, [customIocSearchQuery]);

  //all functions for pagination
  const gotoPage = (page) => {
    if (customIocSearchQuery !== "") {
      if (page === 1) {
        searchApiCall(page,customIocLimit, customIocSearchQuery);
        setCustomIocCanPreviousPage(false);
        setCustomIocCanNextPage(true);
      } else if (page === totalPageCustomIoc) {
        searchApiCall(page,customIocLimit, customIocSearchQuery);
        setCustomIocCanNextPage(false);
        setCustomIocCanPreviousPage(true);
      }
    } else {
      if (page === 1) {
        customIocDataApiCall(page,customIocLimit);
        setCustomIocCanPreviousPage(false);
        setCustomIocCanNextPage(true);
      } else if (page === totalPageCustomIoc) {
        customIocDataApiCall(page,customIocLimit);
        setCustomIocCanNextPage(false);
        setCustomIocCanPreviousPage(true);
      }
    }
  };
  const previousPage = () => {
    if (customIocSearchQuery !== "") {
      searchApiCall(customIocCurrentPage - 1,customIocLimit, customIocSearchQuery);
    } else {
      customIocDataApiCall(customIocCurrentPage - 1,customIocLimit);
    }
    setCustomIocCanNextPage(true);
  };
  const nextPage = () => {
    if (customIocSearchQuery !== "") {
      searchApiCall(customIocCurrentPage + 1,customIocLimit, customIocSearchQuery);
    } else {
      customIocDataApiCall(customIocCurrentPage + 1,customIocLimit);
    }
    setCustomIocCanPreviousPage(true);
  };
  const downloadIocSample = async () => {
    setDownloadLoader(true)
    const token = JSON.parse(getAccessToken());
    const org_id = JSON.parse(getOrgToken());
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? "Bearer " + token : "",
          org_id: org_id ? org_id : "",
        },
        url: `${process.env.REACT_APP_API_URL}download/sample-csv`,
        responseType: "blob",
      };

      const response = await axios(options);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `iocsample.csv`);
      document.body.appendChild(link);
      link.click();
      toast.success(`Downloading file: iocsample.csv`);
      setDownloadLoader(false)
    } catch (error) {
      toast.error(`Error downloading file: Your email is not verified`);
      setDownloadLoader(false)
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="w-25">
          <form className="d-flex align-items-center mb-3 mt-1">
            {/* <label className="form-label me-3 fs-3 fw-bold">Search</label> */}
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="Search IOC Data"
              onChange={handleSearch}
            ></input>
          </form>
        </div>
        <div className="">
          <button
            onClick={() => {
              setUploadModal(true);
              setUploadType("form");
            }}
            className="btn btn-outline-theme btn-lg me-3"
          >
            IOC Upload form
          </button>
          <button
            onClick={() => {
              setUploadModal(true);
              setUploadType("csv");
            }}
            className="btn btn-outline-theme btn-lg me-3"
          >
            IOC Upload CSV
          </button>
          {downloadCsvLoader ? (
            <div className="btn btn-outline-theme btn-lg">
              <div>
                <LineWaveLoader />
              </div>
            </div>
          ) : (
            <button
              onClick={() => {
                downloadIocSample();
              }}
              className="btn btn-outline-theme btn-lg"
            >
              IOC Sample Download
            </button>
          )}
        </div>
        <Modal show={uploadModal}>
          {uploadType === "csv" ? (
            <>
              <ModalHeader className="d-flex justify-content-between">
                <div>upload using CSV</div>
                <i
                  onClick={() => setUploadModal(false)}
                  className="bi bi-x-circle modalHide"
                ></i>
              </ModalHeader>
              <ModalBody>
                <UploadCsv
                  setUploadModal={setUploadModal}
                  customIocDataApiCall={customIocDataApiCall}
                />
              </ModalBody>
            </>
          ) : uploadType === "form" ? (
            <>
              <ModalHeader className="d-flex justify-content-between">
                <div>upload using form</div>
                <i
                  onClick={() => setUploadModal(false)}
                  className="bi bi-x-circle modalHide"
                ></i>
              </ModalHeader>
              <ModalBody>
                <UploadForm
                  setUploadModal={setUploadModal}
                  customIocDataApiCall={customIocDataApiCall}
                />
              </ModalBody>
            </>
          ) : (
            <></>
          )}
        </Modal>
      </div>
      {customIocData.length > 0 ? (
        <>
          <div style={{ maxHeight: "450px", overflow: "scroll" }}>
            <IocTable tData={customIocData} COLUMNS={CUSTOM_IOC_COLUMNS} />
          </div>
          <div
            className="mt-3 mb-3"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div className="paginationButton">
          <label className="me-1" htmlFor="dropdown">Select Limit:</label>
          <select
            id="dropdown"
            value={customIocLimit}
            onChange={(e) => {
              setCustomIocLimit(e.target.value)
              if (customIocSearchQuery !== "") {
                searchApiCall(1, e.target.value, customIocSearchQuery);
              } else {
                customIocDataApiCall(1, e.target.value);
              }
            }}
            className="block bg-transparent rounded"
            style={{
              width:'40px'
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
            <button
              className="paginationButton"
              onClick={() => gotoPage(1)}
              disabled={!customIocCanPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="paginationButton"
              onClick={() => previousPage()}
              disabled={!customIocCanPreviousPage}
            >
              {"<"}
            </button>
            <button
              className="paginationButton"
              onClick={() => nextPage()}
              disabled={!customIocCanNextPage}
            >
              {">"}
            </button>
            <button
              className="paginationButton"
              onClick={() => gotoPage(totalPageCustomIoc)}
              disabled={!customIocCanNextPage}
            >
              {">>"}
            </button>
            <span>
              Page{" "}
              <strong>
                {customIocCurrentPage} of {totalPageCustomIoc}
              </strong>{" "}
            </span>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center">No data found</div>
      )}
    </>
  );
};

export default CustomIoc;
