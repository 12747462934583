import axios from "axios";
import { getAccessToken,getOrgToken } from "./global";

const BASE_URL = process.env.REACT_APP_API_URL_INSTRUCT;
export const instructAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
instructAxios.interceptors.request.use((request) => {
  const token = JSON.parse(getAccessToken());
  const org_id = JSON.parse(getOrgToken());
  request.headers["Authorization"] = token ? "Bearer " + token : "";
  request.headers["org_id"] = org_id ? org_id : "";
  return request;
});
instructAxios.interceptors.response.use(
  (response) => {
    if (response.data?.status === 1) return response;
    return Promise.reject(response.data?.err);
  },
  (error) => {
    const { response } = error;
    const message = response?.data?.err || "Something Went Wrong";
    return Promise.reject(message);
  }
);

